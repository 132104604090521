import {
  HallucinationFilter,
  PartialMatchFilter,
  ReferenceFilter,
  ValueMatchFilter,
} from '@unique/component-library';

export const DUE_DILIGENCE_COLUMNS_OVERRIDE = {
  A: {
    filter: ValueMatchFilter,
  },
  B: {
    filter: ValueMatchFilter,
  },
  C: {
    filter: PartialMatchFilter,
    cellEditor: 'magicMarkdownCellEditor',
  },
  D: {
    filter: PartialMatchFilter,
    cellEditor: 'magicMarkdownCellEditor',
  },
  E: {
    filter: PartialMatchFilter,
    cellEditor: 'magicMarkdownCellEditor',
  },
  F: {
    filter: ReferenceFilter,
  },
  G: {
    filter: HallucinationFilter,
    cellEditor: 'magicMarkdownCellEditor',
  },
};
