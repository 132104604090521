'use client';

import cn from 'classnames';
import {
  FC,
  KeyboardEventHandler,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { ChatUpload, MessageCreateInput, Role } from '@/@generated/graphql';
import { isSystemMessage } from '@/helpers/messages';
import { getUseMessagesQueryKey, useMessageCreateMutation } from '@/lib/swr/hooks';
import { Assistant, ContentById, Message } from '@/lib/swr/types';
import { upsertMessages, stopStream, useAppDispatch, useAppSelector } from '@/store';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  LoadingText,
  showUnsavedChangesWarning,
  Textarea,
  useOutsideClick,
} from '@unique/component-library';
import {
  IconFileLoading,
  IconMicrophone,
  IconPaperPlane,
  IconStop,
  IconUploadArrow,
} from '@unique/icons';
import { useRoles } from '@unique/next-commons/authorization';
import { logger } from '@unique/next-commons/logger';
import { ClientContext, Service } from '@unique/next-commons/swr';
import {
  LayoutContext,
  ScrollWrapperContext,
  isIngestionDone,
  useIsTouchDevice,
  useWarnBeforeReload,
  LEAVE_PAGE_WARNING_MESSAGE,
} from '@unique/shared-library';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { PoweredByDisclaimer } from '../PoweredByDisclaimer';
import { PromptSuggestions } from '../Space/PromptSuggestions';
import AssistantCharacterLimit from './AssistantCharacterLimit';
import ChatHeader from './Header/ChatHeader';
import { ContentItemUploading } from './ContentItemUploading';
import { VoiceInput } from './Inputs/VoiceInput/VoiceInput';
import { TakePhotoCta } from './TakePhotoCta';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:ChatInput',
});

interface ChatInputProps {
  handlePromptSent?: (prompt: string) => void;
  selectedPrompt?: { prompt: string } | null;
  currentChatAssistant?: Assistant;
  content?: ContentById[] | null;
  isIngesting?: boolean;
  chatUploadEnabled?: boolean;
  handleChatUploadClick: MouseEventHandler<HTMLButtonElement>;
  isUploading?: boolean;
}

interface ChatFormData {
  prompt: string;
}

const getLastMessage = (messages: Message[]): Message | null => {
  if (messages.length === 0) {
    return null;
  }
  const sortedMessages = [...messages].sort((a, b) =>
    differenceInMilliseconds(parseISO(a.createdAt), parseISO(b.createdAt)),
  );
  return sortedMessages[sortedMessages.length - 1];
};

export const ChatInput: FC<ChatInputProps> = ({
  handlePromptSent,
  selectedPrompt,
  currentChatAssistant,
  content,
  isIngesting,
  chatUploadEnabled,
  handleChatUploadClick,
  isUploading,
}) => {
  const {
    reset,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm<ChatFormData>({ reValidateMode: 'onChange', mode: 'onChange' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [hasInput, setHasInput] = useState(false);
  const { clients } = useContext(ClientContext);
  const isTouchDevice = useIsTouchDevice();
  const [text, setText] = useState('');
  const { allowUnlimitedChatInput } = useRoles();
  const { speechBackendUrl } = useContext(ConfigurationContext);
  const { setHeaderItems } = useContext(LayoutContext);
  const [showVoiceInput, setShowVoiceInput] = useState<boolean>(false);
  const inputDivRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();

  useOutsideClick(inputDivRef, () => {
    if (!isOnFocus) return;
    setIsOnFocus(true);
  });

  const chatInputWrapperRef = useRef<HTMLDivElement>();
  const { scrollToBottom } = useContext(ScrollWrapperContext);

  const { trigger: createMessage } = useMessageCreateMutation(getUseMessagesQueryKey());

  const chatId = typeof id === 'string' ? id : null;

  const messages = useAppSelector((state) => state.messages.messages) ?? [];

  // Show stop button if the last message is streaming or completed
  const showStopButton = useMemo(() => {
    // Get last message from Assistant role
    const lastMessage = getLastMessage(
      messages.filter((message) => message.role === Role.Assistant),
    );
    return (
      chatId &&
      !isLoading &&
      lastMessage &&
      !lastMessage?.stoppedStreamingAt &&
      !lastMessage?.completedAt
    );
  }, [messages, isLoading, chatId]);
  useEffect(() => {
    if (!selectedPrompt || !selectedPrompt.prompt) return;
    setHasInput(true);
    setValue('prompt', selectedPrompt.prompt);
    setText(selectedPrompt.prompt);
  }, [selectedPrompt]);

  // useOutsideClick instead of onBlur as the latest block direct submission of the form
  useOutsideClick(chatInputWrapperRef, () => {
    if (!text) {
      setHasInput(false);
    }
    setIsOnFocus(false);
  });

  useEffect(() => {
    // If is not loading
    if (!isLoading) return;
    // Get last message in the chat
    const lastMessage = getLastMessage(messages);
    // If last message is from the user, we keep the loading state
    if (lastMessage?.role === Role.User) return;
    // If last message is a system message, we keep the loading state
    if (isSystemMessage(lastMessage?.text) && !lastMessage?.stoppedStreamingAt) {
      setIsSearching(true);
      return;
    }
    setIsLoading(false);
    setIsSearching(false);
  }, [messages]);

  const handleVoiceModeClose = async () => {
    setShowVoiceInput(false);
    // -- INFO: At the request of Pascal We add a empty line to the prompt if text is already present to separate it.
    const promptValue = getValues('prompt');
    if (!promptValue) {
      return;
    }
    setValue('prompt', promptValue + '\n');
    // -- END
  };

  const sendPrompt = useCallback(
    (prompt: string) => {
      setIsLoading(true);
      handlePromptSent?.(prompt);

      const lastMessage = getLastMessage(messages);
      let lastMessageId = null;
      if (lastMessage?.chatId === chatId) {
        lastMessageId = lastMessage.id;
      }
      const payload: {
        chatId?: string | null;
        input: MessageCreateInput;
        assistantId?: string | null;
      } = {
        chatId,
        input: {
          text: prompt,
          role: Role.User,
          ...(lastMessageId && { previousMessage: { connect: { id: lastMessageId } } }),
        },
        assistantId: currentChatAssistant?.id,
      };

      createMessage(payload, {
        revalidate: false,
        throwOnError: false,
        onSuccess: ({ messageCreate }) => {
          if (lastMessageId) {
            dispatch(upsertMessages([messageCreate]));
          }
          setIsLoading(true);
          // Resets the prompt
          handleSelectPrompt(null);
          reset();
          if (!chatId) navigate(`/${messageCreate.chatId}`);
        },
        onError: (err) => {
          setIsLoading(false);
          setIsSearching(false);
          log.error(`Error sending prompt: ${JSON.stringify(err)}`);
        },
      });
    },
    [chatId, createMessage, handlePromptSent, messages, reset, navigate, currentChatAssistant?.id],
  );

  const handleFormSubmit = async () => {
    sendPrompt(text);
  };

  const onDragEnter = (event: React.DragEvent) => {
    if (currentChatAssistant?.chatUpload !== ChatUpload.Enabled) return;

    let isFile = false;
    if (event.dataTransfer.types) {
      event.dataTransfer.types.forEach((type) => {
        if (type === 'Files') isFile = true;
      });
    }
    if (!isFile) return;
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (hasInputLimitError) return;
    if (text.trim().length < 1) return;

    const target = event.target as HTMLInputElement;
    if (event.key === 'Enter' && !event.shiftKey && target.value && !isLoading) {
      event.preventDefault();
      sendPrompt(target.value);
      scrollToBottom();
      target.focus();
    }
  };

  const onStopStream = () => {
    const lastMessageId = getLastMessage(messages)?.id;
    if (!lastMessageId) {
      return;
    }
    dispatch(stopStream(chatId, lastMessageId, clients[Service.NODE_CHAT], true));
  };

  useEffect(() => {
    // Set focus on the input box when the user is on the Chat Space
    // or when the user switches between spaces
    if (!text) {
      setHasInput(false);
      // This is a hacky way to show the cursor in the contenteditable div
      setTimeout(() => {
        showCursorForContentEditableElement(inputDivRef.current);
      }, 0);
    }
  }, [text]);

  useEffect(() => {
    // Set focus on contenteditable div when Component is loaded
    setIsOnFocus(true);
    // Show cursor in contenteditable div
    showCursorForContentEditableElement(inputDivRef.current);
  }, []);

  useEffect(() => {
    if (!chatId || !currentChatAssistant) return;

    setHeaderItems([
      <ChatHeader
        key={chatId}
        assistantTitle={currentChatAssistant.name}
        onClickNewChat={() => {
          navigate(`/space/${currentChatAssistant?.id}`);
        }}
      />,
    ]);

    return () => {
      setHeaderItems([]);
    };
  }, [chatId, currentChatAssistant, setHeaderItems]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const prompt = urlParams.get('prompt');
    if (prompt) {
      sendPrompt(prompt);
    }
  }, []);

  useWarnBeforeReload(isUploading);

  useOutsideClick(containerRef, (event: Event) =>
    showUnsavedChangesWarning(event as MouseEvent, isUploading, LEAVE_PAGE_WARNING_MESSAGE),
  );

  const isProcessingFiles = isUploading || isIngesting;

  const currentAssistantInputLimit = currentChatAssistant?.inputLimit;
  const currentAssistantInputPlaceholder = useMemo(() => {
    if (isProcessingFiles) return '';
    if (isSearching) return 'Searching...';
    if (showStopButton) return 'Generating response...';
    if (isLoading) return 'Sending your prompt...';
    return currentChatAssistant ? `Enter a prompt in ${currentChatAssistant.name}` : 'Ask Unique';
  }, [currentChatAssistant, showStopButton, isLoading, isSearching, isProcessingFiles]);

  const hasText = !!text?.length;
  const isInputActive = (hasText || isOnFocus) && !isProcessingFiles;
  const hasInputLimitError =
    currentAssistantInputLimit &&
    text?.length > currentAssistantInputLimit &&
    !allowUnlimitedChatInput;

  const handleSelectPrompt = (prompt: string | null) => {
    const value = prompt ?? '';

    // Reset
    reset();

    setHasInput(prompt?.length > 0);
    setText(value);
    setValue('prompt', value);
  };

  const contentUploading = content?.filter((content) => !isIngestionDone(content.ingestionState));

  const showCursorForContentEditableElement = (target: HTMLElement) => {
    if (!target) return;
    const range = document.createRange();
    const sel = window.getSelection();
    range?.setStart(target?.childNodes?.[0], 0);
    range?.collapse(true);

    sel?.removeAllRanges();
    sel?.addRange(range);
    target.focus();
  };

  const handleVoiceToText = (result: string) => {
    setHasInput(true);

    if (!text) {
      handleSelectPrompt(result);
      return;
    }

    const diff = result.replace(text, '');
    const value = text + diff;
    handleSelectPrompt(value);
  };

  const hideFilePickerOnMobile =
    isTouchDevice && !!currentChatAssistant?.settings?.disableFilePickerOnMobile;

  return (
    <div className="bg-surface w-full pb-2 pt-1 transition-all" ref={containerRef}>
      <div className="mx-auto" ref={chatInputWrapperRef}>
        <PromptSuggestions
          assistant={currentChatAssistant}
          handleSelectPrompt={handleSelectPrompt}
          isChatInputEmpty={!text?.length}
          isSending={isLoading}
        />
        <div className="flex items-end gap-3">
          <form className="relative flex w-full" onSubmit={handleSubmit(handleFormSubmit)}>
            <div
              className={cn({
                'border-control bg-surface flex-1 rounded-md border-[1px] transition-all': true,
                'border-primary-cta': isInputActive && id,
                'border-primary-cta pb-8': !showVoiceInput && isInputActive && !id,
                'hover:border-primary-cta': !isProcessingFiles,
              })}
            >
              {showVoiceInput && (
                <VoiceInput
                  speechBackendUrl={speechBackendUrl}
                  onChange={handleVoiceToText}
                  onClose={handleVoiceModeClose}
                />
              )}

              {!showVoiceInput && (
                <>
                  {hasInput || isProcessingFiles ? (
                    <Textarea
                      name="prompt"
                      placeholder={currentAssistantInputPlaceholder}
                      className="w-full !flex-1 rounded-md border-0 !py-3 pr-10"
                      initialHeight={isProcessingFiles ? 100 : 46}
                      style={{ boxShadow: 'none' }}
                      labelClassname={cn({
                        'block relative': true,
                        '!h-[46px]': !isInputActive && !isProcessingFiles,
                        '!h-[100px]': isProcessingFiles,
                      })}
                      onDragEnter={onDragEnter}
                      onFocus={() => setIsOnFocus(true)}
                      register={register}
                      handleTextAreaChange={setText}
                      onKeyDown={handleKeyDown}
                      autoFocus={!isProcessingFiles}
                      disabled={isProcessingFiles}
                    />
                  ) : (
                    <div
                      role="textbox"
                      onDragEnter={onDragEnter}
                      ref={inputDivRef}
                      className="w-full !flex-1 rounded-md border-0 !py-3 pr-10 outline-none focus:pr-4"
                      autoFocus
                      onClick={(e: ReactMouseEvent) => {
                        setIsOnFocus(true);
                        showCursorForContentEditableElement(e.target as HTMLElement);
                      }}
                      suppressContentEditableWarning
                      onKeyDown={(event) => {
                        if (!text.length && (event.key === 'Backspace' || event.key === 'Delete')) {
                          event.preventDefault();
                          return;
                        }
                        setHasInput(true);
                        setValue('prompt', (event.target as HTMLInputElement).value);
                      }}
                      contentEditable={isOnFocus}
                      tabIndex={0}
                    >
                      <div className="body-1 text-on-control-dimmed flex gap-1 px-4">
                        {chatUploadEnabled ? (
                          <span className="w-40 sm:w-full">
                            <p className="truncate sm:hidden">{currentAssistantInputPlaceholder}</p>
                            <span className="hidden sm:inline">
                              {currentAssistantInputPlaceholder}, or{' '}
                              <button
                                role="button"
                                className="text-primary-cta cursor-pointer"
                                title="Upload"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleChatUploadClick(e);
                                }}
                              >
                                Upload in Chat
                              </button>
                            </span>
                          </span>
                        ) : (
                          currentAssistantInputPlaceholder
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {isUploading && (
              <div className="body-1 text-on-control-dimmed absolute left-4 top-3 flex gap-x-2">
                <IconFileLoading height="20px" />
                <LoadingText>Uploading file(s)</LoadingText>
              </div>
            )}
            {isIngesting && (
              <div className="body-1 text-on-control-dimmed absolute left-4 top-3">
                <LoadingText>Ingesting knowledge</LoadingText>
              </div>
            )}
            {contentUploading?.length > 0 && (
              <div className="absolute left-4 top-12 flex max-w-[calc(100%-60px)] gap-x-3 overflow-x-auto">
                {content
                  ?.filter((content) => !isIngestionDone(content.ingestionState))
                  .map((content) => <ContentItemUploading content={content} key={content.id} />)}
              </div>
            )}
            {currentAssistantInputLimit && !allowUnlimitedChatInput && (
              <div
                className={cn({
                  'absolute bottom-2.5 left-4': true,
                  'pointer-events-none opacity-0': !isInputActive,
                })}
              >
                <AssistantCharacterLimit
                  inputSize={text.length}
                  inputLimit={currentAssistantInputLimit}
                />
              </div>
            )}

            {!showVoiceInput && (
              <>
                {showStopButton ? (
                  <ButtonIcon
                    variant={ButtonVariant.PRIMARY}
                    type={ButtonType.BUTTON}
                    icon={!isLoading ? <IconStop height="16" width="16" /> : <></>}
                    className={cn({
                      '!bg-attention-variant !text-on-attention-variant !absolute bottom-2 right-2':
                        true,
                    })}
                    onClick={onStopStream}
                    buttonSize={ButtonSize.SMALL}
                  >
                    Stop
                  </ButtonIcon>
                ) : (
                  <ButtonIcon
                    variant={ButtonVariant.PRIMARY}
                    isLoading={isLoading}
                    type={ButtonType.SUBMIT}
                    icon={!isLoading ? <IconPaperPlane height="16" width="16" /> : <></>}
                    className={cn({
                      '!absolute bottom-2 right-2': true,
                    })}
                    disabled={!isValid || !hasText || hasInputLimitError || isProcessingFiles}
                    buttonSize={ButtonSize.SMALL}
                  />
                )}
              </>
            )}
          </form>

          {!!speechBackendUrl && !showVoiceInput && (
            <ButtonIcon
              className="bg-primary-cta text-on-primary h-[50px] w-auto"
              onClick={() => setShowVoiceInput(true)}
              icon={<IconMicrophone width="20" height="20" />}
            />
          )}

          {chatUploadEnabled && id && (
            <>
              {hideFilePickerOnMobile ? (
                <TakePhotoCta showIcon />
              ) : (
                <ButtonIcon
                  disabled={isProcessingFiles}
                  className="bg-primary-cta text-on-primary h-[50px] w-auto"
                  icon={<IconUploadArrow />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChatUploadClick(e);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <PoweredByDisclaimer
        className={isInputActive ? 'hidden sm:flex' : ''}
        currentChatAssistant={currentChatAssistant}
      />
    </div>
  );
};

export default ChatInput;
