import { useMessageAssessmentUpdatedSubscription } from '@/lib/swr/hooks';
import { updateMessageAssessment, useAppDispatch } from '@/store';
import { logger } from '@unique/next-commons/logger';
import { GraphQLError } from 'graphql';

type UseMessageAssessmentSubscriptionProps = {
  subscriptionVariables?: {
    chatId: string;
  };
};

const log = logger.child({
  package: 'chat',
  namespace: 'hooks:chat:use-message-assessment-subscription',
});

export const useMessageAssessmentSubscription = ({
  subscriptionVariables,
}: UseMessageAssessmentSubscriptionProps) => {
  const dispatch = useAppDispatch();

  useMessageAssessmentUpdatedSubscription(
    {
      next: (data) => {
        dispatch(updateMessageAssessment(data.messageAssessmentUpdated));
      },
      error: (errors: GraphQLError[]) => {
        log.error(`Message assessment update subscription error. Error: ${JSON.stringify(errors)}`);
      },
      complete: () => {
        log.info('Message assessment update subscription complete');
      },
    },
    subscriptionVariables,
  );
};
