import { Message, MessageAssessment } from '@/lib/swr/types';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { StrongId } from '@unique/misc';

export interface MessagesState {
  // Flat list of messages loaded, potentially multiple chats
  messages: Message[];
  // pagination store for each chatId the totalCount retuned by paginatedMessage query
  pagination: Record<StrongId<'chat'>, number>;
}

const initialState: MessagesState = {
  messages: [],
  pagination: {},
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.messages = initialState.messages;
      state.pagination = initialState.pagination;
    },
    upsertMessages: (state, action: PayloadAction<Message[]>) => {
      for (const messageToUpsert of action.payload) {
        const index = state.messages.findIndex((m) => m.id === messageToUpsert.id);
        if (index !== -1) {
          const previousMessage = state.messages[index];
          // If new message id is newer, we update the message
          // Important equal is needed to keep latest update not updating updatedAt (eg. feedback update)
          if (
            messageToUpsert.updatedAt >= previousMessage.updatedAt &&
            messageToUpsert.createdAt !== messageToUpsert.updatedAt
          ) {
            state.messages[index] = {
              ...previousMessage,
              ...messageToUpsert,
            };
          } else {
            // If new message id is older, we keep the old message
            state.messages[index] = {
              ...messageToUpsert,
              ...previousMessage,
            };
          }
        } else {
          state.messages.push(messageToUpsert);
          state.pagination[messageToUpsert.chatId] = state.pagination[messageToUpsert.chatId]++;
        }
      }
    },
    setPagination: (
      state,
      action: PayloadAction<{ chatId: StrongId<'chat'>; totalCount: number }>,
    ) => {
      state.pagination[action.payload.chatId] = action.payload.totalCount;
    },
    updateMessageAssessment: (state, action: PayloadAction<MessageAssessment>) => {
      const { messageId, id } = action.payload;
      const messageIndex = state.messages.findIndex((message) => message.id === messageId);
      if (messageIndex === -1) {
        return;
      }
      const updatedMessage = state.messages[messageIndex];

      // Check if the assessment already exists
      let assessmentFound = false;
      const updatedAssessments = (updatedMessage.assessment || []).map((assessment) => {
        if (assessment.id === id) {
          assessmentFound = true;
          return action.payload; // Update existing assessment
        }
        return assessment;
      });

      // If the assessment was not found, add it as a new assessment
      if (!assessmentFound) {
        updatedAssessments.push(action.payload);
      }

      state.messages[messageIndex] = {
        ...updatedMessage,
        assessment: updatedAssessments,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export * from './actions';
export const { clearMessages, setPagination, upsertMessages, updateMessageAssessment } =
  messagesSlice.actions;
export default messagesSlice.reducer;
