import { getCleanedFilters } from '@unique/shared-library/helpers/filter';
import { ChatHistoryFilter, ChatHistoryState } from '@/hooks/useChatHistory';
import { ScrollPagination } from '@unique/component-library';
import {
  FeatureFlagContext,
  LayoutContext,
  ScrollWrapperContext,
  useKeyPress,
} from '@unique/shared-library';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatHistoryActionBar, ChatHistoryDeleteAction } from './ChatHistoryActionBar';
import { ChatHistoryControls } from './ChatHistoryControls';
import { ChatHistoryHeader } from './ChatHistoryHeader';
import ChatHistoryListItem from './ChatHistoryListItem';
import { ChatHistoryListSkeleton } from './ChatHistoryListSkeleton';
import { ChatHistoryNoResults } from './ChatHistoryNoResults';
import { ChatHistoryContext } from './context';
import { ChatHistoryError } from './ChatHistoryError';

export const ChatHistoryList = () => {
  const navigate = useNavigate();
  const {
    chatsInHistory,
    onFilterUpdate,
    chatHistoryState,
    loadMore,
    canLoadMore,
    chatHistoryFilters,
    onDeleteChats,
  } = useContext(ChatHistoryContext);
  const { flags } = useContext(FeatureFlagContext);
  const [chatIdsToDelete, setChatIdsToDelete] = useState<string[]>([]);
  const { setHeaderItems } = useContext(LayoutContext);
  const { scrollWrapperRef } = useContext(ScrollWrapperContext);

  useEffect(() => {
    // Scroll to top when the component mounts to prevent loading more chats from the bottom of the page
    scrollWrapperRef?.current?.scrollTo(0, 0);
  }, [scrollWrapperRef?.current]);

  useEffect(() => {
    setChatIdsToDelete([]);
  }, [chatsInHistory]);

  useEffect(() => {
    // TODO: Improve to not store component in a state
    setHeaderItems([
      <ChatHistoryHeader
        key="chat-history-header"
        isLoading={chatHistoryState === ChatHistoryState.Loading}
        canDeleteAllChats={chatsInHistory.length > 0}
        handleDeleteAllChats={() => onDeleteChats({ shouldDeleteAll: true })}
      />,
    ]);
    return () => {
      setHeaderItems([]);
    };
  }, [setHeaderItems, chatHistoryState, chatsInHistory]);

  useEffect(() => {
    if (flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803) return;
    navigate('/');
  }, [flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803, navigate]);

  const deselectAllChats = () => setChatIdsToDelete([]);

  const handleClickEscape = useCallback(
    (event) => {
      if (Object.keys(chatIdsToDelete).length === 0 || event.key !== 'Escape') return;
      deselectAllChats();
    },
    [chatIdsToDelete],
  );

  useKeyPress(handleClickEscape);

  const addChatIdToDelete = useCallback(
    (chatId: string) => {
      setChatIdsToDelete([...chatIdsToDelete, chatId]);
    },
    [chatIdsToDelete],
  );

  const removeChatIdToDelete = useCallback(
    (chatId: string) => {
      setChatIdsToDelete(chatIdsToDelete.filter((id) => id !== chatId));
    },
    [chatIdsToDelete],
  );

  const handleUpdateFilter = async (
    filterId: keyof ChatHistoryFilter,
    selectedOption: string | string[],
  ) => {
    const newFilters = getCleanedFilters(chatHistoryFilters, filterId, selectedOption);
    onFilterUpdate(newFilters);
  };

  const handleLoadMore = async () => {
    await loadMore(chatHistoryFilters);
  };

  const handleDeleteChats = async (action: ChatHistoryDeleteAction) => {
    if (action === ChatHistoryDeleteAction.ALL) {
      onDeleteChats({ shouldDeleteAll: true });
    }
    if (action === ChatHistoryDeleteAction.SELECTED) {
      onDeleteChats({ chatIds: chatIdsToDelete });
    }
  };

  const hasFiltered = Object.keys(chatHistoryFilters).length > 0;

  return (
    <>
      {chatHistoryState === ChatHistoryState.Error && <ChatHistoryError />}
      {chatHistoryState === ChatHistoryState.InitialLoad && <ChatHistoryListSkeleton />}
      {[ChatHistoryState.Ready, ChatHistoryState.Loading].includes(chatHistoryState) && (
        <>
          <ChatHistoryControls handleUpdateFilter={handleUpdateFilter} />
          <div className="mx-auto max-w-[928px] space-y-4 px-4 py-4 md:space-y-6 md:py-7">
            {!chatsInHistory.length && hasFiltered && <ChatHistoryNoResults />}
            {chatsInHistory.map((chat) => {
              return (
                <div key={`${chat.id}-history-item`}>
                  <ChatHistoryListItem
                    chat={chat}
                    isSelected={chatIdsToDelete.includes(chat.id)}
                    onAddChatIdToDelete={addChatIdToDelete}
                    onRemoveChatIdToDelete={removeChatIdToDelete}
                  />
                </div>
              );
            })}
            {chatIdsToDelete.length > 0 && (
              <ChatHistoryActionBar
                chatIdsToDelete={chatIdsToDelete}
                onDeselectAll={deselectAllChats}
                onDelete={handleDeleteChats}
              />
            )}
          </div>
          {canLoadMore && (
            <ScrollPagination
              isLoading={chatHistoryState === ChatHistoryState.Loading}
              enableInfiniteScroll
              onClick={handleLoadMore}
            />
          )}
        </>
      )}
    </>
  );
};
