import { MessageAssessment } from '@/@generated/graphql';
import { AssessmentBadgeVariant } from '@/components/Chat/Assessment/AssessmentBadge';
import { IconSentimentVariant } from '@unique/icons';
import { capitalize } from 'lodash';

export enum AssessmentRating {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  UNKNOWN = 'UNKNOWN',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export enum AssessmentType {
  HALLUCINATION = 'HALLUCINATION',
  COMPLIANCE = 'COMPLIANCE',
}

export enum AssessmentStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export const getAssessmentBadgeLabel = (assessment: Partial<MessageAssessment>) => {
  if (assessment.title) return assessment.title;
  if (assessment.status === AssessmentStatus.PENDING) return 'AI answer evaluation in progress..';
  if (assessment.status === AssessmentStatus.ERROR)
    return `AI answer evaluation for ${assessment.type.toLowerCase()} failed`;
  if (assessment.type === AssessmentType.HALLUCINATION) {
    switch (assessment.label) {
      case AssessmentRating.POSITIVE:
      case AssessmentRating.VERIFIED:
      case AssessmentRating.GREEN:
        return 'No Hallucination Detected';
      case AssessmentRating.UNVERIFIED:
      case AssessmentRating.YELLOW:
        return 'Hallucination Warning';
      case AssessmentRating.NEGATIVE:
      case AssessmentRating.RED:
        return 'High Hallucination';
      default:
        return 'Unknown Hallucination Status';
    }
  }

  if (assessment.type === AssessmentType.COMPLIANCE) {
    switch (assessment.label) {
      case AssessmentRating.POSITIVE:
      case AssessmentRating.VERIFIED:
      case AssessmentRating.GREEN:
        return 'Compliance OK';
      case AssessmentRating.UNVERIFIED:
      case AssessmentRating.YELLOW:
        return 'Compliance Warning';
      case AssessmentRating.NEGATIVE:
      case AssessmentRating.RED:
        return 'Non Compliant';
      default:
        return 'Unknown Compliance Status';
    }
  }

  return capitalize(assessment.type);
};

export const mapEvaluationToIconSentimentVariant = (assessmentRating: AssessmentRating) => {
  switch (assessmentRating) {
    case AssessmentRating.NEGATIVE:
    case AssessmentRating.RED:
      return IconSentimentVariant.NEGATIVE;
    case AssessmentRating.POSITIVE:
    case AssessmentRating.VERIFIED:
    case AssessmentRating.GREEN:
      return IconSentimentVariant.POSITIVE;
    case AssessmentRating.UNVERIFIED:
    case AssessmentRating.YELLOW:
      return IconSentimentVariant.NEUTRAL;
    default:
      return IconSentimentVariant.NEUTRAL;
  }
};

export const mapEvaluationToAssessmentBadgeVariant = (assessment: Partial<MessageAssessment>) => {
  if (assessment.status === AssessmentStatus.PENDING) return AssessmentBadgeVariant.LOADING;
  if (assessment.status === AssessmentStatus.ERROR) return AssessmentBadgeVariant.ERROR;
  switch (assessment.label) {
    case AssessmentRating.NEGATIVE:
    case AssessmentRating.RED:
      return AssessmentBadgeVariant.FAILED;
    case AssessmentRating.POSITIVE:
    case AssessmentRating.VERIFIED:
    case AssessmentRating.GREEN:
      return AssessmentBadgeVariant.SUCCESS;
    case AssessmentRating.UNVERIFIED:
    case AssessmentRating.YELLOW:
      return AssessmentBadgeVariant.WARNING;
    default:
      return AssessmentBadgeVariant.UNKNOWN;
  }
};
