import { ContentByIdQuery, Maybe } from '@/@generated/graphql';
import { Reference } from '@/lib/swr/types';

export const INTERNAL_URL_PREFIX = 'unique://content/';

export const isInternalUniqueUrl = (url: Maybe<string> | undefined) =>
  url && url.startsWith(INTERNAL_URL_PREFIX);

export const parseContentIdFromInternalUrl = (url: Maybe<string> | undefined) => {
  const urlParts = url?.split('/');
  return urlParts?.length > 3 ? urlParts[3] : '';
};

export const mapReadUrlForReference = (
  reference: Reference,
  contentData: ContentByIdQuery,
  redirectInternalStorageOnly: boolean,
) => {
  if (!reference) return;
  if (isInternalUniqueUrl(reference.url || '')) {
    const contentId = parseContentIdFromInternalUrl(reference.url || '');
    const content = contentData?.contentById?.find((content) => content.id === contentId);
    if (content) {
      return {
        ...reference,
        url: redirectInternalStorageOnly
          ? `${INTERNAL_URL_PREFIX}${encodeURIComponent(content.id)}`
          : content.url ?? `${INTERNAL_URL_PREFIX}${encodeURIComponent(content.id)}`,
        pdfPreviewName: content.previewPdfFileName,
        contentId: content.id,
        mimeType: content.mimeType,
      };
    }
  }
  return reference;
};

export const mapReadUrlForReferences = (
  references: Reference[],
  contentData: ContentByIdQuery,
  redirectInternalStorageOnly: boolean,
) => {
  return references.map((reference) =>
    mapReadUrlForReference(reference, contentData, redirectInternalStorageOnly),
  );
};

/**
 * Extract the file type from the reference name
 * @param referenceName - The name of the reference which can have two formats:
 *                     - "fileName.pdf : 1" (if its a pdf with a page number)
 *                     - "fileName.txt" (no page number)
 * @returns The file extension (e.g., 'pdf', 'txt')
 */
export const extractFileTypeFromReferenceName = (referenceName: string | undefined | null) => {
  if (!referenceName) return '';
  const nameParts = referenceName.split(' : ')[0].split('.');
  return nameParts.length > 1 ? nameParts.pop() || '' : '';
};
