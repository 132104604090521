import { getCSSVariableValue } from '@unique/component-library/helpers/getCSSVariableValue';
import { ChartOptions, ColorType, LineStyle } from 'lightweight-charts';
import { DeepPartial } from 'react-hook-form';

/**
 * Get the config for the financial chart
 * @param clientWidth - The width of the chart container
 * @returns The config for the financial chart
 */
export function getConfig(clientWidth): DeepPartial<ChartOptions> {
    // Get the text color from the theme
    const textColor = `${getCSSVariableValue('--color-on-background-dimmed')}`;
    const backgroundLineColor = `${getCSSVariableValue('--color-background-variant')}`;
    const scaleLineColor = `${getCSSVariableValue('--color-control')}`;

    return {
        layout: {
            background: { type: ColorType.Solid, color: 'transparent' },
            textColor: textColor,
            attributionLogo: false,
            panes: {
                enableResize: false,
            }
        },
        grid: {
            vertLines: {
                visible: false,
            },
            horzLines: {
                color: `${backgroundLineColor}`,
                style: LineStyle.Dashed,
                visible: true,
            },
        },
        handleScroll: false,  // Disable scrolling
        handleScale: false,  // Disable scaling
        width: clientWidth,
        height: 300,
        leftPriceScale: {
            visible: true,
            borderVisible: false,
            scaleMargins: {
                top: 0.1,
                bottom: 0.1,
            },
        },
        rightPriceScale: {
            visible: false,
        },
        timeScale: {
            timeVisible: true,
            lockVisibleTimeRangeOnResize: true,
            borderColor: scaleLineColor,
        },
    };
}