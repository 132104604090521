import {
  getUseAddDueDiligenceMetaDataKey,
  getUseCreateDueDiligenceKey,
  useAddDueDiligenceMetaData,
  useCreateDueDiligence,
} from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import { ButtonIcon, ButtonVariant, Spinner, Tooltip } from '@unique/component-library';
import { useNavigate } from 'react-router-dom';
import useDueDiligenceArtifacts from '../DueDiligence/hooks/useDueDiligenceArtifacts';
import { ReactNode } from 'react';

type Props = {
  assistantId: string;
  source: string;
};

export default function SkipCreateDueDiligenceButton({ assistantId, source }: Props) {
  const navigate = useNavigate();

  const questionFileIds =
    useAppSelector((state) => state.persistedDueDiligence.questionFileIds) || [];

  const dueDiligenceDetails = useAppSelector(
    (state) => state.persistedDueDiligence.dueDiligenceDetails,
  );

  const { questionsIngestionInProgress } = useDueDiligenceArtifacts({
    chatId: dueDiligenceDetails?.chatId,
    questionFileIds,
  });

  const { trigger: addDueDiligenceMetaData } = useAddDueDiligenceMetaData(
    getUseAddDueDiligenceMetaDataKey(),
  );

  const goToSheet = (sheetId: string) => {
    navigate(`/space/${assistantId}/${sheetId}`);
  };

  const skipButtonClicked = () => {
    if (source === 'add-sources') {
      // Send question file to the agent
      addDueDiligenceMetaData({
        dueDiligenceId: dueDiligenceDetails.dueDiligenceId,
        questionFileIds,
      });
      goToSheet(dueDiligenceDetails.sheetId);
      return;
    }
    if (source === 'import-questions') {
      createDueDiligence({ assistantId });
    }
  };

  const { trigger: createDueDiligence } = useCreateDueDiligence(getUseCreateDueDiligenceKey(), {
    onSuccess: ({ createDueDiligence }) => {
      goToSheet(createDueDiligence.magicTableSheet.id);
    },
  });

  return (
    <div className="flex justify-end">
      {questionsIngestionInProgress ? (
        <Tooltip
          wrapper={<SkipButton isDisabled={questionsIngestionInProgress} />}
          arrowPosition="none"
          className="w-[260px]"
        >
          This action will be possible once all the uploaded files have been ingested.
        </Tooltip>
      ) : (
        <SkipButton
          isDisabled={questionsIngestionInProgress}
          icon={questionsIngestionInProgress ? <Spinner size={16} wrapperClasses="pt-1" /> : null}
          onClick={skipButtonClicked}
        />
      )}
    </div>
  );
}

const SkipButton = ({
  isDisabled,
  icon,
  onClick,
}: {
  isDisabled?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}) => (
  <ButtonIcon variant={ButtonVariant.TERTIARY} onClick={onClick} icon={icon} disabled={isDisabled}>
    Skip
  </ButtonIcon>
);
