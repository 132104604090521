export const FinancialChartLegend = ({ series, colors, isLoading }) => {

    if (isLoading) {
        return <></>;
    }
    
    return (
        <div className="">
            { series.length > 1 && <div className="flex flex-row gap-4 pl-2 pt-4 pr-2 justify-start">
                { series.map((serie, index) => (
                    <div key={`${index}-${serie.info.ticker}`} className="flex items-center gap-2">
                        <div className="w-4 h-4 rounded-full" style={{backgroundColor: colors[index % colors.length]}}></div>
                        <span className="text-sm">{serie.info.ticker}</span>
                    </div>
                ))}
            </div> }
        </div>
    );
};