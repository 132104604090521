'use client';
import {
  ContentUpsertByChatMutation,
  getSdk,
  MagicTableSheetQuery,
  OwnerType,
} from '@/@generated/graphql';
import { getUseContentByChatQueryKey, useContentUpsertByChatMutation } from '@/lib/swr/hooks';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
import { ButtonIcon, ButtonVariant, Dropzone, Spinner } from '@unique/component-library';
import { IconAddColumn, IconArrowDown, IconShapeHeadEngineXS, IconUpload } from '@unique/icons';
import { clientContextValues } from '@unique/next-commons/swr';
import {
  SUPPORTED_MIME_PDF_DOCS_CSV,
  SUPPORTED_MIME_TYPES_EXCEL,
  ToastVariant,
  useFileUpload,
  useToast,
} from '@unique/shared-library';
import { useParams } from 'react-router-dom';
import { FC, lazy, useContext, useRef, useState } from 'react';
import { ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';
import Dropdown, { DropdownOptions } from '@unique/component-library/src/Dropdown';
import cn from 'classnames';
import { DUE_DILIGENCE_MAX_FILES } from './utils/constants';
import {
  setShowUploadTrayNotification,
  setUploadedContentIds,
  useAppDispatch,
  useAppSelector,
} from '@/store';

const DueDiligenceSystemTrayNotification = lazy(
  () => import('./DueDiligenceSystemTrayNotification'),
);

type Props = {
  disabled?: boolean;
  handleOpenKnowledgeBaseModal?: (value: boolean) => void;
  setQuestionFileIds?: (value: string[]) => void;
  dueDiligenceId: string;
  chatId: string;
};

const SUPPORTED_UPLOAD_FILES = { ...SUPPORTED_MIME_PDF_DOCS_CSV, ...SUPPORTED_MIME_TYPES_EXCEL };

const DueDiligenceImportQuestionsButton: FC<Props> = ({
  disabled,
  handleOpenKnowledgeBaseModal,
  setQuestionFileIds,
  dueDiligenceId,
  chatId,
}) => {
  const { showToast } = useToast();

  const showNotification = useAppSelector((state) => state.dueDiligence.showUploadTrayNotification);

  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>();

  const { maxFileSize } = useContext(ConfigurationContext);
  // UPSERT CONTENT
  const { trigger: upsertContent } = useContentUpsertByChatMutation(
    getUseContentByChatQueryKey({
      chatId: '',
    }),
  );

  const { spaceSlug: sheetId } = useParams<{ spaceId: string; spaceSlug: string }>();

  const [isUploading, setIsUploading] = useState(false);
  let contentId = null;

  const handleChatUpload = async (
    acceptedFiles: FileWithPath[],
    fileRejections: FileRejection[],
  ) => {
    if (disabled || isUploading) {
      return;
    }

    if (!acceptedFiles.length) {
      return;
    }

    if (fileRejections.length) {
      const code = fileRejections[0].errors[0].code;
      let message = `Unknown Error (${code})`;
      switch (code) {
        case ErrorCode.FileTooLarge:
          message = `File too large. The current limit is ${maxFileSize / 1024 / 1024} MB per file.`;
          break;
        case ErrorCode.FileInvalidType:
          message = 'Invalid File Type';
          break;
        case ErrorCode.FileTooSmall:
          message = 'File size is too small';
          break;
        case ErrorCode.TooManyFiles:
          message = `Too many files. You can upload a maximum of ${DUE_DILIGENCE_MAX_FILES} files at once.`;
          break;
      }
      showToast({ message, variant: ToastVariant.ERROR });
      return;
    }

    try {
      setIsUploading(true);
      dispatch(setShowUploadTrayNotification(true));
      // Fetch MagicTableSheet to get chatId
      const sdkDefault = getSdk(clientContextValues.defaultClient);
      const contentIngestionStateQuery: MagicTableSheetQuery = await sdkDefault.MagicTableSheet({
        where: {
          id: {
            equals: sheetId,
          },
        },
      });

      const { chatId } = contentIngestionStateQuery.magicTableSheet;
      // Upload file to chat
      const contentIds = await handleUpload(acceptedFiles, fileRejections, { newChatId: chatId });
      dispatch(setUploadedContentIds(contentIds));
    } catch (error) {
      console.error('Upload failed:', error);
      showToast({ message: 'Upload failed. Please try again.', variant: ToastVariant.ERROR });
      return;
    } finally {
      setIsUploading(false);
    }
  };

  // Upload File
  const { handleUpload } = useFileUpload<OwnerType>({
    setError: (error) => showToast({ message: error, variant: ToastVariant.ERROR }),
    chatOwnerType: OwnerType.Chat,
    upsertContent: async (extraArgument, options) => {
      const response: ContentUpsertByChatMutation = await upsertContent(extraArgument, options);
      if (response?.contentUpsertByChat?.id) {
        contentId = response.contentUpsertByChat.id;
        setQuestionFileIds?.([contentId]);
      }
      return response;
    },
    ownerType: OwnerType.Chat,
    upsertContentAttributeName: 'contentUpsertByChat',
    storeInternally: true,
    maxFiles: DUE_DILIGENCE_MAX_FILES,
    maxFileSize: maxFileSize,
  });

  const onColumnSelect = (option: DropdownOptions) => {
    if (option.value === 'knowledgeBase') {
      // open knowledge base modal
      handleOpenKnowledgeBaseModal(true);
      return;
    }
    const parent = ref.current;
    const input = parent.querySelector('input');
    input?.click();
  };

  return (
    <>
      <Dropdown
        dropdownOptions={[
          {
            id: '1',
            title: 'From Knowledge Base',
            icon: <IconShapeHeadEngineXS />,
            value: 'knowledgeBase',
          },
          {
            id: '2',
            title: 'Upload Files',
            icon: <IconUpload />,
            value: 'upload',
          },
        ]}
        onSelect={(option) => onColumnSelect(option)}
        className="w-[300px]"
      >
        {(isOpen) => (
          <ButtonIcon
            disabled={isUploading || disabled}
            variant={ButtonVariant.SECONDARY}
            icon={isUploading ? <Spinner size={16} wrapperClasses="pt-1" /> : <IconAddColumn />}
          >
            <span className="flex items-center gap-x-3">
              <span className="whitespace-nowrap sm:inline">Import Questions </span>
              <IconArrowDown
                className={cn({
                  'font-bold transition duration-200': true,
                  'rotate-180': isOpen,
                })}
                width="14px"
                height="14px"
              />
            </span>
          </ButtonIcon>
        )}
      </Dropdown>
      {!disabled && (
        <Dropzone
          ref={ref}
          onDrop={handleChatUpload}
          onError={(e) => console.log(e)}
          accept={SUPPORTED_UPLOAD_FILES}
          maxFiles={DUE_DILIGENCE_MAX_FILES}
          maxSize={maxFileSize}
          additonalClassname="h-full pb-2"
          wrapperClassname="hidden"
        />
      )}
      {showNotification && (
        <DueDiligenceSystemTrayNotification
          chatId={chatId}
          dueDiligenceId={dueDiligenceId}
          isOpen={showNotification}
        />
      )}
    </>
  );
};

export default DueDiligenceImportQuestionsButton;
