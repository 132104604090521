import { extractPagesFromName, hidePagesFromName } from '@/helpers/pdfPreview';
import { extractFileTypeFromReferenceName } from '@/helpers/references';
import { Reference } from '@/lib/swr/types';
import { FileTypeIcon } from '@unique/component-library';
import { IconArrowDown, IconNewWindow } from '@unique/icons';
import cn from 'classnames';
import { FC, useMemo } from 'react';

interface ChatReferenceProps {
  reference: Reference;
  isActive?: boolean;
  isLoading?: boolean;
  onClick: (reference: Reference, isActive: boolean) => void;
  checkPdfPreviewEnabled: (reference: Reference) => boolean;
}

export const ChatReference: FC<ChatReferenceProps> = ({
  reference,
  isActive = false,
  isLoading = false,
  onClick,
  checkPdfPreviewEnabled,
}) => {
  const referenceFileType = extractFileTypeFromReferenceName(reference.name);
  const referencePages = extractPagesFromName(reference.name);
  const pdfPreviewEnabled = useMemo(() => checkPdfPreviewEnabled(reference), [reference]);

  return (
    <div
      className={cn({
        'border-background hover:bg-background flex items-center gap-x-3 border-b p-2.5 transition first-of-type:border-t':
          true,
        'bg-background': isActive,
        'cursor-pointer': !!reference.url && !isLoading,
        'opacity-50': isLoading,
      })}
      onClick={reference.url ? () => onClick(reference, isActive) : undefined}
    >
      <div
        className={cn({
          'subtitle-2 flex h-4 w-4 items-center justify-center rounded-[4px]': true,
          'bg-background-variant text-on-background-dimmed': !isActive,
          'bg-attention-variant text-on-attention-variant': isActive,
        })}
      >
        {reference.sequenceNumber}
      </div>

      <FileTypeIcon dimensions="18px" isExternal={false} fileType={referenceFileType} />
      <div className="text-on-background-main subtitle-2 flex-1 truncate">
        {reference.name ? hidePagesFromName(reference.name) : 'Untitled document'}
      </div>

      {referencePages.length > 0 && (
        <div className="subtitle-2 text-on-background-dimmed ml-auto">
          pp.: {referencePages.join(', ')}
        </div>
      )}

      {pdfPreviewEnabled ? (
        <div className="text-on-background-dimmed ml-auto">
          <IconArrowDown
            className={cn({ '-rotate-90': true, 'rotate-90': isActive })}
            width="12px"
          />
        </div>
      ) : (
        <div className="text-on-background-dimmed ml-auto">
          <IconNewWindow width="15px" />
        </div>
      )}
    </div>
  );
};
