import { IconClose } from '@unique/icons';
import { FC } from 'react';

export enum ChatHistoryDeleteAction {
  ALL = 'all',
  SELECTED = 'selected',
}

type ChatHistoryActionBarProps = {
  chatIdsToDelete: string[];
  onDelete: (action: ChatHistoryDeleteAction) => void;
  onDeselectAll: () => void;
};

export const ChatHistoryActionBar: FC<ChatHistoryActionBarProps> = ({
  chatIdsToDelete,
  onDeselectAll,
  onDelete,
}) => {
  return (
    <div className="sticky bottom-10 flex justify-center">
      <div className="body-2 bg-secondary text-on-secondary flex items-center gap-x-4 whitespace-nowrap rounded-md px-4 py-3 font-semibold">
        {chatIdsToDelete.length} {chatIdsToDelete.length > 1 ? 'chats' : 'chat'} selected:
        <a
          className="block cursor-pointer"
          onClick={() => onDelete(ChatHistoryDeleteAction.SELECTED)}
        >
          Delete Selected
        </a>
        <a className="block cursor-pointer" onClick={() => onDelete(ChatHistoryDeleteAction.ALL)}>
          Delete All
        </a>
        <a className="cursor-pointer" onClick={onDeselectAll}>
          <IconClose />
        </a>
      </div>
    </div>
  );
};
