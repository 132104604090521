import { ButtonIcon, ButtonSize, ButtonVariant, Drawer, MenuItem } from '@unique/component-library';
import { IconDelete, IconEdit, IconMeatballsMenu, IconNewChat } from '@unique/icons';
import { FC, useState, MouseEvent } from 'react';
import { ChatToRename, useChatRename } from '../ChatProvider';
import { useChatDelete } from '@/hooks/useChatDelete';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

interface ChatActionsProps {
  onClickNewChat: () => void;
  currentChat?: ChatToRename;
  ctaSubject?: string;
}

export const ChatHeaderActions: FC<ChatActionsProps> = ({
  onClickNewChat,
  currentChat,
  ctaSubject,
}) => {
  const { handleClickRename } = useChatRename();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { deleteChatIds } = useChatDelete();
  const location = useLocation();
  const showBackToHistory = location.state?.showBackToHistory;

  const handleClickRenameChat = (event: MouseEvent<HTMLButtonElement>) => {
    handleClickRename(event, currentChat);
    setIsDrawerOpen(false);
  };

  const handleDeleteChat = (e: MouseEvent) => {
    e.preventDefault();
    deleteChatIds([currentChat?.id], { chats: [currentChat] });
  };

  return (
    <>
      <ButtonIcon
        icon={<IconNewChat />}
        onClick={onClickNewChat}
        className={cn({
          '!hidden truncate whitespace-nowrap sm:!flex': true,
          'order-2 ml-3': showBackToHistory,
        })}
      >
        New {ctaSubject}
      </ButtonIcon>
      {/* meatballs menu including chat delete action is only visible on mobile */}
      <div className="order-2 ml-auto px-1.5 sm:hidden">
        <ButtonIcon
          icon={<IconMeatballsMenu />}
          variant={ButtonVariant.SECONDARY}
          buttonSize={ButtonSize.SMALL}
          onClick={() => {
            setIsDrawerOpen(true);
          }}
        />
      </div>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="mt-8">
          <MenuItem
            icon={<IconNewChat />}
            title={`New ${ctaSubject}`}
            handleClick={onClickNewChat}
          />
          <MenuItem
            icon={<IconEdit width="18px" />}
            title={`Rename ${ctaSubject}`}
            handleClick={handleClickRenameChat}
          />
          <MenuItem
            icon={<IconDelete />}
            title={`Delete ${ctaSubject}`}
            handleClick={handleDeleteChat}
          />
        </div>
      </Drawer>
    </>
  );
};
