import { SortOrder } from '@/@generated/graphql';
import { getUseChatsQueryKey, useChatQuery, useChatUpdateTitleMutation } from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';
import { FeatureFlagContext, ToastVariant, useToast } from '@unique/shared-library';
import { useContext } from 'react';

const log = logger.child({ package: 'chat', namespace: 'chat:hooks:useChatUpdateTitle' });

export function useChatUpdateTitle(chatId: string) {
  const { showToast } = useToast();
  const { flags } = useContext(FeatureFlagContext);
  const { trigger: chatUpdateTitleMutation, isMutating: isRenamingChat } =
    useChatUpdateTitleMutation(
      getUseChatsQueryKey({
        orderBy: [{ createdAt: SortOrder.Desc }],
        take: flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803 ? 5 : 50,
      }),
    );
  const { mutate: updateChatData } = useChatQuery(
    chatId
      ? {
          chatId,
        }
      : null,
  );

  const updateChatTitle = (title: string, onUpdateChatTitleDone?: () => void) => {
    chatUpdateTitleMutation(
      { chatId, title },
      {
        revalidate: true,
        throwOnError: false,
        onSuccess: () => {
          updateChatData();
          showToast &&
            showToast({
              message: `Chat renamed to ${title}`,
              variant: ToastVariant.SUCCESS,
              duration: 6000,
            });
          onUpdateChatTitleDone && onUpdateChatTitleDone();
        },
        onError: (err) => {
          log.error(err);
          showToast &&
            showToast({
              message: 'There was an error while updating the chat title',
              variant: ToastVariant.ERROR,
            });
          onUpdateChatTitleDone && onUpdateChatTitleDone();
        },
      },
    );
  };

  return { updateChatTitle, isRenamingChat };
}
