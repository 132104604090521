import { Assistant, ChatAssistant } from '@/lib/swr/types';
import { getAllowedFileTypes } from './getAllowedFileTypes';

export const DEFAULT_FILE_TYPES_DOCUMENT_TRANSLATOR = 'DOCX, XLSX, PPTX';

export const getAcceptableFileTypes = (assistant: Assistant | ChatAssistant) => {
  const imageFileTypes = 'PNG, JPG, JPEG, WEBP, GIF';
  const DEFAULT_DOCUMENT_TYPES = 'PDF, DOCX, XLSX, PPTX, CSV, HTML, MD, TXT';

  const { settings, modules } = assistant || {};
  const allowUploadingImages = !!settings?.imageUpload || false;
  const allowOnlyImages = modules?.every(
    (module) => module.name === 'ImageSummarizer' || module.name === 'ImageUpload',
  );

  const allowedFileTypes = getAllowedFileTypes(assistant);

  const documentFileTypes = allowedFileTypes
    ? allowedFileTypes.join(', ').replaceAll('.', '').toUpperCase()
    : DEFAULT_DOCUMENT_TYPES;

  if (allowOnlyImages) {
    return imageFileTypes;
  }

  if (!allowUploadingImages) {
    return documentFileTypes;
  }

  return `${documentFileTypes}, ${imageFileTypes}`;
};
