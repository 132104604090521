import { ExtendedChatWhereInput } from '@/@generated/graphql';
import { ChatHistoryFilter } from '@/hooks/useChatHistory';
import { getDateRange } from '@unique/component-library';

export const getExtendedChatWhereInput = (chatHistoryFilter: ChatHistoryFilter) => {
  let where: ExtendedChatWhereInput = {};

  if (chatHistoryFilter.assistantId) {
    where = {
      ...where,
      assistant: {
        is: {
          id: {
            equals: chatHistoryFilter.assistantId,
          },
        },
      },
    };
  }

  if (chatHistoryFilter.lastMessageTimestamp) {
    const [start, end] = getDateRange(chatHistoryFilter.lastMessageTimestamp);
    where = {
      ...where,
      lastMessageStartDate: start,
      lastMessageEndDate: end,
    };
  }

  where = {
    ...where,
    // always include searchTerm so that the backend returns only last message instead of all messages
    searchTerm: chatHistoryFilter.searchTerm ?? '',
  };

  return where;
};
