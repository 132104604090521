import { io, Socket } from 'socket.io-client';
import { SpeechToTextProvider } from './types';
import { PCMRecordingService } from '../audio-recorder';
import { logger } from '@unique/next-commons/logger';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:chat-voice-input:unique-adapter',
});

export class UniqueSpeechToTextClient implements SpeechToTextProvider {
  private socket: Socket;
  private pcmRecordingService: PCMRecordingService;

  // Example available languages; adjust as needed.
  public readonly availableLanguages: string[] = ['en-US', 'de-DE', 'it-IT', 'fr-FR'];

  // Callback storage
  public onTranscript: (text: string) => void;
  public onInterimTranscript?: (text: string) => void;
  public onError?: (error: unknown) => void;
  public onComplete?: () => void;
  public onReady?: () => void;

  constructor(
    private serverUrl: string,
    private mediaStream: MediaStream,
    private accessToken: string,
  ) { }

  /**
   * Waits for the socket connection to be established.
   */
  public async initialize(): Promise<void> {
    if (this.socket?.connected) {
      log.debug('Socket already connected');
      return;
    }

    return new Promise((resolve) => {
      this.socket = io(`${this.serverUrl}/speech-to-text`, {
        path: '/speech/socket.io',
        auth: {
          token: this.accessToken,
        },
        query: {
          token: this.accessToken,
        },
        extraHeaders: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        withCredentials: true,
        transports: ['websocket'],
      });
      this.pcmRecordingService = new PCMRecordingService();

      // Handle connection error
      this.socket.on('connect_error', (error) => {
        log.error(error.message);
        this.onError('Connection not connect to service');
        resolve();
      });

      // handle connection timeout
      this.socket.on('connect_timeout', () => {
        log.error('Connection timeout');
        this.onError('Connection timedout');
        resolve();
      });

      // handle generic errors
      this.socket.on('error', () => {
        this.onError('Connection error');
        resolve();
      });

      this.socket.on('connect', () => {
        log.debug('Connected to speech-to-text WebSocket server');
        resolve();
        this.onReady();
      });

      this.socket.on('disconnect', () => {
        log.debug('Disconnected from speech-to-text WebSocket server');
        this.clean();
      });

      // Listen for  recognized transcript
      this.socket.on('speech-to-text-result', (data: { text: string; isFinal: boolean }) => {
        log.debug(data.text);
        this.onTranscript?.(data.text);
      });

      // Listen for final recognized transcript
      this.socket.on('speech-to-text-final', () => {
        this.onComplete?.();
      });

      // Listen for errors during recognition
      this.socket.on('speech-to-text-error', (data: { message: string }) => {
        log.error('Speech recognition error');
        this.onError?.(data.message);
        this.onComplete?.();
      });
    });
  }

  /**
   * Starts the speech recognition process.
   */
  async startRecognition(language?: string): Promise<boolean> {
    this.socket.emit('start-speech-to-text', {
      language,
    });

    try {
      // Request microphone access.
      // Set the onDataAvailable callback to forward PCM chunks to the backend.
      this.pcmRecordingService.onDataAvailable = (chunk: ArrayBuffer) => {
        this.sendAudioChunk(chunk);
      };
      // Start recording using the PCMRecordingService.
      const started = await this.pcmRecordingService.startRecording(this.mediaStream);
      return started;
    } catch (error) {
      this.onError?.(`Failed to start recognition: ${error}`);
      return false;
    }
  }

  async stopRecognition(): Promise<boolean> {
    this.pcmRecordingService.stopRecording();
    this.socket.emit('stop-speech-to-text');

    return new Promise((resolve) => {
      // Wait for the confirmation event.
      this.socket.once('speech-to-text-final', () => {
        log.debug('Received stop confirmation from server.');
        resolve(true);
      });

      log.debug('waiting for the last message');
      // Optionally, set a timeout to resolve if confirmation isn't received in time.
      setTimeout(() => {
        resolve(true);
      }, 5_000);
    });
  }

  async clean(): Promise<void> {
    if (!this.socket?.connected) {
      return;
    }

    this.socket.disconnect();
    this.onComplete?.();
  }

  private sendAudioChunk(chunk: ArrayBuffer | Blob | string): void {
    if (chunk instanceof Blob) {
      // Convert Blob to ArrayBuffer before sending.
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        this.socket.emit('speech-to-text-audio-chunk', arrayBuffer);
      };
      reader.readAsArrayBuffer(chunk);
    } else {
      this.socket.emit('speech-to-text-audio-chunk', chunk);
    }
  }
}
