import { GetTransformedMagicTableQuery } from '@/@generated/graphql';
import { useMagicTableQuery } from '@/lib/swr/hooks';
import { setMagicTableSheetUpdates, useAppDispatch, useAppSelector } from '@/store';
import { logger } from '@unique/next-commons/logger';
import { useEffect, useState } from 'react';

export type TransformedMagicTableSheet =
  GetTransformedMagicTableQuery['getTransformedMagicTableSheet']['magicTableSheet'];

const log = logger.child({
  package: 'chat',
  namespace: 'components:due-diligence-component:useDueDiligenceQuery',
});

type Props = { sheetId: string; setIsMenuExpanded: (isMenuExpanded: boolean) => void };

export default function useDueDiligenceQuery({ sheetId, setIsMenuExpanded }: Props) {
  const dueDiligencePayload = useAppSelector((state) => state.dueDiligence.dueDiligencePayload);
  const isGridReady = useAppSelector((state) => state.dueDiligence.isGridReady);
  const [queryPaused, setQueryPaused] = useState(true);
  const dispatch = useAppDispatch();

  const requeryDueDiligence = () => {
    mutate();
  };

  useEffect(() => {
    if (!isGridReady) return;
    setIsMenuExpanded(false);
    setQueryPaused(false);
  }, [isGridReady, dueDiligencePayload?.sheetId, sheetId, queryPaused]);

  const {
    data,
    isLoading: loadingTableData,
    mutate,
  } = useMagicTableQuery(
    { cursor: { id: queryPaused ? null : sheetId } },
    {
      onError: () => {
        log.error('Unable to fetch Magic Table Data');
      },
      onSuccess: (response) => {
        const stateUpdates = response?.getTransformedMagicTableSheet?.magicTableSheet;
        dispatch(
          setMagicTableSheetUpdates({
            id: sheetId,
            state: stateUpdates.state,
            // Show Badge for tables that have content in them
            updatedAt: stateUpdates.rows?.length ? stateUpdates.updatedAt : '',
          }),
        );
      },
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    },
  );

  const dueDiligenceData: TransformedMagicTableSheet =
    data?.getTransformedMagicTableSheet?.magicTableSheet;

  return { dueDiligenceData, loadingTableData, requeryDueDiligence } as const;
}
