/**
 * Get the currency symbol for a given locale and currency
 * @param locale - The locale to use eg 'en-US'
 * @param currency - The currency to get the symbol using ISO code eg 'USD' or 'EUR'
 * @returns The currency symbol as a string eg 'US$' or '€'
 */
export default function getCurrencySymbol (locale: string, currency: string) {
  try {
    return (0).toLocaleString(
      locale,
      {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    ).replace(/\d/g, '').trim();
  } catch (error) {
    return currency;
  }
}