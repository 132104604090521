'use client';

import useChatModals from '@/hooks/useChatModals';
import { Assistant, Message } from '@/lib/swr/types';
import { ButtonIcon, ButtonSize, ButtonVariant, CopyToClipboardWrapper, Modal } from '@unique/component-library';
import { IconCopy } from '@unique/icons';
import { useState } from 'react';
import FeedbackButtons from '../FeedbackButtons';

export default function TranslationFeedback({
  message,
  assistant,
}: {
  message: Partial<Message>;
  assistant: Assistant;
}) {
  const [feedbackGiven, setFeedbackGiven] = useState(null);

  const { onThumbsClick, showModal, modalContent, handleModalClose } = useChatModals({
    currentChatAssistant: assistant,
    onSuccess: (data) => {
      setFeedbackGiven({ positive: data.submitFeedback.positive });
    },
  });

  return (
    <>
      <div className="relative flex gap-3">
        <FeedbackButtons
          feedback={
            typeof message?.feedback?.positive !== 'undefined' ? message.feedback : feedbackGiven
          }
          onThumbsClick={onThumbsClick}
          id={message.id}
          translationSpace={true}
        />
        <CopyToClipboardWrapper text={message.text}>
          {({ copiedText }) => (
            <ButtonIcon
              icon={<IconCopy />}
              className="!bg-background-variant !ml-auto !flex"
              variant={ButtonVariant.SECONDARY}
              buttonSize={ButtonSize.SMALL}
            >
              {`${copiedText ? 'Copied' : 'Copy'}`}
            </ButtonIcon>
          )}
        </CopyToClipboardWrapper>
      </div>
      {/* Modals */}
      <div
        tabIndex={-1}
        className={`pointer-events-none fixed z-50 opacity-0 transition-opacity ${
          showModal ? 'pointer-events-auto opacity-100' : ''
        }`}
      >
        {modalContent && (
          <Modal
            title={modalContent.title}
            icon={modalContent.icon}
            shouldShow={showModal}
            handleClose={handleModalClose}
          >
            {modalContent.children}
          </Modal>
        )}
      </div>
    </>
  );
}
