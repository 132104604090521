/**
 * Returns an array of similar colors for a given color. It will use color science and return an array of colors that are variations of the given color.
 * First colors will always be the provided color so basic case of one line match global theme
 * @param color - The base color to generate variations from.
 * @param count - The number of variations to return.
 * @returns An array of similar colors.
 */
export default function getColorComplements(color: string, count: number) {
    // Create array of specified length and map each index to a color
    return Array.from({length: count}, (_, index) => {
        // First color is always the input color
        if (index === 0) return color;

        // For subsequent colors, make more dramatic adjustments to hue and saturation
        // This ensures colors are distinctly different while maintaining some harmony
        const hueOffset = (120 / count) * index; // Larger 120° range for more variation
        const saturationOffset = (30 / count) * index; // Vary saturation by up to 30%

        // Parse the input color string
        const r = parseInt(color.slice(1,3), 16) / 255;
        const g = parseInt(color.slice(3,5), 16) / 255;
        const b = parseInt(color.slice(5,7), 16) / 255;

        // Convert RGB to HSL
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h = 0;
        let s = 0;
        const l = (max + min) / 2;

        if (max !== min) {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            if (max === r) h = (g - b) / d + (g < b ? 6 : 0);
            else if (max === g) h = (b - r) / d + 2;
            else if (max === b) h = (r - g) / d + 4;

            h *= 60;
        }

        // Apply the offsets with more dramatic changes
        h = (h + hueOffset) % 360;
        s = Math.min(1, s + saturationOffset/100);
        // Adjust lightness to increase contrast between colors
        const lAdjust = (index % 2 === 0) ? 0.1 : -0.1; // Alternate between lighter and darker
        const newL = Math.max(0.2, Math.min(0.8, l + lAdjust)); // Keep within reasonable bounds

        // Convert HSL back to RGB
        const c = (1 - Math.abs(2 * newL - 1)) * s;
        const x = c * (1 - Math.abs((h / 60) % 2 - 1));
        const m = newL - c/2;
        
        let r1, g1, b1;
        if (h >= 0 && h < 60) {
            [r1, g1, b1] = [c, x, 0];
        } else if (h >= 60 && h < 120) {
            [r1, g1, b1] = [x, c, 0];
        } else if (h >= 120 && h < 180) {
            [r1, g1, b1] = [0, c, x];
        } else if (h >= 180 && h < 240) {
            [r1, g1, b1] = [0, x, c];
        } else if (h >= 240 && h < 300) {
            [r1, g1, b1] = [x, 0, c];
        } else {
            [r1, g1, b1] = [c, 0, x];
        }

        // Helper function to convert decimal to 2-digit hex
        const toHex = (n: number) => {
            const hex = Math.round((n + m) * 255).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };

        // Combine RGB components into hex color string
        return `#${toHex(r1)}${toHex(g1)}${toHex(b1)}`;
    });
}