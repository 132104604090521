import { Chat } from '@/lib/swr/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface Stream {
  chatId: string;
  messageId: string;
  lastMessageTimestamp?: number;
}

// map of contentId to imageUrl
export interface ChatImageUrls {
  [key: string]: string;
}

export interface ChatState {
  chatImageUrls: ChatImageUrls;
  streams: Stream[];
  chats?: Chat[];
  ignoreStream: Stream[];
}

const initialState: ChatState = {
  chatImageUrls: {},
  streams: [],
  chats: null,
  ignoreStream: [],
};

export interface setChatImageUrlsInput {
  chatImageUrls: ChatImageUrls;
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<Chat[] | null>) => {
      state.chats = action.payload;
    },
    setChatImageUrls: (state, action: PayloadAction<setChatImageUrlsInput>) => {
      state.chatImageUrls = action.payload.chatImageUrls;
    },
    // Handle streams
    addStream: (state, action: PayloadAction<Stream>) => {
      // If stream already exists, do not add it
      if (state.streams.find((s) => s.messageId === action.payload.messageId)) {
        return;
      }

      // If stream is in ignore list, do nothing
      if (state.ignoreStream.find((s) => s.messageId === action.payload.messageId)) {
        return;
      }

      state.streams.push(action.payload);
    },
    removeStream: (state, action: PayloadAction<Stream>) => {
      // We clean all streams with the same chatId to avoid weird blocked situation with multiple stream.
      // Only affet the stop button UI for now.
      state.streams = state.streams.filter(
        (s) => action.payload.chatId !== s.chatId && action.payload.messageId !== s.messageId,
      );
    },
    ignoreStream: (state, action: PayloadAction<Stream>) => {
      // List of stream to ignore from web socket. This is used for a clean stop button
      // as stream might still come after clicking.
      // If stream already exists, do not add it
      if (state.ignoreStream.find((s) => s.messageId === action.payload.messageId)) {
        return;
      }
      state.ignoreStream.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export * from './actions';
export default chatSlice.reducer;
