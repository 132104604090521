'use client';
import {
  AddDueDilgenceMetaDataDocument,
  MagicTableSheetState,
  Reference,
} from '@/@generated/graphql';
import { deleteSearchParams } from '@/helpers/deleteSearchParams';
import { mapReadUrlForReferences, parseContentIdFromInternalUrl } from '@/helpers/references';
import useChatPdfPreview from '@/hooks/useChatPdfPreview';
import { useAddDueDiligenceMetaData, useContentByIdQuery, useMessageQuery } from '@/lib/swr/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { updateEditingCell } from '@/store/slices/dueDiligence';
import { ButtonIcon, ButtonVariant, Textarea } from '@unique/component-library';
import { IconClose, IconFileAdd, IconPaperPlane } from '@unique/icons';
import { FolderPathSelection, ToastVariant, useToast } from '@unique/shared-library';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DueDiligenceAddQuestionButton from './DueDiligenceAddQuestionButton';
import DueDiligenceExportButton from './DueDiligenceExportButton';
import { TransformedMagicTableSheet } from '@/hooks/useDueDiligenceQuery';
import ButtonTooltipWrapper from './ButtonTooltipWrapper';
import DueDiligenceImportQuestionsButton from './DueDiligenceImportQuestionsButton';
import { logger } from '@unique/next-commons/logger';
import DueDiligenceFilesDropdown from './DueDiligenceFilesDropdown';

type Props = {
  stopEditingCell: () => void;
  dueDiligenceData: TransformedMagicTableSheet;
  chatId: string;
  sheetId: string;
  requeryDueDiligence: () => void;
  scrollToLastRow: () => void;
  exportAsExcel: (fileName: string) => void;
};

const AGENT_BUSY_MESSAGE =
  'Question extraction in progress... This action will be possible once all the questions have been extracted.';

const SOURCE_FILES_TOOLTIP_TEXT =
  'Add sources of company information (PDF, DOCx, CSV). Added sources will be used to answer your Due Diligence questions.';

const IMPORT_QUESTIONS_TOOLTIP_TEXT =
  'Import questions from documents (PDF, DOCx, CSV, XLSX). Documents’ questions will be automatically extracted and added to the table. ';

const log = logger.child({
  package: 'chat',
  namespace: 'chat:components:DueDiligence:DueDiligenceButtons',
});

export default function DueDiligenceButtons({
  stopEditingCell,
  dueDiligenceData,
  chatId,
  requeryDueDiligence,
  sheetId,
  scrollToLastRow,
  exportAsExcel,
}: Props) {
  const dueDiligence = useAppSelector((state) => state.dueDiligence);
  const dispatch = useAppDispatch();
  const [contentId, setContentId] = useState('');

  const isAgentBusy =
    dueDiligence?.magicTableSheetUpdates.state === MagicTableSheetState.Processing;

  const magicTableSheetData = dueDiligenceData?.dueDiligence;

  const { showToast } = useToast();

  const [foundReferenceState, setFoundReferenceState] = useState<Reference | null>(null);

  const [searchParams] = useSearchParams();

  const reference = searchParams.get('reference');
  const requestId = searchParams.get('requestId');
  const messageId = searchParams.get('messageId');

  const { handleClickReference, handleClosePdfPreview } = useChatPdfPreview({
    chatId,
    showPdfHighlighting: true,
    redirectInternalStorageOnly: true,
    onClose: () => {
      searchParams.delete('reference');
      deleteSearchParams(['reference', 'requestId', 'messageId']);
    },
  });

  const messageQueryVariables = {
    chatId,
    messageId,
  };

  const { data: messageQuery, mutate: fetchMessage } = useMessageQuery(
    messageId ? messageQueryVariables : null,
  );

  const { data: contentData } = useContentByIdQuery(
    !contentId
      ? null
      : {
          contentIds: [contentId],
          chatId,
        },
  );

  useEffect(() => {
    if (!reference) return;

    if (!messageQuery?.message?.id) {
      fetchMessage();
    }

    const foundReference = messageQuery?.message?.references.find(
      (ref) => ref.sequenceNumber === Number(reference),
    );

    if (foundReference) {
      setContentId(parseContentIdFromInternalUrl(foundReference.url));
      setFoundReferenceState(foundReference as Reference);
    }
  }, [reference, messageQuery?.message, requestId]);

  useEffect(() => {
    if (!contentData || !contentId) return;
    log.info(`Opening Reference: ${contentId} ${contentData}`);
    handleClickReference(mapReadUrlForReferences([foundReferenceState], contentData, false)[0]);
  }, [contentData, contentId, foundReferenceState, requestId]);

  useEffect(() => {
    return () => {
      handleClosePdfPreview();
    };
  }, []);

  const [showQuestionsSelect, setShowQuestionsSelect] = useState(false);

  const [isImportingQuestions, setIsImportingQuestions] = useState(false);

  const { trigger: addDDMetaData } = useAddDueDiligenceMetaData({
    variables: {},
    query: AddDueDilgenceMetaDataDocument,
  });

  const [showFolderPathSelectionModal, setShowFolderPathSelectionModal] = useState(false);

  const clearEditingSelection = () => {
    stopEditingCell();
    dispatch(updateEditingCell(null));
  };

  const classNames = cn({
    relative: true,
    'pl-24': dueDiligence.editingCell,
  });

  const handleSubmitQuestion = (question: string) => {
    addDDMetaData(
      {
        dueDiligenceId: magicTableSheetData?.id,
        questionTexts: [question],
      },
      {
        onSuccess: () => {
          showToast({
            message: 'Question added',
            variant: ToastVariant.SUCCESS,
          });
          scrollToLastRow();
        },
        onError: () => {
          showToast({
            message: 'Could not add question',
            variant: ToastVariant.ERROR,
          });
        },
      },
    );

    setShowQuestionsSelect(false);
  };

  const handleFolderPathSelected = ({ fileIds }: { fileIds: string[] }) => {
    setShowFolderPathSelectionModal(false);
    addDDMetaData(
      {
        dueDiligenceId: magicTableSheetData?.id,
        ...(isImportingQuestions ? { questionFileIds: fileIds } : { sourceFileIds: fileIds }),
      },
      {
        onSuccess: () => {
          requeryDueDiligence();
          showToast({
            message: isImportingQuestions ? 'Questions imported' : 'Files added',
            variant: ToastVariant.SUCCESS,
          });
        },
        onError: () => {
          showToast({
            message: isImportingQuestions ? 'Could not import questions' : 'Could not add files',
            variant: ToastVariant.ERROR,
          });
        },
      },
    );
  };

  const previouslySelectedFileIds = useMemo(() => {
    return isImportingQuestions
      ? [...(dueDiligenceData?.dueDiligence?.questionFileIds || [])]
      : [...(dueDiligenceData?.dueDiligence?.sourceFileIds || [])];
  }, [isImportingQuestions, dueDiligenceData]);

  return (
    <div className="mx-5 my-5 flex w-full items-center">
      <div className="flex w-full items-center justify-start gap-3 md:justify-between">
        {/* This will be hidden for now until we clear up the requirements and finalize how it should work. */}
        <form className="hidden w-full max-w-sm">
          <div className="relative flex w-full justify-between">
            {dueDiligence.editingCell && (
              <button
                className="bg-background text-on-background-dimmed shadow-background absolute left-2 z-10 my-auto mt-1 flex h-5 w-auto items-center justify-center rounded-md px-2 py-4 text-sm font-bold shadow-sm"
                onClick={clearEditingSelection}
              >
                Cell&nbsp;
                {`${dueDiligence.editingCell?.columnId}${dueDiligence?.editingCell.rowId}`}
                &nbsp;
                <IconClose />
              </button>
            )}
            <Textarea name="text" placeholder="Ask your table anything" className={classNames} />
            <ButtonIcon
              variant={ButtonVariant.PRIMARY}
              className="absolute right-9 top-0 my-auto mb-2.5 h-8 w-8 border-none"
              icon={<IconPaperPlane />}
            />
          </div>
        </form>
        <div className="mt-3 flex flex-wrap items-center gap-3">
          <ButtonTooltipWrapper
            showTooltip
            message={isAgentBusy ? AGENT_BUSY_MESSAGE : SOURCE_FILES_TOOLTIP_TEXT}
          >
            <ButtonIcon
              variant={ButtonVariant.SECONDARY}
              icon={<IconFileAdd />}
              onClick={() => {
                setIsImportingQuestions(false);
                setShowFolderPathSelectionModal(true);
              }}
              className="text-nowrap"
              disabled={isAgentBusy}
            >
              Add Sources
            </ButtonIcon>
          </ButtonTooltipWrapper>
          <ButtonTooltipWrapper showTooltip={isAgentBusy} message={AGENT_BUSY_MESSAGE}>
            <DueDiligenceAddQuestionButton
              setShowQuestionsSelect={setShowQuestionsSelect}
              showQuestionsSelect={showQuestionsSelect}
              disabled={isAgentBusy}
              handleSubmitQuestion={handleSubmitQuestion}
            />
          </ButtonTooltipWrapper>
          {!showQuestionsSelect && (
            <>
              <ButtonTooltipWrapper
                showTooltip
                message={isAgentBusy ? AGENT_BUSY_MESSAGE : IMPORT_QUESTIONS_TOOLTIP_TEXT}
              >
                <DueDiligenceImportQuestionsButton
                  handleOpenKnowledgeBaseModal={() => {
                    setShowFolderPathSelectionModal(true);
                    setIsImportingQuestions(true);
                  }}
                  dueDiligenceId={magicTableSheetData?.id}
                  disabled={isAgentBusy}
                  chatId={chatId}
                />
              </ButtonTooltipWrapper>
              <DueDiligenceExportButton
                sheetId={sheetId}
                chatId={chatId}
                isDisabled={isAgentBusy}
                exportAsExcel={() => exportAsExcel(dueDiligenceData?.name)}
              />
            </>
          )}
          <div className="flex md:hidden">
            <DueDiligenceFilesDropdown
              dueDiligence={dueDiligenceData?.dueDiligence}
              chatId={chatId}
            />
          </div>
        </div>
        <div className="mr-10 mt-3 hidden md:flex">
          <DueDiligenceFilesDropdown
            dueDiligence={dueDiligenceData?.dueDiligence}
            chatId={chatId}
          />
        </div>
      </div>
      {showFolderPathSelectionModal && (
        <FolderPathSelection
          handleClose={() => setShowFolderPathSelectionModal(false)}
          handleSubmit={handleFolderPathSelected}
          submitButtonText="Add files"
          isSelectable
          modalTitle="Select Files or Folders to add"
          previouslySelectedFileIds={previouslySelectedFileIds}
        />
      )}
    </div>
  );
}
