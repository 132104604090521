import { ButtonIcon, ButtonVariant, InputSelect } from '@unique/component-library';
import { IconClose, IconViewFolders } from '@unique/icons';
import { magicTableColumns } from './tableColumns';

const inputSelectMinWidth = 'min-w-[200px] md:min-w-[380px] max-w-[380px]';

export default function DueDiligenceAddQuestionButton({
  setShowQuestionsSelect,
  showQuestionsSelect,
  disabled,
  handleSubmitQuestion,
}: {
  setShowQuestionsSelect: (show: boolean) => void;
  showQuestionsSelect: boolean;
  disabled: boolean;
  handleSubmitQuestion: (question: string) => void;
}) {
  const questionOptions = magicTableColumns.map((question) => ({
    label: question.title,
    value: question.question,
    labelRaw: question.title,
  }));

  return (
    <div>
      {showQuestionsSelect ? (
        <div className={`relative ${inputSelectMinWidth}`}>
          <InputSelect
            id="modulesSelection"
            placeholder="What other information would you like to extract?"
            options={questionOptions}
            handleSelectOption={(option) => handleSubmitQuestion(option.value)}
            tabIndex={4}
            hideArrow
            canCreate
            autoFocus
            createLabel="Add question:"
            className={inputSelectMinWidth}
            classes={{ valueContainer: '!mr-10' }}
          />
          <div className="absolute right-2.5 top-2.5 h-full">
            <span className="cursor-pointer" onClick={() => setShowQuestionsSelect(false)}>
              <IconClose width="20px" height="20px" />
            </span>
          </div>
        </div>
      ) : (
        <ButtonIcon
          variant={ButtonVariant.SECONDARY}
          icon={<IconViewFolders />}
          className="justify-start text-nowrap"
          onClick={() => setShowQuestionsSelect(!showQuestionsSelect)}
          disabled={disabled}
        >
          Add Question
        </ButtonIcon>
      )}
    </div>
  );
}
