'use client';
import { AssistantQuery, ChatUpload } from '@/@generated/graphql';
import { getAcceptableFileTypes } from '@/helpers/getAcceptableFileTypes';
import { ButtonIcon } from '@unique/component-library';
import { IconUploadInChat } from '@unique/icons';
import { LayoutContext, useIsTouchDevice } from '@unique/shared-library';
import cn from 'classnames';
import { FC, MouseEventHandler, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ChatInput from '../Chat/ChatInput';
import { ChatContext } from '../Chat/ChatProvider';
import { TakePhotoCta } from '../Chat/TakePhotoCta';
import ChatDropzone from '../ChatDropzone';
import { SpaceHeader } from './SpaceHeader';
import { SpaceInfo } from './SpaceInfo';
import { SpaceNotFoundPage } from './SpaceNotFoundPage';
import { ChatUploadProgress } from '../Chat/ChatUploadProgress';
import { useFileUploadProgress } from '@/hooks/useFileUploadProgress';

type SpacePageProps = {
  adminUrl: string;
  chatUrl: string;
  assistant: AssistantQuery['assistantByUser'];
};

const ChatComponent: FC<SpacePageProps> = ({ adminUrl, chatUrl, assistant }) => {
  const [selectedPrompt, setSelectedPrompt] = useState<{ prompt: string } | null>(null);
  const [searchParams] = useSearchParams();
  const { spaceId } = useParams<{ spaceId: string }>();
  const { setHeaderItems, setSplitPaneContent } = useContext(LayoutContext);
  const { uploadInputRef, activeUploads } = useContext(ChatContext);
  const isTouchDevice = useIsTouchDevice();
  const { isUploading, uploadProgress } = useFileUploadProgress(activeUploads);
  useEffect(() => {
    setSplitPaneContent(null);
  }, [spaceId, setSplitPaneContent]);

  useEffect(() => {
    const prompt = searchParams.get('prompt');
    if (!prompt?.length) return;

    setSelectedPrompt({ prompt });
  }, [searchParams]);

  useEffect(() => {
    if (assistant) {
      setHeaderItems([<SpaceHeader key={`space-header-${assistant.id}`} title={assistant.name} />]);
    }
    return () => {
      setHeaderItems([]);
    };
  }, [assistant, setHeaderItems, adminUrl]);

  if (!assistant) {
    return <SpaceNotFoundPage basePath={chatUrl} />;
  }

  const chatUploadEnabled = assistant?.chatUpload === ChatUpload.Enabled;

  const handleChatUploadClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (event.button !== 0) return; // If it's not left click, ignore.

    if (!uploadInputRef?.current) {
      console.error('Upload input not found.');
      return;
    }
    uploadInputRef.current.click();
  };

  const acceptableFileTypes = getAcceptableFileTypes(assistant);

  return (
    <div className="mx-auto flex h-[calc(100vh-119px)] max-w-[928px] flex-col px-4 sm:h-[calc(100vh-81px)]">
      <div className="bg-surface relative flex h-full flex-col">
        <SpaceInfo explanation={assistant?.explanation} alert={assistant?.alert} />

        {isUploading && <ChatUploadProgress progress={uploadProgress} />}

        {chatUploadEnabled && !isUploading ? (
          <ChatDropzone assistant={assistant} disableClick={true}>
            <div className="flex h-full flex-col items-center justify-center gap-3">
              <div className="flex w-5/6 flex-row items-center gap-3 sm:flex-col sm:items-center md:w-full">
                <div className="flex sm:ml-0 sm:flex-none">
                  <IconUploadInChat height="80px" width="80px" />
                </div>
                <div className="flex flex-col items-start text-left sm:items-center md:text-center">
                  <p className="text-on-background-main font-extrabold">
                    Upload files to chat with your content
                  </p>
                  <p className="text-on-background-dimmed hidden sm:block">{acceptableFileTypes}</p>
                </div>
              </div>
              <div className="flex flex-row gap-5">
                {isTouchDevice && <TakePhotoCta />}
                <ButtonIcon
                  className={cn({
                    'bg-primary-cta text-on-primary': true,
                    hidden: isTouchDevice && !!assistant?.settings?.disableFilePickerOnMobile,
                  })}
                  onClick={handleChatUploadClick}
                >
                  Upload Files
                </ButtonIcon>
              </div>
            </div>
          </ChatDropzone>
        ) : null}
        <div className="sticky bottom-0 mt-auto">
          <ChatInput
            selectedPrompt={selectedPrompt}
            currentChatAssistant={assistant}
            chatUploadEnabled={chatUploadEnabled}
            handleChatUploadClick={handleChatUploadClick}
            isUploading={isUploading}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
