'use client';
import { FC, useMemo } from 'react';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { IconDeleteModal } from '@unique/icons';
import { truncateChatTitle } from '@/helpers/truncateChatTitle';

type DeleteAllConfirmationProps = {
  chatsTotalCount?: number;
  isDeletingAllChats?: boolean;
  onConfirmation: () => void;
  chatTitle?: string;
};

export const DeleteAllConfirmation: FC<DeleteAllConfirmationProps> = ({
  chatsTotalCount = 0,
  onConfirmation,
  isDeletingAllChats = false,
  chatTitle = null,
}) => {
  const { title, description } = useMemo(() => {
    let title, description;
    if (chatTitle && chatsTotalCount === 1) {
      // Single chat with a specific title
      title = 'Delete Chat';
      description = `"${truncateChatTitle(chatTitle)}"`;
    } else if (isDeletingAllChats) {
      // Deleting all chats
      title = 'Delete all Chats';
      description = 'all Chats';
    } else {
      // Multiple or single chats without a specific title
      const chatWord = chatsTotalCount === 1 ? 'Chat' : 'Chats';
      title = `Delete ${chatsTotalCount} ${chatWord}`;
      description = `${chatsTotalCount} ${chatWord.toLowerCase()}`;
    }
    return { title, description };
  }, [chatTitle, chatsTotalCount, isDeletingAllChats]);

  return (
    <div className="p-6">
      <div className="text-on-background-main flex flex-col items-center pb-[48px] pl-[24px] pr-[25px] pt-[24px]">
        <div className="pb-8">
          <IconDeleteModal />
        </div>
        <h3 className="mb-[8px] text-center text-[23px] font-extrabold">{title}</h3>
        <p className="text-md text-center">
          Click on Delete to <strong>permanently</strong> remove <strong>{description}</strong> from
          your history. After confirmation, it will not be possible to recover the deleted chats.
        </p>
      </div>
      <div className="flex w-full justify-end pr-6">
        <ButtonIcon variant={ButtonVariant.DANGER} handleClick={onConfirmation}>
          Delete
        </ButtonIcon>
      </div>
    </div>
  );
};

export default DeleteAllConfirmation;
