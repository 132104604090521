import { getSdk } from '@/@generated/graphql';
import { logger } from '@unique/next-commons/logger';
import { clientContextValues, Service } from '@unique/next-commons/swr';

const log = logger.child({
  package: 'chat',
  namespace: 'helpers:checkSnowConfig',
});

export const checkSNowConfig = async () => {
  const sdkScope = getSdk(clientContextValues.clients[Service.NODE_CHAT]);

  try {
    const config = await sdkScope.GetServiceNowIntegrationData();
    return config.getServiceNowIntegrationData;
  } catch (error) {
    log.error(error);
    return null;
  }
};
