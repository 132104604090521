export const SESSION_KEY_HAS_ERROR_OCCURED = 'hasAnErrorOccured';
export const RECOVERABLE_OIDC_ERRORS = [
  'invalid_grant',
  'Failed to fetch',
  'Errors.User.RefreshToken.Invalid',
  'Errors.OIDCSession.RefreshTokenInvalid',
  'additional interaction is required',
  'IFrame timed out',
  'Network timed out',
  'No matching state found in storage',
];
