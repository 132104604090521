import { logger } from '@unique/next-commons/logger';
import { PcmRecorder } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/PCMRecorder';
import {
  IStreamChunk,
  Stream,
} from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common/Stream';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:chat-voice-input:voice-recorder',
});

export class PCMRecordingService {
  private audioContext: AudioContext | null = null;
  private pcmRecorder: PcmRecorder | null = null;
  // The output stream that will receive PCM audio chunks (ArrayBuffer).
  private outputStream: Stream<ArrayBuffer> | null = null;
  public onDataAvailable: (chunk: ArrayBuffer) => void;

  /**
   * Start recording using Microsoft PcmRecorder.
   * This method requests microphone access, creates an AudioContext,
   * instantiates a PcmRecorder, and starts writing PCM data to the output stream.
   */
  public async startRecording(mediaStream: MediaStream): Promise<boolean> {
    try {
      // Create an AudioContext.
      this.audioContext = new AudioContext();

      // Instantiate PcmRecorder (true indicates input is stopped on release).
      this.pcmRecorder = new PcmRecorder(true);

      // Create an output stream that will receive ArrayBuffer PCM chunks.
      this.outputStream = new Stream<ArrayBuffer>('audio-stream');

      // Start recording: PcmRecorder writes PCM data into outputStream.
      this.pcmRecorder.record(this.audioContext, mediaStream, this.outputStream);

      // Begin processing the output stream.
      this.processAudioStream(this.outputStream);
      return true;
    } catch (error) {
      log.error('Error starting audio record');
      return false;
    }
  }

  private async processAudioStream(stream: Stream<ArrayBuffer>): Promise<void> {
    try {
      while (!stream.isReadEnded) {
        const chunk: IStreamChunk<ArrayBuffer> = await stream.read();
        if (chunk.isEnd) {
          break;
        }

        this.onDataAvailable?.(chunk.buffer);
      }
    } catch (error) {
      log.error('Error processing audio stream:');
    }
  }

  /**
   * Stop recording and release media resources.
   * This calls the PcmRecorder's release method and signals the stream to end.
   */
  public stopRecording(): void {
    if (this.pcmRecorder && this.audioContext) {
      this.pcmRecorder.releaseMediaResources(this.audioContext);
    }

    if (this.outputStream) {
      // Signal that no further data will be written to the stream.
      this.outputStream.readEnded();
    }
  }
}
