import { ButtonIcon } from '@unique/component-library';
import { IconCamera } from '@unique/icons';
import cn from 'classnames';
import { FC, useContext, useRef } from 'react';
import { ChatContext } from './ChatProvider';

interface TakePhotoCtaProps {
  showIcon?: boolean;
}

export const TakePhotoCta: FC<TakePhotoCtaProps> = ({ showIcon }) => {
  const cameraInputRef = useRef<HTMLInputElement>(null);
  const { handleFileUpdate } = useContext(ChatContext);

  const handleCameraCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;
    handleFileUpdate(files);
  };

  const handleCameraClick = () => {
    cameraInputRef.current?.click();
  };

  return (
    <>
      <ButtonIcon
        className={cn({
          'h-[50px] w-auto': showIcon,
        })}
        icon={showIcon ? <IconCamera /> : null}
        onClick={handleCameraClick}
      >
        {showIcon ? null : 'Take Photo'}
      </ButtonIcon>
      <input
        type="file"
        id="camera-capture"
        ref={cameraInputRef}
        accept="image/*"
        capture="environment"
        className="hidden"
        onChange={handleCameraCapture}
      />
    </>
  );
};
