'use client';
import { UserPrompt } from '@unique/shared-library/@generated/graphql';
import {
  UserPromptCreateManyInput,
  UserPromptCreateManyMutation,
  UserPromptCreateWithoutAssistantInput,
} from '@/@generated/graphql';
import {
  createManyUserPromptsMutation,
  deleteAllUserOwnedPromptsMutation,
  getUseAssistantsQueryKey,
  useAssistantQuery,
} from '@/lib/swr/hooks';
import {
  ButtonIcon,
  ButtonType,
  ButtonVariant,
  PromptsFormData,
  SuggestedPrompts,
  showUnsavedChangesWarning,
  useOutsideClick,
} from '@unique/component-library';
import { IconBook, IconClose } from '@unique/icons';
import { logger } from '@unique/next-commons/logger';
import { LayoutContext, ToastVariant, useToast } from '@unique/shared-library';
import cloneDeep from 'lodash/cloneDeep';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';

const promptsConfigurationLogger = logger.child({ package: 'promptsConfiguration' });

type PromptsConfigurationsProps = {
  handleCloseConfigurations: () => void;
  assistantId?: string;
};

export const PromptsConfigurations: FC<PromptsConfigurationsProps> = ({
  handleCloseConfigurations,
  assistantId,
}: PromptsConfigurationsProps) => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const [isDirtyCustom, setIsDirtyCustom] = useState(false);
  const { setSplitPaneContent } = useContext(LayoutContext);
  const { mutate: mutateAssistant, data } = useAssistantQuery(
    assistantId ? { assistantId: assistantId } : null,
  );
  const myPrompts =
    data?.assistantByUser?.userPrompts?.filter(
      (prompt: UserPrompt) => prompt.ownedBy === user.profile.sub,
    ) || [];
  const [userPrompts, setUserPrompts] = useState<Partial<UserPrompt>[]>(
    myPrompts.length > 0 ? cloneDeep(myPrompts) : [{ title: '', prompt: '' }],
  );

  const { trigger: deleteUserPrompts, isMutating: isDeletingUserPrompts } =
    deleteAllUserOwnedPromptsMutation(getUseAssistantsQueryKey());
  const { trigger: createUserPrompts, isMutating: isCreatingUserPrompts } =
    createManyUserPromptsMutation(getUseAssistantsQueryKey());
  const isUpdatingUserPrompts = isCreatingUserPrompts || isDeletingUserPrompts;

  const { handleSubmit, register, setValue, reset, formState } = useForm<PromptsFormData>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      userPrompts: [],
    },
    shouldUnregister: true,
  });

  const onCloseClick = () => {
    if (isDirtyCustom) {
      const shouldClose = confirm(
        'Warning: You have unsaved changes. Are you sure you want to exit?',
      );
      if (shouldClose) {
        handleCloseConfigurations();
      }
    } else {
      handleCloseConfigurations();
    }
  };

  const formRef = useRef(null);
  useOutsideClick(formRef, (event: MouseEvent) => showUnsavedChangesWarning(event, isDirtyCustom));

  useEffect(() => {
    setValue('userPrompts', userPrompts, {
      shouldValidate: true,
      shouldDirty: isDirtyCustom,
    });
  }, [userPrompts, setValue, isDirtyCustom]);

  const discardChanges = () => {
    reset(undefined, { keepValues: false, keepDirty: false });
    setIsDirtyCustom(false);
    setUserPrompts(cloneDeep(myPrompts));
    setSplitPaneContent(null);
    showToast({
      message: 'Changes to My Prompts discarded',
      variant: ToastVariant.SUCCESS,
      duration: 6000,
    });
  };

  const handleUpdateSuggestedPrompts = (userPrompt: Partial<UserPrompt>, id: number) => {
    setUserPrompts((prev) => {
      const newPrompts = [...prev];
      newPrompts[id] = userPrompt;
      return newPrompts;
    });
    setIsDirtyCustom(true);
  };

  const handleAddSuggestedPrompt = () => {
    setUserPrompts([
      ...(userPrompts || []),
      {
        title: '',
        prompt: '',
        ownedBy: user.profile.sub,
      },
    ]);
    setIsDirtyCustom(true);
  };

  const handleRemoveSuggestedPrompt = (indexToRemove: number) => {
    const oldUserPrompts = [...userPrompts];
    const promptToRemove = oldUserPrompts[indexToRemove];
    setUserPrompts((prev) => {
      const newPrompts = [...prev];
      newPrompts.splice(indexToRemove, 1);
      return newPrompts;
    });
    setIsDirtyCustom(true);
    if (!promptToRemove?.title) return;

    showToast({
      message: `${promptToRemove.title} removed`,
      variant: ToastVariant.SUCCESS,
      duration: 6000,
      toastAction: {
        label: 'Undo',
        handleClick: () => {
          setUserPrompts(oldUserPrompts);
          showToast({
            message: `${promptToRemove.title} restored`,
            variant: ToastVariant.SUCCESS,
          });
        },
      },
    });
  };

  const toUserPromptCreateWithoutAssistantInput = useCallback(
    (userPrompt: Partial<UserPrompt>): UserPromptCreateWithoutAssistantInput => ({
      title: userPrompt.title,
      prompt: userPrompt.prompt,
      ownedBy: user.profile.sub,
    }),
    [user.profile.sub],
  );

  const getCreateUserPromptsData = useCallback(
    (formData: PromptsFormData): UserPromptCreateManyInput[] => {
      const userPromptsToCreate = formData?.userPrompts.map((userPrompt) => {
        return {
          assistantId,
          prompt: userPrompt.prompt,
          title: userPrompt.title,
        };
      });

      if (userPromptsToCreate.length === 0) return null;

      return userPromptsToCreate;
    },
    [userPrompts, toUserPromptCreateWithoutAssistantInput],
  );

  const onUpdateSuccess = (data?: UserPromptCreateManyMutation) => {
    showToast({
      message: 'My Prompts updated',
      variant: ToastVariant.SUCCESS,
      duration: 6000,
    });
    mutateAssistant();
    setUserPrompts(data ? data.userPromptCreateMany : []);
    reset([data ? data.userPromptCreateMany : []]);
    setIsDirtyCustom(false);
    setSplitPaneContent(null);
  };

  const udpateUserPrompts = useCallback(
    async (formData: PromptsFormData) => {
      const inputCreate = getCreateUserPromptsData(formData);
      let isErrored = false;

      await deleteUserPrompts(
        { assistantId },
        {
          revalidate: true,
          throwOnError: false,
          onSuccess: async () => {
            // Do not refresh data and do not notify the user if there are user prompts to create
            if (!inputCreate) {
              onUpdateSuccess();
            }
          },
          onError: (error) => {
            isErrored = true;
            promptsConfigurationLogger.error(error);
            showToast({
              message: 'Could not update prompts',
              variant: ToastVariant.ERROR,
              duration: 6000,
            });
          },
        },
      );

      inputCreate &&
        !isErrored &&
        (await createUserPrompts(
          { assistantId, areOwnedByUser: true, data: inputCreate },
          {
            revalidate: true,
            throwOnError: false,
            onSuccess: (data: UserPromptCreateManyMutation) => {
              onUpdateSuccess(data);
            },
            onError: (error) => {
              promptsConfigurationLogger.error(error);
              showToast({
                message: 'Could not update prompts',
                variant: ToastVariant.ERROR,
                duration: 6000,
              });
            },
          },
        ));
    },
    [
      createManyUserPromptsMutation,
      deleteAllUserOwnedPromptsMutation,
      assistantId,
      showToast,
      reset,
    ],
  );

  return (
    <form
      ref={formRef}
      className="bg-background fixed left-0 top-0 z-[60] flex h-full w-full flex-col overflow-y-scroll p-6 md:relative"
    >
      <div className="text-on-surface mb-10 flex w-full justify-between">
        <div className="title-s">Configurations</div>
        <div className="text-on-background-main cursor-pointer" onClick={onCloseClick}>
          <IconClose height="24" width="24" />
        </div>
      </div>
      <SuggestedPrompts
        suggestedPrompts={userPrompts}
        title="My Prompts"
        addButtonIcon={<IconBook />}
        handleAddSuggestedPrompt={handleAddSuggestedPrompt}
        handleRemoveSuggestedPrompt={handleRemoveSuggestedPrompt}
        handleUpdateSuggestedPrompt={handleUpdateSuggestedPrompts}
        register={register}
      />
      <div className="row ml-auto flex">
        <ButtonIcon
          onClick={discardChanges}
          disabled={!isDirtyCustom}
          className="mr-4"
          variant={ButtonVariant.SECONDARY}
        >
          Discard
        </ButtonIcon>
        <ButtonIcon
          type={ButtonType.SUBMIT}
          onClick={handleSubmit(udpateUserPrompts)}
          disabled={!isDirtyCustom || !formState.isValid}
          isLoading={isUpdatingUserPrompts}
        >
          Save
        </ButtonIcon>
      </div>
    </form>
  );
};
