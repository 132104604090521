import { getSdk, SortOrder } from '@/@generated/graphql';
import { getExtendedChatWhereInput } from '@/helpers/getChatHistoryWhereInput';
import { DateFilterOptions } from '@unique/component-library';
import { clientContextValues, Service } from '@unique/next-commons/swr';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum ChatHistoryState {
  Loading = 'loading',
  Error = 'error',
  Ready = 'ready',
  InitialLoad = 'initialLoad',
}

export interface ChatHistoryFilter {
  assistantId?: string;
  lastMessageTimestamp?: DateFilterOptions;
  searchTerm?: string;
}

const fetchChatHistory = async ({
  pageSize,
  offset,
  filters,
}: {
  pageSize: number;
  offset: number;
  filters?: ChatHistoryFilter;
}) => {
  try {
    const sdkChat = getSdk(clientContextValues.clients[Service.NODE_CHAT]);
    const chats = await sdkChat.Chats({
      skip: offset,
      where: getExtendedChatWhereInput(filters),
      take: pageSize,
      orderBy: [{ createdAt: SortOrder.Desc }],
      includeAssistantName: true,
      includeLastMessage: true,
    });
    return chats.chats;
  } catch (error) {
    throw new Error(error);
  }
};

export const useChatHistory = (pageSize: number) => {
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState<string>('');
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const chatHistoryState = useMemo(() => {
    if (initialLoad) return ChatHistoryState.InitialLoad;
    if (isLoading) return ChatHistoryState.Loading;
    if (error) return ChatHistoryState.Error;
    return ChatHistoryState.Ready;
  }, [initialLoad, error, canLoadMore, isLoading]);

  const fetch = async ({
    filters = {},
    resetOffset,
    shouldUpdateSearchParams,
  }: {
    filters?: ChatHistoryFilter;
    resetOffset: boolean;
    shouldUpdateSearchParams: boolean;
  }) => {
    if (resetOffset) setOffset(0);

    // This is the case when user manually applies a filter
    if (shouldUpdateSearchParams) {
      setSearchParams(
        Object.fromEntries(
          Object.entries(filters)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => [key, String(value)]),
        ),
      );
    }

    // Use existing search params if no filters provided
    if (!Object.keys(filters).length && searchParams.size > 0 && !shouldUpdateSearchParams) {
      filters = Object.fromEntries(searchParams.entries());
    }

    setIsLoading(true);
    try {
      const results = await fetchChatHistory({
        pageSize,
        filters,
        offset: resetOffset ? 0 : offset,
      });
      const isLastPage = results.length < pageSize;
      setCanLoadMore(!isLastPage);
      if (!isLastPage) {
        setOffset(resetOffset ? pageSize : offset + pageSize);
      }
      return { chats: results, filters: filters ?? {} };
    } catch (error) {
      setError(error.message);
      return { chats: [], filters: filters ?? {} };
    } finally {
      setInitialLoad(false);
      setIsLoading(false);
    }
  };

  return {
    canLoadMore,
    fetch,
    chatHistoryState,
  };
};
