import { ButtonIcon, ButtonSize, ButtonVariant } from '@unique/component-library';
import { IconAlert, IconClose, IconPause, IconPlay } from '@unique/icons';
import { memo, useEffect, useMemo, useState } from 'react';
import { useSpeechToText } from './hooks/useSpeechToText';
import { VoiceVisualizer } from './components/VoiceVisualizer/VoiceVisualizer';
import { LanguageSelector } from './components/LanguageSelector';
import { barHistoryAnimation } from './components/VoiceVisualizer/animations/bar-history-animation';
import { logger } from '@unique/next-commons/logger';
import { useScreens } from '@unique/shared-library';
import { LS_VOICE_INPUT_USER_LANG } from './components/types';
import { UniqueSpeechToTextClient } from './services/speech-to-text/unique.adapter';
import { useAuth } from 'react-oidc-context';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:inputs.VoiceInput',
});

interface VoiceInputProps {
  speechBackendUrl: string;
  grammarList?: string[];
  onClose?: () => void;
  onChange?: (transcript: string) => void;
  onChangeInterim?: (interimTranscript: string) => void;
  onStatusChange?: (status: VoiceInputStatus) => void;
}

const enum VoiceInputStatus {
  Initializing = 'initializing',
  Listening = 'listening',
  Stopped = 'stopped',
}

export const VoiceInput = memo(
  ({ speechBackendUrl, onChange, onChangeInterim, onClose }: VoiceInputProps) => {
    const { isMobile } = useScreens();
    const auth = useAuth();
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedlanguage, setSelectedlanguage] = useState<string | undefined>(() => {
      const lang = localStorage.getItem(LS_VOICE_INPUT_USER_LANG);
      return lang;
    });

    const speechToTextProvider = useMemo(() => {
      return stream && speechBackendUrl && auth?.user?.access_token
        ? new UniqueSpeechToTextClient(speechBackendUrl, stream, auth.user.access_token)
        : null;
    }, [speechBackendUrl, stream, auth?.user?.access_token]);
    const {
      status,
      startListening,
      availableLanguages,
      stopListening,
      isListening,
      transcript,
      interimTranscript,
      clean,
      error,
    } = useSpeechToText(speechToTextProvider);
    const canStartSpeechToText = stream && speechBackendUrl && auth?.user?.access_token;

    const actionButtonLabel = useMemo(() => {
      if (!isListening && !error) {
        return 'Start';
      }

      if (error && !isListening) {
        return 'Retry';
      }

      return 'Stop';
    }, [isListening, error, status]);

    useEffect(() => {
      handleInitStream();
      return () => {
        clean();
      };
    }, []);

    // If there is a stream start automatically
    useEffect(() => {
      if (!canStartSpeechToText) {
        return;
      }

      handleStartStop();
    }, [canStartSpeechToText]);

    // Trigger callback when transcript is updated
    useEffect(() => {
      if (!transcript) {
        return;
      }
      onChange?.(transcript);
    }, [transcript]);

    // Trigger callback when interim transcript is updated
    useEffect(() => {
      if (!interimTranscript) {
        return;
      }
      onChangeInterim?.(interimTranscript);
    }, [interimTranscript]);

    const handleStopListening = async () => {
      await stopListening();
      onClose?.();
    };

    const handleInitStream = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        if (!mediaStream) {
          throw new Error('No media stream returned');
        }

        setStream(mediaStream);
      } catch (err) {
        log.error('Error getting microphone permissions');
      }
    };

    const handleLanguageChange = (lang: string) => {
      localStorage.setItem(LS_VOICE_INPUT_USER_LANG, lang);
      setSelectedlanguage(lang);
    };

    const handleClose = () => {
      setIsLoading(true);
      clean();
      onClose?.();
    };

    const handleStartStop = async () => {
      if (!isListening) {
        await startListening(selectedlanguage);
        return;
      }

      setIsLoading(true);
      await handleStopListening();
    };

    return (
      <div className="voice-input-container bg-primary-cta text-on-background-main h-full w-full rounded-md p-3">
        <div className="flex h-10 w-full items-center justify-between gap-3">
          <div className="inline">
            <LanguageSelector
              selectedLanguage={selectedlanguage ?? availableLanguages?.[0]}
              languages={availableLanguages}
              onChange={handleLanguageChange}
            />
          </div>

          {!!error && (
            <div className="text-on-background-dimmed w-full">
              <ButtonIcon
                variant={ButtonVariant.DANGER}
                buttonSize={ButtonSize.SMALL}
                icon={<IconAlert />}
              >
                {error}
              </ButtonIcon>
            </div>
          )}

          {!isListening && !error && (
            <div className="align-center text-on-control-dimmed w-full text-center">
              Choose a language and press start
            </div>
          )}
          {!error && stream && isListening && (
            <VoiceVisualizer
              animate={barHistoryAnimation({
                barsLimit: isMobile ? 30 : 100,
              })}
              isListening={isListening}
              stream={stream}
            />
          )}

          {error && (
            <ButtonIcon
              isLoading={isLoading}
              onClick={handleClose}
              buttonSize={ButtonSize.SMALL}
              variant={ButtonVariant.SECONDARY}
              icon={<IconClose />}
            />
          )}

          <ButtonIcon
            variant={ButtonVariant.SECONDARY}
            icon={
              isListening ? (
                <IconPause height="16" width="16" />
              ) : (
                <IconPlay height="16" width="16" />
              )
            }
            isLoading={isLoading}
            buttonSize={ButtonSize.SMALL}
            onClick={handleStartStop}
          >
            {actionButtonLabel}
          </ButtonIcon>
        </div>
      </div>
    );
  },
);
