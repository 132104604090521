import getCurrencySymbol from './get-currency-symbol';

export function getCurrencyFormatted(value: number, currency: string) {

    let displayValue = value.toString();
    // If currency we cut large number: 1T, 1B, and 1M
    if (value >= 1_000_000_000_000) {
        displayValue = `${(value / 1_000_000_000_000).toFixed(2)}T`;
    } else if (value >= 1_000_000_000) {
        displayValue = `${(value / 1_000_000_000).toFixed(2)}B`;
    } else if (value >= 1_000_000) {
        displayValue = `${(value / 1_000_000).toFixed(2)}M`;
    }
    // using native API here, could be custom but good first version
    return `${getCurrencySymbol(navigator.language, currency)} ${displayValue}`;
}
