import { Assignment } from '@unique/shared-library/hooks/types';

export function hasAllRequiredAssignments(
  userAssignments: Assignment[],
  requiredAssignments: Assignment[],
): boolean {
  return requiredAssignments.every((requiredRole) => userAssignments.includes(requiredRole));
}

export function hasAnyRequiredAssignments(
  userAssignments: Assignment[],
  requiredAssignments: Assignment[],
): boolean {
  return requiredAssignments.some((requiredRole) => userAssignments.includes(requiredRole));
}
