import { useState, useEffect, useCallback } from 'react';
import { SpeechToTextProvider } from '../services/speech-to-text/types';

export enum SpeechToTextStatus {
  Initializing = 'initializing',
  Ready = 'ready',
  Listening = 'listening',
  Error = 'error',
  Stopped = 'stopped',
}

export function useSpeechToText(provider: SpeechToTextProvider) {
  const [status, setStatus] = useState(SpeechToTextStatus.Initializing);
  const [availableLanguages, setAvailableLanguages] = useState<string[]>();
  const [interimTranscript, setInterimTranscript] = useState<string>('');
  const [transcript, setTranscript] = useState<string>('');
  const [isListening, setIsListening] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClean = () => {
    setTranscript('');
    setIsListening(false);
    setAvailableLanguages([]);
    setInterimTranscript('');
    setError(null);
    setStatus(SpeechToTextStatus.Stopped);
    provider?.clean();
  };

  const startListening = useCallback(
    async (language?: string) => {
      try {
        const hasStarted = await provider.startRecognition(language);
        if (!hasStarted) {
          throw new Error('An error occurrd while starting recognition');
        }

        setIsListening(true);
        setStatus(SpeechToTextStatus.Listening);
      } catch (err) {
        setError('An error occurred while starting recognition.');
        setIsListening(false);
      }
    },
    [provider],
  );

  const handleStopListening = useCallback(async () => {
    if (status === SpeechToTextStatus.Stopped) {
      return;
    }

    try {
      await provider.stopRecognition();
      setIsListening(false);
      setStatus(SpeechToTextStatus.Stopped);
    } catch (err) {
      setError('An error occurred while stopping recognition.');
    }
  }, [provider, status, isListening]);

  useEffect(() => {
    if (!error) {
      return;
    }

    setStatus(SpeechToTextStatus.Error);
  }, [error]);

  useEffect(() => {
    if (!provider) {
      return;
    }

    setAvailableLanguages(provider.availableLanguages);

    provider.onTranscript = (newTranscript: string) => {
      setTranscript((prevTranscript) => `${prevTranscript} ${newTranscript}`);
    };

    provider.onInterimTranscript = (newinterimtranscript: string) => {
      setInterimTranscript(newinterimtranscript);
    };

    provider.onError = (err: string) => {
      setError(err);
    };

    provider.onComplete = () => {
      handleStopListening();
    };

    provider.onReady = () => {
      setStatus(SpeechToTextStatus.Ready);
    };

    provider.initialize();

    // cleanup on unmount
    return () => {
      (async () => {
        await handleStopListening();
        handleClean();
      })();
    };
  }, [provider]);

  return {
    transcript,
    status,
    interimTranscript,
    isListening,
    startListening,
    stopListening: handleStopListening,
    error,
    availableLanguages,
    clean: handleClean,
  };
}
