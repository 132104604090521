import {
  AssessmentRating,
  getAssessmentBadgeLabel,
  mapEvaluationToAssessmentBadgeVariant,
} from '@/helpers/messageAssessment';
import { Message } from '@/lib/swr/types';
import { sortBy } from 'lodash';
import { FC, ReactNode, useMemo } from 'react';
import { ChatAccordion } from '../ChatAccordion';
import { AssessmentBadge } from './AssessmentBadge';
import { AssessmentSentiment } from './AssessmentSentiment';

type MessageAssessmentProps = {
  message: Message;
  additionalComponents?: ReactNode;
};

const MessageAssessment: FC<MessageAssessmentProps> = ({ message, additionalComponents }) => {
  const assessments = message.assessment ?? [];

  const evaluationRating = useMemo(() => {
    // list of ratings containing POSITIVE, NEGATIVE, VERIFIED, UNVERIFIED
    const ratings = assessments.map((assessment) => assessment.label);

    if (
      ratings.some((rating: (typeof AssessmentRating)[keyof typeof AssessmentRating]) =>
        [AssessmentRating.NEGATIVE, AssessmentRating.RED].includes(rating),
      )
    ) {
      return AssessmentRating.RED;
    }

    if (
      ratings.some((rating: (typeof AssessmentRating)[keyof typeof AssessmentRating]) =>
        [AssessmentRating.UNVERIFIED, AssessmentRating.YELLOW].includes(rating),
      )
    ) {
      return AssessmentRating.YELLOW;
    }

    const unknownRatings = ratings.filter(
      (rating) =>
        !Object.values(AssessmentRating)
          .map((r) => r.toString())
          .includes(rating),
    );
    if (unknownRatings.length > 0) {
      return AssessmentRating.UNKNOWN;
    }

    // Note: Positive, verified and green are treated the same
    return AssessmentRating.GREEN;
  }, [assessments]);

  const visibleAssessments = useMemo(
    () =>
      sortBy(
        assessments.filter((assessment) => assessment.isVisible),
        ['type'],
      ),
    [assessments],
  );

  if (assessments.length === 0) {
    return null;
  }

  return (
    <ChatAccordion
      icon={<AssessmentSentiment assessments={assessments} evaluationRating={evaluationRating} />}
      label="AI answer assessment"
      additionalComponents={additionalComponents}
    >
      {visibleAssessments.map((assessment) => (
        <div className="mt-6" key={assessment.id}>
          <AssessmentBadge
            variant={mapEvaluationToAssessmentBadgeVariant(assessment)}
            label={getAssessmentBadgeLabel(assessment)}
          />
          <div className="body-2 text-on-background-main mt-2">{assessment.explanation}</div>
        </div>
      ))}
    </ChatAccordion>
  );
};

export default MessageAssessment;
