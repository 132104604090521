import React, { useEffect } from 'react';
import { IconWarning } from '@unique/icons';
import { logger } from '@unique/next-commons/logger';

interface ErrorMessageProps {
    error: Error;
}

const log = logger.child({
    package: 'chat',
    namespace: 'components:Chat:AdvancedFormatting:ErrorMessage',
});

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
    
    useEffect(() => {
        log.error(error.message);
    }, [error.message]);

    return (
        <div className="flex items-start gap-2 rounded-lg bg-error-light p-4 text-on-error-dark mb-4">
            <IconWarning className="mt-0.5 h-5 w-5 shrink-0" />
            <div className="text-sm">
                <p className="font-medium">Failed to load visualization</p>
            </div>
        </div>
    );
}; 