import { SortOrder } from '@/@generated/graphql';
import { useChatDelete } from '@/hooks/useChatDelete';
import { ChatHistoryFilter, useChatHistory } from '@/hooks/useChatHistory';
import { useChatsQuery } from '@/lib/swr/hooks';
import { Chat } from '@/lib/swr/types';
import { FeatureFlagContext } from '@unique/shared-library';
import { uniqBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ChatHistoryList } from './ChatHistoryList';
import { ChatHistoryContext } from './context';

const PAGE_SIZE_CHAT_HISTORY = 30;

export const ChatHistoryPage = () => {
  const [visibleChats, setVisibleChats] = useState<Chat[]>([]);
  const [chatHistoryFilters, setChatHistoryFilters] = useState<ChatHistoryFilter>({});
  const { fetch, chatHistoryState, canLoadMore } = useChatHistory(PAGE_SIZE_CHAT_HISTORY);
  const { deleteChatIds, deleteAllChats } = useChatDelete();
  const { flags } = useContext(FeatureFlagContext);
  const { mutate: fetchChatsInMenu } = useChatsQuery({
    orderBy: [{ createdAt: SortOrder.Desc }],
    take: flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803 ? 5 : 50,
  });

  useEffect(() => {
    const fetchChatsInitial = async () => {
      const { chats, filters } = await fetch({
        resetOffset: true,
        shouldUpdateSearchParams: false,
      });
      setVisibleChats(chats);
      setChatHistoryFilters(filters);
    };
    fetchChatsInitial();
  }, []);

  const handleFiltersChange = async (filters: ChatHistoryFilter) => {
    const { chats } = await fetch({ filters, resetOffset: true, shouldUpdateSearchParams: true });
    setChatHistoryFilters(filters);
    setVisibleChats(chats);
  };

  const loadMore = async () => {
    if (!canLoadMore) return;
    const { chats } = await fetch({
      filters: chatHistoryFilters,
      resetOffset: false,
      shouldUpdateSearchParams: false,
    });
    const uniqueChats = uniqBy([...visibleChats, ...chats], 'id');
    setVisibleChats(uniqueChats);
  };

  const onDeleteChats = async ({
    chatIds,
    shouldDeleteAll = false,
  }: {
    chatIds?: string[];
    shouldDeleteAll?: boolean;
  }) => {
    if (shouldDeleteAll) {
      deleteAllChats({ mutate: fetchChatsInMenu, onSuccess: () => setVisibleChats([]) });
      return;
    }
    deleteChatIds(chatIds, {
      mutate: fetchChatsInMenu,
      onSuccess: () => setVisibleChats(visibleChats.filter((chat) => !chatIds.includes(chat.id))),
    });
  };

  const chatHistoryContext = useMemo(() => {
    return {
      chatsInHistory: visibleChats,
      onDeleteChats: onDeleteChats,
      onFilterUpdate: handleFiltersChange,
      loadMore,
      chatHistoryState,
      canLoadMore,
      chatHistoryFilters,
    };
  }, [visibleChats, chatHistoryState]);

  return (
    <ChatHistoryContext.Provider value={chatHistoryContext}>
      <ChatHistoryList />
    </ChatHistoryContext.Provider>
  );
};
