import { logger } from '@unique/next-commons/logger';
import { messagesSlice } from '../messagesSlice';
import { getSdk, SortOrder } from '@/@generated/graphql';
import { clientContextValues, Service } from '@unique/next-commons/swr';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:queryPaginatedMessages',
});

/**
 *  Send an async graphQL message query and dispatch the result to the redux store.
 */
export const queryPaginatedMessages = (chatId, take, skip, includeDebugInfo) => {
  return async (dispatch) => {
    const { upsertMessages, setPagination } = messagesSlice.actions;
    try {
      const sdkScope = getSdk(clientContextValues.clients[Service.NODE_CHAT]);
      log.info(`query PaginatedMessage: ${chatId}, ${take}, ${skip}`);

      const paginatedMessage = await sdkScope.PaginatedMessage({
        chatId,
        take,
        skip,
        orderBy: [{ createdAt: SortOrder.Desc }],
        includeDebugInfo,
      });
      // Save totalCount for pagination to handle infinite scroll
      if (paginatedMessage?.paginatedMessage?.totalCount) {
        dispatch(
          setPagination({ chatId, totalCount: paginatedMessage?.paginatedMessage?.totalCount }),
        );
      }
      return dispatch(upsertMessages(paginatedMessage?.paginatedMessage?.nodes));
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};
