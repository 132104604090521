import { AssistantQuery, SortOrder } from '@/@generated/graphql';
import { SpaceInfo } from '../Space/SpaceInfo';
import { ButtonIcon, InputField, ScrollPagination } from '@unique/component-library';
import { IconCreateSpace, IconFileAdd } from '@unique/icons';
import { useDueDiligenceQuery } from '@/lib/swr/hooks';
import DueDiligenceList from './DueDiligenceList';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '@unique/shared-library';
import { SpaceHeader } from '../Space/SpaceHeader';
import { useNavigate } from 'react-router-dom';
import { queryByAssistantId } from './utils/queryByAssistantId';
import { setDueDiligenceListResult, useAppDispatch, useAppSelector } from '@/store';

const numItems = 10;

const dueDiligenceQueryVariables = {
  take: numItems,
  skip: 0,
  orderBy: { createdAt: SortOrder.Desc },
};

export default function DueDiligenceHome({
  assistant,
}: {
  assistant: AssistantQuery['assistantByUser'];
}) {
  const { setHeaderItems } = useContext(LayoutContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageData, setPageData] = useState(dueDiligenceQueryVariables);

  const dueDiligenceListResult = useAppSelector(
    (state) => state.dueDiligence.dueDiligenceListResult,
  );

  const { data: dueDiligenceList, isLoading: loadingDueDiligenceList } = useDueDiligenceQuery(
    {
      where: queryByAssistantId(assistant?.id),
      ...pageData,
    },
    {
      keepPreviousData: true,
      onSuccess: ({ dueDiligence }) => {
        if (
          pageData.take > dueDiligenceListResult.take &&
          dueDiligence.length === dueDiligenceListResult.resultCount
        ) {
          // All data has been fetched.
          dispatch(
            setDueDiligenceListResult({
              resultCount: dueDiligence.length,
              hasMore: false,
              take: pageData.take,
            }),
          );
          return;
        }
        dispatch(
          setDueDiligenceListResult({
            hasMore: true,
            resultCount: dueDiligence.length,
            take: pageData.take,
          }),
        );
      },
    },
  );

  useEffect(() => {
    setHeaderItems([<SpaceHeader key={`space-header-${assistant}`} title={assistant?.name} />]);
    return () => {
      setHeaderItems([]);
    };
  }, [assistant?.name]);

  const fetchMore = () => {
    if (!dueDiligenceListResult.hasMore) return;
    setPageData((prev) => ({ ...prev, take: prev.take + numItems }));
  };

  const handleClickCreateSpace = () => {
    navigate(`/space/${assistant?.id}/create-worksheet?step=import-questions`);
  };

  return (
    <div className="flex w-full flex-col">
      {assistant?.explanation || assistant?.alert ? (
        <div className="bg-background flex items-center justify-center py-5">
          <div className="mx-auto max-w-[928px] px-3 sm:px-0">
            <SpaceInfo
              explanation={assistant?.explanation}
              alert={assistant?.alert}
              bg="bg-surface"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="bg-surface mx-auto mt-5 h-full w-full max-w-[928px] px-3 sm:px-0">
        {dueDiligenceList?.dueDiligence?.length ? (
          <div className="w-full">
            <div className="flex w-full items-center justify-between gap-3 px-0 sm:px-2">
              {/* TODO: To be displayed when we have the API from the backend. Ticket:  https://unique-ch.atlassian.net/browse/UN-9392 */}
              <InputField name="search" className="hidden" placeholder="Search sheet" type="text" />
              <ButtonIcon
                onClick={handleClickCreateSpace}
                icon={<IconFileAdd />}
                className="bg-primary-cta text-nowrap"
              >
                New Sheet
              </ButtonIcon>
            </div>
            <div className="mt-10">
              <DueDiligenceList
                dueDiligence={dueDiligenceList?.dueDiligence}
                isLoading={loadingDueDiligenceList}
              />
              {dueDiligenceListResult.hasMore &&
                dueDiligenceList?.dueDiligence?.length >= numItems && (
                  <ScrollPagination
                    onClick={() => {
                      !loadingDueDiligenceList && fetchMore();
                    }}
                    isLoading={loadingDueDiligenceList}
                    enableInfiniteScroll
                  />
                )}
            </div>
          </div>
        ) : (
          <div className="mt-10 flex h-full w-full flex-col items-center justify-center gap-y-5 sm:mt-20">
            <IconCreateSpace />
            <div className="body-2 text-on-background-dimmed text-center">
              <p>All your sheets will be shown here.</p>
              <p>Get started now.</p>
            </div>
            <ButtonIcon icon={<IconFileAdd />} onClick={handleClickCreateSpace}>
              Create New Sheet
            </ButtonIcon>
          </div>
        )}
      </div>
    </div>
  );
}
