import { BenchmarkStatusResponse, getStatusInfo } from '@/helpers/benchmarking';
import { Spinner } from '@unique/component-library';
import Badge from '@unique/component-library/src/Badge';
import { IconAlert, IconExcelFile } from '@unique/icons';
import { FC } from 'react';

type BenchmarkingResultProps = BenchmarkStatusResponse & {
  isDownloading: boolean;
  handleDownload: () => void;
  handleAbort: () => void;
};

const BenchmarkingResult: FC<BenchmarkingResultProps> = ({
  status,
  error,
  done,
  total,
  filename,
  isDownloading,
  handleDownload,
  handleAbort,
}) => {
  if (!filename) return null;
  const statusInfo = getStatusInfo({ error, done, total, filename, status });
  return (
    <div className="mt-10">
      <h3 className="title-s text-on-background-main mb-4 text-left">Benchmarking Result</h3>
      <div className="border-control flex h-16 items-center gap-x-5 border-y px-5">
        <IconExcelFile width="24px" height="24px" />
        <div className="body-2 text-on-surface">{filename}</div>
        <Badge
          variant={statusInfo.badgeVariant}
        >
          {statusInfo.text.toUpperCase()}
        </Badge>
        {statusInfo.text.includes('In Progress') && (
          <div
            className="bg-error-light ml-auto flex !h-[24px] flex-shrink-0 items-center rounded-[8px] p-1 !leading-[24px] tracking-widest"
            onClick={handleAbort}
          >
            <span className="text-on-surface contents cursor-pointer">
              <IconAlert width="17px" height="17px" className="mr-2" /> Abort
            </span>
          </div>
        )}
        {statusInfo.icon && (
          <div className="ml-auto flex items-center" onClick={handleDownload}>
            {isDownloading ? <Spinner size={18} /> : <>{statusInfo.icon}</>}
          </div>
        )}
      </div>
    </div>
  );
};

export default BenchmarkingResult;
