import {
  AssessmentRating,
  AssessmentStatus,
  mapEvaluationToIconSentimentVariant,
} from '@/helpers/messageAssessment';
import { MessageAssessment } from '@/lib/swr/types';
import { IconLoading, IconSentiment, IconSentimentVariant } from '@unique/icons';
import { FC } from 'react';

type AssessmentSentimentProps = {
  assessments: MessageAssessment[];
  evaluationRating: AssessmentRating;
};

export const AssessmentSentiment: FC<AssessmentSentimentProps> = ({
  assessments = [],
  evaluationRating,
}) => {
  const anyAssessmentsPending = assessments.some(
    (assessment) => assessment.status === AssessmentStatus.PENDING,
  );

  const anyAssessmentErrors = assessments.some(
    (assessment) => assessment.status === AssessmentStatus.ERROR,
  );

  if (anyAssessmentsPending) {
    return (
      <div className="flex w-4 items-center">
        <IconLoading width="15px" height="15px" />
      </div>
    );
  }

  if (anyAssessmentErrors) {
    return <IconSentiment variant={IconSentimentVariant.ERROR} />;
  }

  return <IconSentiment variant={mapEvaluationToIconSentimentVariant(evaluationRating)} />;
};
