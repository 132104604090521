import { IconBotError } from '@unique/icons';

export const ChatHistoryError = () => {
  return (
    <div className="flex h-[60vh] flex-col items-center justify-center">
      <IconBotError />
      <div className="body-2 text-on-background-dimmed py-3 text-center">
        <p>Could not load past chats</p>
      </div>
    </div>
  );
};
