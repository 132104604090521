'use client';

import { Content as ContentById, Role } from '@/@generated/graphql';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Modal,
  Spinner,
} from '@unique/component-library';
import { loadFile } from '@unique/next-commons/helpers';
import { ClientContext, Service } from '@unique/next-commons/swr';
import cn from 'classnames';
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { extractUniqueContentIdsForImages } from '@/helpers/extractUniqueContentIdsForImages';
import { removeSystemPrefixFromMessages } from '@/helpers/messages';
import { useMessageAssessmentSubscription } from '@/hooks/useMessageAssessmentSubscription';
import { useMessagesUpdateSubscription } from '@/lib/swr/hooks';
import { Assistant, Message } from '@/lib/swr/types';
import {
  chatSlice,
  clearMessages,
  queryPaginatedMessages,
  setChatImageUrls,
  upsertMessages,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import { useRoles } from '@unique/next-commons/authorization';
import { logger } from '@unique/next-commons/logger';
import { LayoutContext, ScrollWrapperContext } from '@unique/shared-library';
import { GraphQLError } from 'graphql';
import { uniq } from 'lodash';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import ContentList from './ContentList';
import MessageItem from './MessageItem';
import useChatModals from '@/hooks/useChatModals';
import useFileLoader from '@/hooks/useFileLoader';
import { Event as ClientWsEvent } from 'graphql-ws';

interface ChatMessagesProps {
  handleSelectPrompt: (prompt: string) => void;
  currentChatAssistant: Assistant;
  content: ContentById[] | null;
  handleMutateContent: () => void;
}

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:chat-messages',
});

const PAGE_SIZE = 20; // 20 messages per page
const UPDATE_THROTTLE_MS = 400; // 400ms

export const ChatMessages: FC<ChatMessagesProps> = ({
  handleSelectPrompt,
  currentChatAssistant,
  content,
  handleMutateContent,
}) => {
  const { id } = useParams<{ id: string }>();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const { services } = useContext(ClientContext);
  const chatImageUrls = useAppSelector((state) => state.chat.chatImageUrls) ?? {};
  const chatId = typeof id === 'string' ? id : '';
  const streams = useAppSelector((state) => state.chat.streams);
  const { allowDebugRead } = useRoles();
  const [size, setSize] = useState<number>(0);
  const [messagesError, setMessageError] = useState<string | null>(null);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [wsEvent, setWsEvent] = useState<ClientWsEvent | null>(null);
  // Store LoadingMessages state in ref to WS can access latest version
  const isLoadingMessagesRef = useRef<boolean>(isLoadingMessages);
  useEffect(() => {
    isLoadingMessagesRef.current = isLoadingMessages;
  }, [isLoadingMessages]);

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const messages: Message[] = useAppSelector((state) => state.messages.messages) ?? [];
  const totalCount: number = useAppSelector((state) => state.messages.pagination[chatId]) ?? 0;
  const [messagesOpacity, setMessagesOpacity] = useState<number>(0);

  const { handleModalClose, onSavePromptClick, onThumbsClick, showModal, modalContent } =
    useChatModals({
      currentChatAssistant,
    });

  const { setIsHeaderVisible } = useContext(LayoutContext);

  const { onFileClick, loadUploadedFile } = useFileLoader({ chatId });

  // Div reference to our message list to fetch last element and scroll To its top
  const messagesRef = useRef<HTMLDivElement>(null);

  const assessmentSubscriptionVariables = useMemo(
    () => (typeof id === 'string' ? { chatId: id } : null),
    [id],
  );

  useMessageAssessmentSubscription({
    subscriptionVariables: assessmentSubscriptionVariables,
  });

  // This is loading when user navigate to a new chat changing chatId from url
  // fetchMessages with skiip 0 will clear old messages from previous chat
  useEffect(() => {
    // Reset ws event to null to trigger new messages fetch when WSClient connected
    setWsEvent(null);
    setIsLoadingMessages(true);
    setMessageError(null);
    dispatch(clearMessages());
    setSize(0);
    setMessagesOpacity(0); // message opacity is set to 0 to avoid flickering when messages are loaded
    setIsHeaderVisible(true);
    // Clear stremaing cache if chatid change to avoid displaying streaming message in new chat.
    messageUpdateRef.current = {};
  }, [chatId, allowDebugRead]);

  // Trigger scroll to bottom AFTER messages are loaded
  // scrollToBotton will look at the height and so we need to wait for
  useEffect(() => {
    if (!isLoadingMessages) {
      // Instant scroll to bottom so we can see the last messages after loading
      scrollToBottom();
      setMessagesOpacity(100);
      setIsHeaderVisible(true);
    }
  }, [isLoadingMessages]);

  const loadMore = () => {
    const skip = (size + 1) * PAGE_SIZE;
    setIsLoadingMore(true);
    return dispatch(queryPaginatedMessages(chatId, PAGE_SIZE, skip, allowDebugRead))
      .catch((error) => {
        setMessageError(error.message);
      })
      .finally(() => {
        setSize(size + 1);
        setIsLoadingMore(false);
      });
  };

  // Load images from internal storage if there are any in the messages
  // store them in the redux store so that they can be accessed later in the MessageItem component
  useEffect(() => {
    if (!messages?.length) return;
    const uniqueContentIds = uniq(
      messages?.flatMap((message) => extractUniqueContentIdsForImages(message.text)),
    );
    const loadImages = async () => {
      const promises = uniqueContentIds.map((contentId) =>
        loadFile({
          accessToken: auth.user.access_token,
          ingestionUrl: services[Service.NODE_INGESTION],
          content: {
            id: contentId,
            internallyStoredAt: new Date().toTimeString(),
          },
          chatId,
          shouldOpen: false,
        })
          .then((loadedFileUrl) => ({ [contentId]: loadedFileUrl }))
          .catch((err) => {
            log.error(`Error loading image ${err.toString()}`);
            return null; // Or handle errors as needed
          }),
      );
      Promise.all(promises).then((results) => {
        // Filter out any null results due to errors
        const chatImagesResults = results.filter((result) => result !== null);
        dispatch(
          setChatImageUrls({ ...chatImageUrls, ...Object.assign({}, ...chatImagesResults) }),
        );
      });
    };
    // only load images if there are unique content ids and they are not stored in redux yet
    const shouldLoadImages =
      uniqueContentIds.length > 0 && !uniqueContentIds.every((id) => chatImageUrls[id]);
    if (shouldLoadImages) {
      loadImages();
    }
  }, [messages, chatImageUrls, dispatch]);

  const { scrollToBottom, setOnScrollEvent } = useContext(ScrollWrapperContext);
  // Listen to scroll position and load more messages if the user is close to the top of the page
  useEffect(() => {
    setOnScrollEvent((scrollPercent) => {
      if (
        scrollPercent < 0.2 &&
        !isLoadingMessages &&
        !isLoadingMore &&
        totalCount > messages.length
      ) {
        loadMore();
      }
    });
    return () => {
      setOnScrollEvent(null);
    };
  }, [totalCount, isLoadingMessages, isLoadingMore, messages.length]);

  const subscriptionVariables = useMemo(() => ({ chatId }), [chatId]);

  // When a new message is added by the user, we scroll to bottom so we can see it.
  useEffect(() => {
    const lastItem = messagesOrContent[messagesOrContent.length - 1] as Message;

    if (lastItem?.role === Role.User) {
      scrollToBottom({ behavior: 'smooth' });
    }
  }, [messages.length]);

  //
  // Web socket logic is all here
  //

  // We store the last message update for each message id
  const messageUpdateRef = useRef({});

  useEffect(() => {
    if (isLoadingMessagesRef.current === false) {
      dispatchUpdatedMessages();
    }
  }, [isLoadingMessagesRef.current]);

  // This function is called to propagate received messageUpdate to redux
  const dispatchUpdatedMessages = useCallback(() => {
    // This run in WS closure and can't access state value. Using the ref to access the latest value.
    if (isLoadingMessagesRef.current === true) {
      return;
    }

    Object.keys(messageUpdateRef.current).forEach((messageId) => {
      const message = messageUpdateRef.current[messageId];
      if (message) {
        // Security, do not send message from stream which are not for the current chat
        // This should not happen but just in case ...
        if (message.chatId !== chatId) {
          // If message is for an other chat, we remove it from the cache
          delete messageUpdateRef.current[messageId];
          log.error(
            `Message ${messageId} is for an other chat, removing from messageUpdateRef cache`,
          );
        } else {
          // Only update message every 400ms to avoid too many request
          dispatch(upsertMessages([message]));
        }
      }
    });
    // Reset the ref to avoid updating the same message multiple times
    messageUpdateRef.current = {};
  }, [dispatch, isLoadingMessages, chatId]);

  // When we receive a messageUpdate from web socket, we save it in a ref to avoid crazy re-rendering of react
  // and save the stream in redux. Stream will be stopped with Stop button or when the message is updated with
  // an streamStoppedAt value.
  const clientWs = useMessagesUpdateSubscription(
    {
      next: (data) => {
        // Store messageUpdate in a ref, no need to save in redux yet otherwise trigger too many re-renders
        // Also save per id as we might receive multiple ids and need to keep the last one of each
        messageUpdateRef.current = {
          ...messageUpdateRef.current,
          [data.messageUpdate.id]: data.messageUpdate,
        };

        if (data.messageUpdate.stoppedStreamingAt || data.messageUpdate.completedAt) {
          // If streaming is stopped, we trigger a redux update then stop handling the stream
          dispatchUpdatedMessages();
          return;
        }

        // However because stoppedStreamingAt is not defined, we add to this event as well to the stream list.
        // Action will be ignored if stream already exists.
        dispatch(
          chatSlice.actions.addStream({
            chatId: data.messageUpdate.chatId,
            messageId: data.messageUpdate.id,
          }),
        );
      },
      error: (errors: GraphQLError[]) => {
        log.error(`Message update subscription error. Error: ${JSON.stringify(errors)}`);
      },
      complete: () => {
        log.info('Message update subscription complete');
      },
    },
    subscriptionVariables,
  );

  // Reset scroll lock when streaming is done
  const { resetScrollLock } = useContext(ScrollWrapperContext);
  useEffect(() => {
    if (streams.length === 0) {
      resetScrollLock();
    }
  }, [resetScrollLock, streams]);

  // if a stream for current chatId exist, we start a loop every 400ms outside of react (because web socket is outside of react lifecycle)
  // to check if there is a new message update for a message that is currently streaming. If yes we store it in redux.
  // MessageItem here will render with a smooth animation but is sepratated from out data flow.
  // When sreams is updated we kill the interval and re-create if needed
  useEffect(() => {
    // If stream is for an other chatId, ignore it
    if (!streams.some((stream) => stream.chatId === chatId)) {
      return;
    }

    const interval = setInterval(() => {
      dispatchUpdatedMessages();
    }, UPDATE_THROTTLE_MS);

    return () => {
      clearInterval(interval);
    };
  }, [streams, chatId, isLoadingMessages]);

  //
  // End of websocket magic
  //

  useEffect(() => {
    // Client WS takes couple seconds to connect, and lose sync with previous call on useMessagesQuery.
    // Trigger an extra mutate to fetch new data. What about overriding mutate call from useMessagesUpdateSubscriptionin // ?
    const removeListener = clientWs.on('connected', () => {
      handleMutateContent();
    });

    // Handle websocket reconnection
    clientWs.on('connected', () => {
      // If wsEvent is null when WS connect it means chatID has just been changed and we need to fetch new messages
      if (wsEvent === null) {
        dispatch(queryPaginatedMessages(chatId, PAGE_SIZE, 0, allowDebugRead))
          .catch((error) => {
            setMessageError(error.message);
          })
          .finally(() => {
            // Using a state with useEfffect to trigger the scroll to bottom
            // so we can be sure that the messages are loaded
            setIsLoadingMessages(false);
          });
      }
      // If we were previously disconnected, we need to reload all messages to ensure we didn't miss any updates
      if (wsEvent === 'closed') {
        setWsEvent((prevEvent) => {
          if (prevEvent === 'connected') return prevEvent;
          log.info('Reconnected to socket, useMessagesUpdateSubscription');
          // refetch messages from current page
          const currentSkip = size * PAGE_SIZE;
          dispatch(queryPaginatedMessages(chatId, PAGE_SIZE, currentSkip, allowDebugRead)).catch(
            (error) => {
              setMessageError(error.message);
            },
          );
          return 'connected';
        });
      }
    });

    // Track websocket disconnection
    // Only set wsEvent to 'closed' if the connection was not clean
    // When switching between chats, the connection is clean and we don't need to set wsEvent to 'closed'
    clientWs.on('closed', (closeEvent: CloseEvent) => {
      if (closeEvent.wasClean) return;
      setWsEvent((prevEvent) => {
        if (prevEvent === 'closed') return prevEvent;
        log.info('Disconnected from socket, useMessagesUpdateSubscription');
        return 'closed';
      });
    });

    return () => removeListener();
  }, [clientWs, handleMutateContent, wsEvent]);

  const onFileOpenClick = async (contentItem: ContentById) => {
    if (!contentItem) return;
    onFileClick(contentItem);
  };

  const loadImageURLFromContent = async (contentItem: ContentById): Promise<string> => {
    if (!contentItem.mimeType.startsWith('image/') || !contentItem.id) return;
    return await loadUploadedFile(contentItem);
  };

  const isMessage = (messageOrContent): messageOrContent is Message => {
    return messageOrContent?.role !== undefined;
  };

  const groupMessagesOrContent = (
    messagesOrContent: (Message | ContentById)[],
  ): Array<Message | ContentById[]> => {
    const result = messagesOrContent.reduce(
      (acc, item) => {
        if (isMessage(item)) {
          acc.push(item);
        } else {
          const lastItem = acc[acc.length - 1];
          if (lastItem && Array.isArray(lastItem) && !isMessage(lastItem[0])) {
            lastItem.push(item);
          } else {
            acc.push([item]);
          }
        }
        return acc;
      },
      [] as (Message | ContentById[])[],
    );
    return result;
  };

  const messagesOrContent = useMemo<Array<Message | ContentById[]>>(() => {
    // only show finished or failed content
    const contentData = content || [];
    const messagesData = messages || [];

    const result = [...removeSystemPrefixFromMessages(messagesData), ...contentData].sort(
      (a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      },
    );

    // group content
    return groupMessagesOrContent(result as Array<Message | ContentById>);
  }, [content, messages]);

  if (isLoadingMessages)
    return (
      <Spinner wrapperClasses="absolute left-0 top-0 flex h-full w-full items-center justify-center" />
    );

  // TODO: Right now the PDF highlighting is behind a setting since it is not final yet.
  // Whats missing: We need to be able to show the PDF highlighting for external files as well.
  // Plus we need to improve the UI/UX for the PDF highlighting.
  const showPdfHighlighting = !!currentChatAssistant?.settings?.showPdfHighlighting;

  const redirectInternalStorageOnly =
    !!currentChatAssistant?.company?.configuration?.redirectInternalStorageOnly;

  return (
    <div
      className={cn({
        'mx-auto flex max-w-[928px] flex-1 flex-col items-start gap-2': true,
        'opacity-0': messagesOpacity === 0,
        'transition-opacity duration-100': messagesOpacity !== 0,
      })}
    >
      {totalCount > messages.length && (
        <ButtonIcon
          variant={ButtonVariant.SECONDARY}
          type={ButtonType.BUTTON}
          onClick={loadMore}
          isLoading={isLoadingMore}
          className={'mb-4 mt-4 w-full'}
          buttonSize={ButtonSize.SMALL}
        >
          Load more messages
        </ButtonIcon>
      )}

      <div ref={messagesRef} className="w-full">
        {!messagesError &&
          messagesOrContent.map((item, index) => (
            <div
              className={cn({
                'text-on-background-main w-full px-0 sm:px-4': true,
                'mb-5 pt-7': isMessage(item),
                'mb-0 first:pt-4': !isMessage(item),
                'bg-surface text-on-surface': isMessage(item) && item.role !== Role.User,
              })}
              key={isMessage(item) ? item.id : item[0].id}
            >
              {isMessage(item) ? (
                <MessageItem
                  messageId={item.id}
                  isStreaming={streams.some(
                    (stream) => stream.chatId === id && stream.messageId === item.id,
                  )}
                  allowDebugRead={allowDebugRead}
                  onThumbsClick={onThumbsClick}
                  onSavePromptClick={onSavePromptClick}
                  handleSelectPrompt={handleSelectPrompt}
                  showPdfHighlighting={showPdfHighlighting}
                  redirectInternalStorageOnly={redirectInternalStorageOnly}
                  enableScrollOnMessageUpdate={index === messagesOrContent.length - 1}
                  onStreamingDone={() => {
                    dispatch(
                      chatSlice.actions.removeStream({
                        chatId: id,
                        messageId: item.id,
                      }),
                    );
                  }}
                />
              ) : (
                <ContentList
                  content={item}
                  handleFileOpenClick={onFileOpenClick}
                  loadImageURLFromContent={loadImageURLFromContent}
                />
              )}
            </div>
          ))}
      </div>

      {/* Modals */}
      <div
        tabIndex={-1}
        className={`pointer-events-none fixed z-50 opacity-0 transition-opacity ${
          showModal ? 'pointer-events-auto opacity-100' : ''
        }`}
      >
        {modalContent && (
          <Modal
            title={modalContent.title}
            icon={modalContent.icon}
            shouldShow={showModal}
            handleClose={handleModalClose}
          >
            {modalContent.children}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ChatMessages;
