import { logger } from '@unique/next-commons/logger';
import { clientContextValues, Service } from '@unique/next-commons/swr';
import { getSdk } from '@/@generated/graphql';
import { chatSlice } from '../chatSlice';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:mutateDeleteChatIds',
});

export const mutateDeleteChatIds = (chatIds: string[] = []) => {
  return async (dispatch, getState) => {
    const { setChats } = chatSlice.actions;

    try {
      const sdkScope = getSdk(clientContextValues.clients[Service.NODE_CHAT]);
      await sdkScope.ChatDeleteByChatIds({ chatIds });
      await dispatch(setChats(getState().chat.chats?.filter((chat) => !chatIds.includes(chat.id))));
      return Promise.resolve();
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};
