'use client';

import { ChatQuery, ChatUpload, Content } from '@/@generated/graphql';
import { useAssistantQuery, useContentByChatQuery } from '@/lib/swr/hooks';
import {
  isIngestingContent,
  LayoutContext,
  REFRESH_INTERVAL_INGESTION,
} from '@unique/shared-library';
import { FC, MouseEventHandler, useContext, useEffect, useMemo, useState } from 'react';
import ChatInput from './ChatInput';
import ChatMessages from './ChatMessages';
import ChatDropzone from '../ChatDropzone';
import { IconUploadInChat } from '@unique/icons';
import { PoweredByDisclaimer } from '../PoweredByDisclaimer';
import cn from 'classnames';
import { getAcceptableFileTypes } from '@/helpers/getAcceptableFileTypes';
import { ChatContext } from './ChatProvider';
import { useFileUploadProgress } from '@/hooks/useFileUploadProgress';
import { ChatUploadProgress } from './ChatUploadProgress';

interface Props {
  id: string;
  currentChat: ChatQuery['chat'];
}

export const ChatContainer: FC<Props> = ({ id, currentChat }) => {
  const [selectedPrompt, setSelectedPrompt] = useState<{ prompt: string } | null>(null);
  const [isIngesting, setIsIngesting] = useState<boolean>(false);
  const { setSplitPaneContent } = useContext(LayoutContext);
  const [showChatDropzone, setShowChatDropzone] = useState(false);
  const [assistantQueryVariables, setAssistantQueryVariables] = useState(null);
  const { uploadInputRef, activeUploads } = useContext(ChatContext);
  const { isUploading, uploadProgress } = useFileUploadProgress(activeUploads, id);
  const { data: assistantQuery } = useAssistantQuery(assistantQueryVariables);

  // only execute query once chatId (e.g. id) is available
  // add refresh interval as soon as one content is ingesting
  // remove refresh interval once all content is either finished or failed
  const {
    data: content,
    isLoading: isLoadingContent,
    mutate: mutateContent,
  } = useContentByChatQuery(
    id
      ? {
          chatId: id,
        }
      : null,
    { refreshInterval: isIngesting ? REFRESH_INTERVAL_INGESTION : 0 },
  );

  const contentByChat: Content[] | null = useMemo(() => {
    return content?.contentByChat as Content[] | null;
  }, [content]);

  // check if any content is ingesting
  useEffect(() => {
    if (isLoadingContent) return;
    if (!contentByChat) return;
    const isAnyContentIngesting = contentByChat.some((content) =>
      isIngestingContent(content.ingestionState),
    );
    setIsIngesting(isAnyContentIngesting);
  }, [isLoadingContent, contentByChat]);

  useEffect(() => {
    setSplitPaneContent(null);
  }, [id, setSplitPaneContent]);

  useEffect(() => {
    if (!currentChat?.assistant?.id) return;
    setAssistantQueryVariables({ assistantId: currentChat.assistant.id });
  }, [currentChat]);

  const handleSelectPrompt = (prompt: string) => {
    // Define selectedPrompt as object to trigger the useEffect in ChattInput even if retriggered with same value
    setSelectedPrompt({ prompt });
  };

  const chatUploadEnabled = assistantQuery?.assistantByUser?.chatUpload === ChatUpload.Enabled;

  const handleChatUploadClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (event.button !== 0) return; // If it's not left click, ignore

    if (!uploadInputRef?.current) {
      console.error('Upload input not found.');
      return;
    }
    uploadInputRef.current.click();
  };

  const handleShowChatDropzone = () => {
    if (!chatUploadEnabled || isUploading) return;
    setShowChatDropzone(true);
  };

  const handleHideChatDropzone = () => {
    setShowChatDropzone(false);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = e;

    // Check if cursor is truly outside the container
    if (
      clientX <= rect.left ||
      clientX >= rect.right ||
      clientY <= rect.top ||
      clientY >= rect.bottom
    ) {
      handleHideChatDropzone();
    }
  };

  const acceptableFileTypes = getAcceptableFileTypes(assistantQuery?.assistantByUser);

  return (
    <div
      className="h-full"
      onDrop={handleHideChatDropzone}
      onDragEnter={handleShowChatDropzone}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={onDragLeave}
    >
      <div
        className={cn({
          'bg-surface z-55 absolute left-0 h-[calc(100vh-90px)] w-full px-4': true,
          block: showChatDropzone,
          hidden: !showChatDropzone,
        })}
      >
        <ChatDropzone
          assistant={assistantQuery?.assistantByUser}
          handleUploadFailed={handleHideChatDropzone}
        >
          <div className="flex h-full flex-row items-center justify-center sm:flex-col">
            <div className="ml-4 flex flex-1 sm:ml-0 sm:flex-none">
              <IconUploadInChat height="80px" width="80px" />
            </div>
            <div className="flex flex-col items-start text-left sm:items-center sm:text-center">
              <p className="text-on-background-main font-extrabold">Drag & Drop Files</p>
              <p className="text-on-background-dimmed hidden sm:block">{acceptableFileTypes}</p>
            </div>
          </div>
        </ChatDropzone>
        <PoweredByDisclaimer />
      </div>
      <div
        className={cn({
          'relative mx-auto h-full w-full max-w-[928px] flex-col': true,
          flex: !showChatDropzone,
          hidden: showChatDropzone,
        })}
      >
        {isUploading ? (
          <ChatUploadProgress progress={uploadProgress} />
        ) : (
          <div className="flex-1">
            <ChatMessages
              handleSelectPrompt={handleSelectPrompt}
              currentChatAssistant={assistantQuery?.assistantByUser}
              content={contentByChat}
              handleMutateContent={() => mutateContent()}
            />
          </div>
        )}
        <div className="sticky bottom-0 z-10">
          <ChatInput
            selectedPrompt={selectedPrompt}
            currentChatAssistant={assistantQuery?.assistantByUser}
            content={contentByChat}
            isIngesting={isIngesting}
            chatUploadEnabled={chatUploadEnabled}
            handleChatUploadClick={handleChatUploadClick}
            isUploading={isUploading}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
