// import { Mermaid } from '../components/Chat/AdvancedFormatting/Mermaid/Mermaid';
import { FinancialChart } from '../components/Chat/AdvancedFormatting/FinancialChart/FinancialChart';
import { FeatureFlagContext } from '@unique/shared-library';
import { useContext } from 'react';
import { Message } from '@/@generated/graphql';

/**
 * This is a hook that returns the advanced formatting components for the message to be used in the MarkdownPreview component.
 * Here can be managed feature flags for each component.
 * @param message - The message object widgets might need to access.
 * @returns The advanced formatting list of components
 */
export const useAdvancedFormatting = (message: Message) => {

  const { flags } = useContext(FeatureFlagContext);

  if (!flags.FEATURE_FLAG_ENABLE_ADVANCED_FORMATTING_UN_9758) {
    return {};
  }

  return {
    // mermaid: {
    //   component: Mermaid,
    //   props: {
    //     message,
    //   },
    // },
    financialChart: {
      component: FinancialChart,
      props: {
        message,
      },
    },
  };
};