'use client';
import { FC, useContext } from 'react';
import Routing from '@/components/Routing';
import {
  SentryProvider,
  ToastProvider,
} from '@unique/shared-library/providers';
import {
  ModalProvider,
} from '@unique/shared-library';
import { ErrorHandler } from '@unique/next-commons/swr';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';

const App: FC = () => {
  const {
    selfUrl,
    basePath,
    sentryDsn, 
    version,
  } = useContext(ConfigurationContext);
  
  return (
    <SentryProvider sentryDsn={sentryDsn} release={version}>
      <ToastProvider>
        <ModalProvider>
          <ErrorHandler basePath={basePath} selfUrl={selfUrl}>
            <Routing />
          </ErrorHandler>
      </ModalProvider>
      </ToastProvider>
    </SentryProvider>
  );
};

export default App;
