'use client';
import { useMemo } from 'react';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { IconArrowDown, IconEyeClosed, IconEyeOpen } from '@unique/icons';
import Dropdown from '@unique/component-library/src/Dropdown';
import cn from 'classnames';
import { DueDiligence } from '@/@generated/graphql';
import DueDiligenceFilesList from './DueDiligenceFilesList';

export default function DueDiligenceFilesDropdown({
  dueDiligence,
  chatId,
}: {
  dueDiligence: Partial<DueDiligence>;
  chatId: string;
}) {
  const totalFiles = useMemo(() => {
    return dueDiligence?.sourceFileIds?.length + dueDiligence?.questionFileIds?.length;
  }, [dueDiligence]);

  return (
    <>
      <Dropdown
        dropDownElement={<DueDiligenceFilesList chatId={chatId} dueDiligence={dueDiligence} />}
        className="min-w-lg left-0 w-auto md:left-auto md:right-0"
      >
        {(isOpen) => (
          <ButtonIcon
            variant={ButtonVariant.SECONDARY}
            icon={isOpen ? <IconEyeClosed /> : <IconEyeOpen />}
            className="w-full flex-nowrap whitespace-nowrap"
          >
            <span className="flex flex-nowrap items-center gap-x-3">
              <span className="inline">View {totalFiles || ''} added files </span>
              <IconArrowDown
                className={cn({
                  'font-bold transition duration-200': true,
                  'rotate-180': isOpen,
                })}
                width="14px"
                height="14px"
              />
            </span>
          </ButtonIcon>
        )}
      </Dropdown>
    </>
  );
}
