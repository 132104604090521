'use client';
import { createContext, useContext, useState, FC, ReactNode, MouseEvent, useRef } from 'react';
import { ChatRenameModal } from './ChatRenameModal';
import { Chat } from '@/lib/swr/types';

export type ChatToRename = {
  id: string;
  title?: string;
};

export type ActiveUpload = { fileName: string; progress: number } | null;

export type ActiveUploads = {
  [key: string]: ActiveUpload[];
};

type ChatContextType = {
  handleClickRename: (event: MouseEvent<HTMLButtonElement>, chat: ChatToRename) => void;
  uploadInputRef: React.RefObject<HTMLInputElement>;
  handleFileUpdate: (files: FileList) => void;
  handleSetUploadInput: (fileInput: HTMLInputElement) => void;
  activeUploads: ActiveUploads;
  handleSetUploadProgress: (chatId: string, activeUpload: ActiveUpload) => void;
};

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [selectedChatForRename, setSelectedChatForRename] = useState<Partial<Chat> | undefined>(
    undefined,
  );
  const [activeUploads, setActiveUploads] = useState<ActiveUploads>({});
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpdate = (files: FileList) => {
    if (!uploadInputRef.current) return;
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(files[0]);
    uploadInputRef.current.files = dataTransfer.files;
    uploadInputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const handleSetUploadInput = (fileInput: HTMLInputElement) => {
    uploadInputRef.current = fileInput;
  };

  const handleClickRename = (event: MouseEvent<HTMLButtonElement>, chat: ChatToRename) => {
    event.preventDefault();
    setSelectedChatForRename(chat);
    setShowRenameModal(true);
  };

  const handleCloseRenameModal = () => {
    setShowRenameModal(false);
    setSelectedChatForRename(undefined);
  };

  const handleSetUploadProgress = (chatId: string, activeUpload: ActiveUpload) => {
    if (!activeUpload) {
      const tempActiveUploads = { ...activeUploads };
      delete tempActiveUploads[chatId];
      setActiveUploads(tempActiveUploads);
      return;
    }
    setActiveUploads((prev) => ({
      ...prev,
      [chatId]: activeUploads?.[chatId]
        ? [
            ...activeUploads[chatId].map((curr) =>
              curr.fileName === activeUpload.fileName ? activeUpload : curr,
            ),
          ]
        : [activeUpload],
    }));
  };

  return (
    <ChatContext.Provider
      value={{
        handleClickRename,
        uploadInputRef,
        handleFileUpdate,
        handleSetUploadInput,
        activeUploads,
        handleSetUploadProgress,
      }}
    >
      {children}
      {showRenameModal && selectedChatForRename && (
        <ChatRenameModal chat={selectedChatForRename} onClose={handleCloseRenameModal} />
      )}
    </ChatContext.Provider>
  );
};

export const useChatRename = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatRename must be used within a ChatProvider');
  }
  return context;
};
