import { useEffect, useState } from 'react';
import cn from 'classnames';

export const FinancialChartTickers = ({ series, isLoading }) => {

    if (isLoading) {
        return <></>;
    }

    const [listingTrends, setListingTrends] = useState<{
        [key: string]: {
            trend: number;
            trendPercentage: number;
        }
    }>({});

    useEffect(() => {
        series.forEach((serie) => {
            // Look at first day and last day of the priceHistory array and calculate trend and percentage value
            const firstDay = serie.priceHistory[0]?.value ?? 0;
            const lastDay = serie.priceHistory[serie.priceHistory.length - 1]?.value ?? 0;
            const trend = lastDay - firstDay;
            const trendPercentage = ((lastDay - firstDay) / firstDay) * 100;
            setListingTrends(prev => ({ ...prev, [serie.info.ticker]: { trend, trendPercentage } }));
        });
    }, [series]);

    return (    
        <div className={cn('flex flex-row gap-x-14 gap-y-5 pl-2 pr-2 pt-8 pb-2 w-full flex-wrap overflow-hidden', {
            'mb-6': series.length === 1
        })}>
        { series.length > 0 && listingTrends && series.map((serie, index) => (
            <div key={`${index}-${serie.info.ticker}`}>
                <h3 className="text-xs">{serie.info.ticker}: {serie.info.exchange}</h3>
                <p className="text-2xl text-gray-500"><span className="text-xs">{serie.info.currency}</span> {serie.priceHistory[serie.priceHistory.length - 1].value}</p>
                { listingTrends[serie.info.ticker] && <div>
                    { listingTrends[serie.info.ticker] ? listingTrends[serie.info.ticker].trend > 0 ? <>
                    <p className="text-sm font-bold text-success-dark">↑ {listingTrends[serie.info.ticker].trendPercentage.toFixed(2)}%, {listingTrends[serie.info.ticker].trend.toFixed(2)}</p>
                        </> : <>
                    <p className="text-sm font-bold text-error-dark">↓ {listingTrends[serie.info.ticker].trendPercentage.toFixed(2)}%, {listingTrends[serie.info.ticker].trend.toFixed(2)}</p>
                    </> : <></> }
                </div>}
            </div>
        ))}
    </div>
    );
};