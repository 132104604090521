import { FeedbackUncheckedCreateInput } from '@/@generated/graphql';
import { SnowAuthData, submitSnow } from '@/helpers/submitSnow';
import { useMessageQuery } from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import { ButtonIcon } from '@unique/component-library';
import { useAuth } from 'react-oidc-context';

type Props = {
  handleClose: () => void;
  messageId: string;
  snowAuthData: SnowAuthData;
  space: string;
  spaceId: string;
  translationLanguage?: string;
  submitFeedback: (data: FeedbackUncheckedCreateInput) => void;
  isPositive: boolean;
};

export default function ServiceNowModal({
  handleClose,
  messageId,
  snowAuthData,
  space,
  spaceId,
  translationLanguage,
  submitFeedback,
  isPositive,
}: Props) {
  const { user } = useAuth();
  const messages = useAppSelector((state) => state.messages.messages).toSorted(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  const answerMessageToEvaluateIndex = messages.findIndex(m => m.id === messageId);
  const answerMessageToEvaluate = messages[answerMessageToEvaluateIndex];
  const promptMessageOfTheAnswerToEvaluate = messages[answerMessageToEvaluateIndex - 1];

  const messageQueryVariables = {
    chatId: answerMessageToEvaluate.chatId,
    messageId: promptMessageOfTheAnswerToEvaluate.id,
  };
  const { data: messageQuery, mutate: fetchMessage } = useMessageQuery(messageQueryVariables);

  const onSubmit = async (includingSensitiveData: boolean) => {
    handleClose();
    submitFeedback({ messageId, positive: isPositive });

    let prompt = '';
    let answer = '';
    let history = '';
    let llmRequest = '';
    const references = JSON.stringify(answerMessageToEvaluate?.references.map(m => ({referenceName: m.name, referenceSequence: m.sequenceNumber})));

    if (includingSensitiveData) {
      fetchMessage(); // update the prompt message to get gptRequest
      prompt = promptMessageOfTheAnswerToEvaluate?.text;
      answer = answerMessageToEvaluate?.text;
      history = JSON.stringify(messages.map(m => ({id: m.id, text: m.text, role: m.role})));
      llmRequest = JSON.stringify(messageQuery?.message?.gptRequest);
    }

    await submitSnow({
      snowAuthData: snowAuthData,
      messageId,
      prompt,
      answer,
      space,
      spaceId,
      isPositive,
      user: user?.profile?.email,
      history,
      llmRequest,
      references,
      translationLanguage,
    });
  };

  return (
    <ModalWrapper>
      <Title text="Indicate Chat History Data Classification" />
      <p>
        By indicating whether your JAI interaction contains any confidential data, the references (prompt & answers)
        will be excluded from your feedback submission. <br/>
        <strong>Can you confirm that the prompt or answer of this chat history does not contain any C4 data?</strong><br/>
        <span className='text-sm'>(you can proceed with the feedback process either way)</span>
      </p>
      <div className="flex justify-end gap-x-4">
        <ButtonIcon onClick={() => onSubmit(false)}>No</ButtonIcon>
        <ButtonIcon onClick={() => onSubmit(true)}>Yes</ButtonIcon>
      </div>
    </ModalWrapper>
  );
}

const Title = ({ text }: { text: string }) => (
  <div className="flex items-center justify-center gap-x-3 px-10">
    <p className="text-on-background-main text-2xl font-bold">{text}</p>
  </div>
);

const ModalWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="text-on-background-main flex flex-col gap-y-5 py-4 text-center">{children}</div>
);
