import { MagicTableSheetState, MagicTableSheetUpdateSubscription } from '@/@generated/graphql';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CellEditing } from '@unique/component-library';

export type MagicTableSheetUpdate = MagicTableSheetUpdateSubscription['magicTableSheetUpdate'];

export const defaultMagicTableSheetUpdate: MagicTableSheetUpdate = {
  id: '',
  state: MagicTableSheetState.Idle,
  updatedAt: '',
};

type CreateDueDiligencePayload = {
  sheetName: string;
  importStepContentId?: string;
  sheetId: string;
  sourceContentIds: string[];
  dueDiligenceId: string;
};

export type SelectedCellContext = {
  rowOrder: number;
  columnOrder: number;
  rowId: string;
  columnId: string;
};

type DueDiligenceResultListArgs = { resultCount: number; take: number; hasMore: boolean };

export interface DueDiligenceState {
  editingCell: CellEditing | null;
  socketConnected: boolean;
  dueDiligencePayload: CreateDueDiligencePayload | null;
  isGridReady: boolean;
  selectedContextCell: SelectedCellContext | null;
  magicTableSheetUpdates: MagicTableSheetUpdate;
  uploadedContentIds: string[];
  showUploadTrayNotification: boolean;
  dueDiligenceListResult: DueDiligenceResultListArgs;
}

const initialState: DueDiligenceState = {
  editingCell: null,
  socketConnected: false,
  dueDiligencePayload: null,
  isGridReady: false,
  selectedContextCell: null,
  magicTableSheetUpdates: defaultMagicTableSheetUpdate,
  uploadedContentIds: [],
  showUploadTrayNotification: false,
  dueDiligenceListResult: { resultCount: 0, take: 10, hasMore: true },
};

export const dueDiligenceSlice = createSlice({
  name: 'dueDiligence',
  initialState,
  reducers: {
    updateEditingCell: (state, action: PayloadAction<CellEditing>) => {
      state.editingCell = action.payload;
    },
    updateSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.socketConnected = action.payload;
    },
    updateDueDiligencePayload: (state, action: PayloadAction<CreateDueDiligencePayload>) => {
      state.dueDiligencePayload = action.payload;
    },
    setGridReady: (state, action: PayloadAction<boolean>) => {
      state.isGridReady = action.payload;
    },
    setSelectedContextCell: (state, action: PayloadAction<SelectedCellContext>) => {
      state.selectedContextCell = action.payload;
    },
    setMagicTableSheetUpdates: (state, action: PayloadAction<MagicTableSheetUpdate>) => {
      state.magicTableSheetUpdates = action.payload;
    },
    setUploadedContentIds: (state, action: PayloadAction<string[]>) => {
      state.uploadedContentIds = action.payload;
    },
    setShowUploadTrayNotification: (state, action: PayloadAction<boolean>) => {
      state.showUploadTrayNotification = action.payload;
    },
    setDueDiligenceListResult: (state, action: PayloadAction<DueDiligenceResultListArgs>) => {
      state.dueDiligenceListResult = action.payload;
    },
  },
});

export const {
  updateEditingCell,
  updateSocketConnected,
  updateDueDiligencePayload,
  setGridReady,
  setSelectedContextCell,
  setMagicTableSheetUpdates,
  setUploadedContentIds,
  setShowUploadTrayNotification,
  setDueDiligenceListResult,
} = dueDiligenceSlice.actions;

const dueDiligenceReducer = dueDiligenceSlice.reducer;

export { dueDiligenceReducer };
