import { useContentByChatQuery, usePaginatedContentQuery } from '@/lib/swr/hooks';
import { ContentByChat } from '@/lib/swr/types';
import { isIngestingContent, REFRESH_INTERVAL_INGESTION } from '@unique/shared-library';
import { useEffect, useMemo, useState } from 'react';

type Props = {
  chatId: string;
  sourceFileIds?: string[];
  questionFileIds?: string[];
};

export default function useDueDiligenceArtifacts({
  chatId,
  sourceFileIds,
  questionFileIds,
}: Props) {
  const [isIngestingQuestion, setIsIngestingQuestion] = useState(false);

  const { data: chatContent, isLoading: isLoadingChatContent } = useContentByChatQuery(
    {
      chatId,
    },
    { refreshInterval: isIngestingQuestion ? REFRESH_INTERVAL_INGESTION : 0 },
  );

  const { data: sourceContent, isLoading: isLoadingSourceContent } = usePaginatedContentQuery(
    sourceFileIds ? { where: { id: { in: sourceFileIds } } } : null,
  );

  const { data: importQuestionContent, isLoading: isImportingQuestionContent } =
    usePaginatedContentQuery(questionFileIds ? { where: { id: { in: questionFileIds } } } : null);

  const questionsContent = useMemo(() => {
    if (importQuestionContent?.paginatedContent?.nodes?.length) {
      return importQuestionContent?.paginatedContent?.nodes;
    }

    const questionContent: ContentByChat[] = [];

    questionFileIds?.forEach((questionFileId) => {
      const foundQuestion = chatContent?.contentByChat?.find(
        (content) => content.id === questionFileId,
      );
      if (foundQuestion) {
        questionContent.push(foundQuestion);
      }
    });

    return questionContent || [];
  }, [importQuestionContent, chatContent?.contentByChat]);

  const questionsIngestionInProgress = questionsContent?.some((content) =>
    isIngestingContent(content.ingestionState),
  );

  useEffect(() => {
    setIsIngestingQuestion(questionsIngestionInProgress);
  }, [questionsIngestionInProgress]);

  return {
    isLoadingChatContent,
    sourceContent,
    isLoadingSourceContent,
    isImportingQuestionContent,
    questionsContent,
    questionsIngestionInProgress,
  };
}
