'use client';

import { useChatDelete } from '@/hooks/useChatDelete';
import { useChatsQuery } from '@/lib/swr/hooks';
import {
  PopupMenu,
  SecondLevelNavItem,
  ExploreMenuButton,
  useBulkSelectWithShift,
} from '@unique/component-library';
import { IconSelect, IconDelete, IconEdit, IconHistory } from '@unique/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FC, useContext, useEffect, useState } from 'react';
import { useChatRename } from './ChatProvider';
import IndeterminateCheckbox from '@unique/component-library/src/IndeterminateCheckbox';
import { ChatMenuHeader } from './ChatMenuHeader';
import { ChatMenuSelectionHeader } from './ChatMenuSelectionHeader';
import { chatSlice, useAppDispatch, useAppSelector } from '@/store';
import { truncateChatTitle } from '@/helpers/truncateChatTitle';
import { SortOrder } from '@/@generated/graphql';
import { FeatureFlagContext } from '@unique/shared-library';

type ChatMenuProps = {
  title?: string;
};

export const ChatMenu: FC<ChatMenuProps> = ({ title = 'Chats' }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { flags } = useContext(FeatureFlagContext);
  const {
    data: chatsQuery,
    isLoading,
    error: chatError,
    mutate,
  } = useChatsQuery({
    orderBy: [{ createdAt: SortOrder.Desc }],
    take: flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803 ? 5 : 50,
  });
  const { deleteChatIds, deleteAllChats } = useChatDelete();
  const { handleClickRename } = useChatRename();
  const { chats } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();
  const { setChats } = chatSlice.actions;

  useEffect(() => {
    if (chatsQuery?.chats) {
      dispatch(setChats(chatsQuery.chats));
    }
  }, [chatsQuery?.chats]);

  const [selectedChats, setSelectedChats] = useState<string[]>([]);

  const getIsOnSingleChatPage = (chatId: string) => id === chatId;

  const [isSelectingModeActive, setIsSelectingModeActive] = useState(false);

  const { toggleItem } = useBulkSelectWithShift({
    items: chats,
    selectedItems: selectedChats,
    setSelectedItems: setSelectedChats,
  });

  const toggleSelectedChat = (chatId: string, event?: React.MouseEvent) => {
    setIsSelectingModeActive(true);
    toggleItem(chatId, event);
  };

  const toggleAllChats = () => {
    setIsSelectingModeActive(true);
    if (selectedChats.length !== (chats?.length || 0)) {
      setSelectedChats((chats || []).map((item) => item.id));
      return;
    }
    setSelectedChats([]);
  };

  // If user navigate we reset select mode
  const location = useLocation();

  useEffect(() => {
    setIsSelectingModeActive(false);
    setSelectedChats([]);
  }, [location.pathname]);

  useEffect(() => {
    if (!id?.length || isLoading) return;
    // If a new chat is created (route changes with new id), revalidate chat menu
    const chatExists = chats?.findIndex((chat) => chat.id === id) > -1;
    if (!chatExists) mutate();
  }, [chats, id, mutate, isLoading]);

  return (
    <div className="relative h-full">
      {!isSelectingModeActive ? (
        <ChatMenuHeader
          title={title}
          isLoading={isLoading}
          activateSelectMode={() => setIsSelectingModeActive(true)}
          onSelectAll={toggleAllChats}
          onDeleteAll={() => deleteAllChats({ mutate })}
          disableSelectAndDeleteAll={chats?.length === 0}
        />
      ) : (
        <ChatMenuSelectionHeader
          selectedChatIds={selectedChats}
          chats={chats}
          onToggleButtonClick={toggleAllChats}
          onDeleteButtonClick={() => {
            deleteChatIds(selectedChats, { mutate, chats });
            setSelectedChats([]);
            setIsSelectingModeActive(false);
          }}
          onCancelButtonClick={() => {
            setSelectedChats([]);
            setIsSelectingModeActive(false);
          }}
        />
      )}

      {!isLoading && !chats?.length && !chatError && (
        <SecondLevelNavItem
          key="no-chats"
          name="Your past chats will show here"
          isActive={false}
          readOnly={true}
        />
      )}

      {!isLoading && !chatError && (
        <>
          {chats?.map((chat) => (
            <SecondLevelNavItem
              key={chat.id}
              name={chat.title ?? 'Unnamed chat'}
              href={`/${chat.id}`}
              isActive={getIsOnSingleChatPage(chat.id)}
              className="group select-none"
              onClick={
                isSelectingModeActive &&
                ((event) => {
                  event.preventDefault();
                  toggleSelectedChat(chat.id, event);
                })
              }
              icon={
                isSelectingModeActive && (
                  <IndeterminateCheckbox defaultChecked={selectedChats.includes(chat.id)} />
                )
              }
            >
              {!isSelectingModeActive && (
                <div className="ml-auto">
                  <PopupMenu
                    buttonClassesClosed="hidden sm:group-hover:flex"
                    buttonClassesOpened="flex ml-2 sm:group-hover:ml-0"
                    menuItems={[
                      ...(!flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803
                        ? [
                            {
                              label: 'Select',
                              icon: <IconSelect width="14px" height="14px" />,
                              onClick: (event) => {
                                event.preventDefault();
                                toggleSelectedChat(chat.id);
                              },
                            },
                          ]
                        : []),
                      {
                        label: 'Rename',
                        icon: <IconEdit width="18px" height="18px" />,
                        onClick: (event) =>
                          handleClickRename(event, {
                            ...chat,
                            title: truncateChatTitle(chat.title),
                          }),
                      },
                      {
                        label: 'Delete',
                        icon: <IconDelete />,
                        onClick: (event) => {
                          event.preventDefault();
                          deleteChatIds([chat.id], { mutate, chats });
                        },
                      },
                    ]}
                  />
                </div>
              )}
            </SecondLevelNavItem>
          ))}
        </>
      )}
      {flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803 && (
        <div className="px-4 py-5">
          <ExploreMenuButton
            label="All Past Chats"
            icon={<IconHistory />}
            onClick={() => navigate('/history')}
          />
        </div>
      )}
    </div>
  );
};
