import {
  addLinkToReferencePlaceholder,
  addSpanToReferencePlaceholders,
  referenceNumberRegex,
  referenceRegex,
} from '@/helpers/addLinkToReferencePlaceholder';
import { MarkdownPreview } from '@unique/component-library';
import { nanoid } from 'nanoid';
import { isValidElement, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { InlineReference } from '../Chat/InlineReference';

type Props = {
  value: string;
  isFirstRow?: boolean;
  renderAsPlainText?: boolean;
  isCellRenderer?: boolean;
};

export default function DueDiligenceMarkdownPreview({
  value,
  isFirstRow,
  renderAsPlainText,
  isCellRenderer,
}: Props) {
  const navigate = useNavigate();
  const { spaceSlug, spaceId } = useParams();

  const textRenderer = useMemo(() => {
    if (!value) return '';
    // This has to come first because the referenceNumberRegex has some similarities with the referenceRegex
    // and we want to prioritize the referenceRegex first to avoid conflicts
    if (value.match(referenceRegex)) {
      return addLinkToReferencePlaceholder(value);
    }
    if (value.match(referenceNumberRegex)) {
      return addSpanToReferencePlaceholders(value);
    }
    return value;
  }, [value]);

  const handleReferenceClicked = useCallback(
    (reference: string, messageId: string) => {
      navigate(
        `/space/${spaceId}/${spaceSlug}?reference=${reference}&messageId=${messageId}&requestId=${nanoid()}`,
      );
    },
    [navigate, spaceId, spaceSlug],
  );

  const MarkdownSpan = (props: JSX.IntrinsicElements['span']): JSX.Element => {
    const { children } = props;
    const [reference, messageId] = children.toString().split('&');
    return (
      <InlineReference onClickReference={() => handleReferenceClicked(reference, messageId)}>
        {reference}
      </InlineReference>
    );
  };

  const MarkdownLink = (props: JSX.IntrinsicElements['sub']): JSX.Element => {
    const { children } = props;

    const child = isValidElement(children[0])
      ? (children[0] as React.ReactElement).props.children
      : (children[0] as string[]);

    if (!child) return <sub className="cursor-pointer text-left text-[14px]">{children}</sub>;

    const [reference, messageId] = child.toString().split('&');

    return (
      <sub
        onClick={() => handleReferenceClicked(reference, messageId)}
        className="cursor-pointer border-b border-dashed text-left text-[14px] hover:border-b-0"
      >
        {children}
      </sub>
    );
  };

  const MarkdownParagraph = (props: JSX.IntrinsicElements['p']): JSX.Element => {
    const { children } = props;
    return (
      <p
        className={cn({
          'text-on-background-main mb-4 px-2': true,
          '!px-0': !isCellRenderer,
        })}
      >
        {children}
      </p>
    );
  };

  if (renderAsPlainText) {
    return <div className="!font-normal">{textRenderer}</div>;
  }

  if (isFirstRow) {
    return <div className="p-2">{textRenderer}</div>;
  }

  return (
    <MarkdownPreview
      text={textRenderer}
      customComponents={{ span: MarkdownSpan, sub: MarkdownLink, p: MarkdownParagraph }}
      className="text-on-background-main"
    />
  );
}
