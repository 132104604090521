'use client';

import { ForbiddenPage, UserProfileContext } from '@unique/shared-library';
import { useContext, FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Roles, extractRoles, hasAllRequiredRoles, hasAnyRequiredRoles } from '../authorization';
import {
  hasAnyRequiredAssignments,
  hasAllRequiredAssignments,
} from '../authorization/required-assignments';
import { Assignment } from '@unique/shared-library/hooks/types';

interface PageAccessProps {
  hasAllRoles?: Roles[];
  hasAnyRoles?: Roles[];
  hasAllAssignments?: Assignment[];
  hasAnyAssignments?: Assignment[];
  children: React.ReactNode;
}

export const PageAccess: FC<PageAccessProps> = ({
  hasAllRoles,
  hasAnyRoles,
  hasAllAssignments,
  hasAnyAssignments,
  children,
}) => {
  const auth = useAuth();
  const { assignments } = useContext(UserProfileContext);

  // Authorization Checks
  let allowAccess = false;

  if (auth.isAuthenticated) {
    const roles = extractRoles(auth.user) ?? [];
    if (hasAnyRoles && hasAnyRoles.length > 0)
      allowAccess = hasAnyRequiredRoles(roles, hasAnyRoles);
    if (hasAllRoles && hasAllRoles.length > 0)
      allowAccess = hasAllRequiredRoles(roles, hasAllRoles);
  }

  if (!allowAccess) {
    if (auth.isAuthenticated) {
      if (hasAnyAssignments && hasAnyAssignments.length > 0)
        allowAccess = hasAnyRequiredAssignments(assignments, hasAnyAssignments);
      if (hasAllAssignments && hasAllAssignments.length > 0)
        allowAccess = hasAllRequiredAssignments(assignments, hasAllAssignments);
    }
  }

  if (!auth.isAuthenticated || !allowAccess) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
};
