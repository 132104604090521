import { IconArrowDown } from '@unique/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const ChatHistoryBreadcrumb: FC = () => {
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  return (
    <div
      className="subtitle-2 text-on-background-dimmed flex cursor-pointer items-center gap-x-2.5"
      onClick={navigateBack}
    >
      <span>
        <span className="hidden sm:inline">Chat</span> History
      </span>
      <IconArrowDown className="origin-center -rotate-90" width="11px" height="11px" />
    </div>
  );
};
