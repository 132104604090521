import { IconEmptySearch } from '@unique/icons';

export const ChatHistoryNoResults = () => {
  return (
    <div className="flex h-[60vh] flex-col items-center justify-center">
      <IconEmptySearch />
      <div className="body-2 text-on-background-dimmed py-2 text-center">
        <p>No chats to show.</p>
        <p>Try adjusting your search.</p>
      </div>
    </div>
  );
};
