import { useEffect, useState } from 'react';
import { IconArrowDown } from '@unique/icons';
import { FinancialChartMetricsList } from './FinancialChartMetricsList';
import cn from 'classnames';

export const FinancialChartMetrics = ({ series, isLoading }) => {

    // Manage expanded or collapsed state for metrics
    const [isExpanded, setIsExpanded] = useState(() => {
        const saved = localStorage.getItem('financialChartMetricsExpanded');
        return saved !== null ? JSON.parse(saved) : true;
    });
    useEffect(() => {
        localStorage.setItem('financialChartMetricsExpanded', JSON.stringify(isExpanded));
    }, [isExpanded]);

    if (isLoading) {
        return <></>;
    }

    return (
        <div className="flex flex-col gap-4 pl-2 pr-2">
            <div>
                <div className="flex justify-between">
                    <button
                        className="bg-background-variant subtitle-2 flex w-max items-center gap-x-2 rounded-[8px] px-2.5 py-1"
                        onClick={() => setIsExpanded(!isExpanded)}
                        >
                        Metrics
                        <IconArrowDown
                            width="12px"
                            className={cn({
                            'origin-center transition': true,
                            'rotate-180': isExpanded,
                            })}
                        />
                    </button>
                </div>
                {isExpanded && series && Array.isArray(series) && series.map((serie, index) => (
                    <div key={`${index}-${serie.info.ticker}`} className="mt-4  pb-4">
                        { series.length > 1 && <h3 className="text-md font-medium mb-2">{serie.info.ticker}: {serie.info.companyName}</h3> }
                        <FinancialChartMetricsList metrics={serie.metrics} currency={serie.info.currency} />
                    </div>
                ))}
            </div>
        </div>
    );
};