import { ContentByIdQuery, getSdk } from '@/@generated/graphql';
import PDFPreview from '@/components/Chat/PDFPreview';
import { isInternalUniqueUrl, parseContentIdFromInternalUrl } from '@/helpers/references';
import { Reference } from '@/lib/swr/types';
import { logger } from '@unique/next-commons/logger';
import { ClientContext, Service } from '@unique/next-commons/swr';
import { LayoutContext, useToast } from '@unique/shared-library';
import { useContext, useState } from 'react';
import useFileLoader from './useFileLoader';

type Props = {
  chatId: string;
  showPdfHighlighting?: boolean;
  redirectInternalStorageOnly?: boolean;
  onClose?: () => void;
};

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:ChatReference',
});

export default function useChatPdfPreview({
  chatId,
  showPdfHighlighting,
  redirectInternalStorageOnly,
  onClose,
}: Props) {
  const { onFileClick } = useFileLoader({ chatId });

  const { setIsMenuExpanded, setSplitPaneContent } = useContext(LayoutContext);
  const { clients } = useContext(ClientContext);

  const [loadingReferenceId, setLoadingReferenceId] = useState<string | null>(null);
  const [openedReferenceId, setOpenedReferenceId] = useState<string | null>(null);

  const { showErrorToast } = useToast();

  const handleClosePdfPreview = () => {
    setSplitPaneContent(null);
    setOpenedReferenceId(null);
    onClose?.();
  };

  const handleOpenReferenceUrl = async (reference: Reference) => {
    if (!isInternalUniqueUrl(reference.url)) {
      window.open(reference.url, '_blank', 'noopener noreferrer');
      return;
    }

    // Set reference as loading to show spinner in template
    setLoadingReferenceId(reference.id);
    try {
      // Request content object
      const sdk = getSdk(clients[Service.NODE_INGESTION]);
      const query: ContentByIdQuery = await sdk.ContentById({
        contentIds: parseContentIdFromInternalUrl(reference.url),
        chatId,
      });
      const content = query.contentById[0];

      if (redirectInternalStorageOnly || !content.url) {
        onFileClick(content);
      } else {
        window.open(content.url, '_blank', 'noopener noreferrer');
      }
    } catch (error) {
      showErrorToast(`Reference ${reference.name} can't be opened.`, 6000);
      log.error(error);
    } finally {
      setLoadingReferenceId(null);
    }
  };

  const checkPdfPreviewEnabled = (reference: Reference) => {
    return (!!reference.pdfPreviewName || !!reference.pdfPreviewUrl) && showPdfHighlighting;
  };

  const handleClickReference = (reference) => {
    // Only show PDF preview for internal files (e.g. files that have a content entry).
    // For external files (e.g. Sharepoint), CORS is preventing us from showing the preview
    if (checkPdfPreviewEnabled(reference)) {
      setSplitPaneContent(
        <PDFPreview
          key={reference.id}
          reference={reference}
          chatId={chatId}
          handleOpenReferenceUrl={handleOpenReferenceUrl}
          handleClosePdfPreview={handleClosePdfPreview}
        />,
      );
      setIsMenuExpanded(false);
      setOpenedReferenceId(reference.id);
      return;
    }
    handleOpenReferenceUrl(reference);
  };

  return {
    handleClickReference,
    loadingReferenceId,
    handleClosePdfPreview,
    openedReferenceId,
    checkPdfPreviewEnabled,
  } as const;
}
