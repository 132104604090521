import { DateFilterOptions } from '@unique/component-library';

export const FILTER_OPTIONS_TIMESTAMP = [
  { value: DateFilterOptions.Today, label: DateFilterOptions.Today },
  { value: DateFilterOptions.Yesterday, label: DateFilterOptions.Yesterday },
  { value: DateFilterOptions.ThisWeek, label: DateFilterOptions.ThisWeek },
  { value: DateFilterOptions.LastWeek, label: DateFilterOptions.LastWeek },
  { value: DateFilterOptions.ThisMonth, label: DateFilterOptions.ThisMonth },
  { value: DateFilterOptions.PreviousMonth, label: DateFilterOptions.PreviousMonth },
  { value: DateFilterOptions.Last3Months, label: DateFilterOptions.Last3Months },
  { value: DateFilterOptions.Last6Months, label: DateFilterOptions.Last6Months },
  { value: DateFilterOptions.PreviousYear, label: DateFilterOptions.PreviousYear },
  {
    value: DateFilterOptions.OlderThanPreviousYear,
    label: DateFilterOptions.OlderThanPreviousYear,
  },
];

export const DEBOUNCE_DELAY_SEARCH_FILTER = 500;
