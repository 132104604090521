import { useState } from 'react';
import Skeleton from '@unique/component-library/src/Skeleton';
import { Tooltip } from '@unique/component-library/src/Tooltip';
import { getCurrencyFormatted } from '@/lib/get-currency-formatted';

interface Metrics {
    name: string;
    value: number;
    timestamp?: string | null;
}

// Define format for each metrics based on metric name enum
const METRIC_FORMAT_MAP = {
    'Open': 'currency',
    'High': 'currency',
    'Close': 'currency',
    'Market Cap': 'currency',
    'Price Earnings Ratio': null,
    'Volume': 'currency',
    'Year High': 'currency',
    'Year Low': 'currency',
    'Dividend Yield': 'percentage',
    'Volatility 30 Days': 'percentage',
};

export const FinancialChartMetricsList = ({ metrics, currency }: { metrics: Array<Metrics>, currency: string }) => {
    const [isLoading] = useState(false);

    const displayMetrics = (name: string, value: number, timestamp?: string | null) => {
        const formattedValue = new Intl.NumberFormat(navigator.language).format(value);
        // Format large numbers to T/B/M notation
        let displayValue = formattedValue;
        
        if (METRIC_FORMAT_MAP[name] === 'currency') {
            displayValue = getCurrencyFormatted(value, currency);
        } else if (METRIC_FORMAT_MAP[name] === 'percentage') {
            displayValue = displayValue + '%';
        }

        return (
            <p className='text-sm text-gray-500 flex justify-between'>
                <span className='font-medium'>{name}</span>
                { timestamp ? <>
                    <Tooltip wrapper={<>
                        <span>{displayValue}</span>
                    </>} arrowPosition='none'>
                        {new Date(timestamp).toLocaleDateString()} {new Date(timestamp).toLocaleTimeString()}
                    </Tooltip>
                </> : <>
                    <span>{displayValue}</span>
                </>}
            </p>
        );
    };

    if (isLoading) {
        return (
            <div className='flex flex-col gap-2 pl-2 pr-2 pt-4 pb-4'>
                <Skeleton className='!bg-control !h-[18px] !w-[220px]' />
                <Skeleton className='!bg-control !h-[18px] !w-[140px]' />
                <Skeleton className='!bg-control mt-4 !h-[300px] !w-full' />
            </div>
        );
    }

    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-0'>
            <div className='flex flex-col'>
                {metrics.slice(0, Math.ceil(metrics.length / 3)).map((metric, index) => (
                    <div key={`${index}-${metric.name}`}>{displayMetrics(metric.name, metric.value, metric.timestamp)}</div>
                ))}
            </div>
            <div className='flex flex-col'>
                {metrics.slice(Math.ceil(metrics.length / 3), Math.ceil(metrics.length / 3) * 2).map((metric, index) => (
                    <div key={`${index}-${metric.name}`}>{displayMetrics(metric.name, metric.value, metric.timestamp)}</div>
                ))}
            </div>
            <div className='flex flex-col'>
                {metrics.slice(Math.ceil(metrics.length / 3) * 2).map((metric, index) => (
                    <div key={`${index}-${metric.name}`}>{displayMetrics(metric.name, metric.value, metric.timestamp)}</div>
                ))}
            </div>
        </div>
    );
};