import { useEffect, useRef, memo, useState } from 'react';
import { createChart, IChartApi, AreaSeries } from 'lightweight-charts';
import { getCSSVariableValue } from '@unique/component-library/helpers/getCSSVariableValue';
import getColorComplements from '@unique/component-library/helpers/getColorComplements';
import { AdvancedFormattingProps } from '@/lib/advanced-formatting-props';
import { ErrorMessage } from '../ErrorMessage';
import { FinancialChartFooter } from './FinancialChartFooter';
import { FinancialChartMetrics } from './FinancialChartMetrics';
import { FinancialChartLegend } from './FinancialChartLegend';
import { FinancialChartTickers } from './FinancialChartTickers';
import getCurrencySymbol from '@/lib/get-currency-symbol';
import { getConfig } from './helpers/getConfig';

export const FinancialChart = memo<AdvancedFormattingProps>(({ data }) => {
    // lightweight-chart needs a container to display the chart in createChart()
    const chartContainerRef = useRef<HTMLDivElement>(null);
    // Loading state to display skeleton loader
    const [isLoading, setIsLoading] = useState(false);
    // List of data series to display
    const [series, setSeries] = useState([]);
    // List of unique colors generated for each serie based on 
    const [colors, setColors] = useState([]);
    // Store error message and display error component if not null
    const [error, setError] = useState(null);

    // Lightweight chart instance returned by createChart()
    // Stored in a ref to be able to access it within handleResize function
    const chartRef = useRef<IChartApi>(null);

    // Load graph when data changes
    useEffect(() => {
        try {
            // Block is displayed without data
            if (!data) return;
            const json = JSON.parse(data);
            // Data is not formated as an array
            if (!Array.isArray(json)) throw new Error('Provided data are not formated as an array');
            // No data provided
            if (json.length === 0) throw new Error('No data provided');
            // Chart container is not found
            if (!chartContainerRef.current) return;

            // Show loading UI until the chart is ready
            setIsLoading(true);
            // Reset error as new data seams all good :)
            setError(null);

            // Initialize the tradingview lightweight chart
            chartRef.current = createChart(chartContainerRef.current, getConfig(chartContainerRef.current.clientWidth));

            const primaryCtaHue = getCSSVariableValue('--color-primary-cta');
            // Initialise series object for each tickers based on json[]
            const newColors = getColorComplements(primaryCtaHue, json.length);

            // Genreate all series for the chart based on json data
            json.forEach((listing, index) => {

                // if graph shows multiple ticker, we display percentage change at each index
                const isValuePercentage = json.length > 1;
                // Get a unique color for each serie
                const color = newColors[index % newColors.length];

                const areaSeries = chartRef.current.addSeries(AreaSeries, {
                    lineColor: color,
                    topColor: 'transparent',
                    bottomColor: 'transparent',
                    priceFormat: {
                        type: 'custom',
                        formatter: (price) => {
                            if (isValuePercentage) {
                                return `${price.toFixed(2)}%`;
                            } else {
                                return `${getCurrencySymbol(navigator.language, listing.info.currency)} ${price.toFixed(2)}`;
                            }
                        }
                    }
                });
                // Get first price to calculate percentage change of current serie if needed
                const firstPrice = listing.priceHistory[0].value;

                areaSeries.setData(listing.priceHistory.map(day => ({
                    time: new Date(day.date).getTime() / 1_000, // Convert to seconds
                    value: isValuePercentage ? ((day.value - firstPrice) / firstPrice) * 100 : day.value,
                    color: color
                })));
            });

            // Refresh lightweight chart UI as it is not react aware
            chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
            chartRef.current.timeScale().fitContent();

            // Set listings and colors for the component
            setSeries(json);
            setColors(newColors);

            // Hide loading UI and show the chart
            setIsLoading(false);
        } catch (error) {
            setError(error);
        }

        return () => {
            chartRef.current?.remove();
        };
    }, [data]);

    // Handle chart resizing event
    useEffect(() => {
        const handleResize = () => {
            if (chartContainerRef.current && chartRef.current) {
                chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
                chartRef.current.timeScale().fitContent();
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('blur', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('blur', handleResize);
        };
    }, [data]);

    if (error) {
        return <ErrorMessage error={error} />;
    }

    return (
        <div className="font-sans">
            <FinancialChartTickers series={series} isLoading={isLoading}/>
            <FinancialChartLegend colors={colors} series={series} isLoading={isLoading}/>
            <div ref={chartContainerRef} className="w-full h-[300px] mb-4 mt-2 pt-2 overflow-visible" />
            <FinancialChartMetrics series={series} isLoading={isLoading}/>
            <FinancialChartFooter
                lastUpdated={series?.[0]?.lastUpdated}
                dataSource={series?.[0]?.dataSource}
                isLoading={isLoading}/>
        </div>
    );
});

FinancialChart.displayName = 'FinancialChart'; 