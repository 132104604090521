import { useChatUpdateTitle } from '@/hooks/useChatUpdateTitle';
import { CHAT_TITLE_MAX_LENGTH } from '@/lib/constants/chatTitleMaxLength';
import { useChatQuery } from '@/lib/swr/hooks';
import { Badge, DRAWER_POSITION, DrawerProvider, InputField } from '@unique/component-library';
import { IconSpace } from '@unique/icons';
import { ToastVariant, useScreens, useToast } from '@unique/shared-library';
import cn from 'classnames';
import { FC, KeyboardEventHandler, Ref, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ChatHeaderActions } from './ChatHeaderActions';
import { ChatHistoryBreadcrumb } from './ChatHistoryBreadcrumb';

interface ChatHeaderProps {
  assistantTitle: string;
  customTitle?: string;
  ctaSubject?: string;
  resetHeader?: () => void;
  drawerPosition?: DRAWER_POSITION;
  onClickNewChat?: () => void;
}

const ChatHeader: FC<ChatHeaderProps> = ({
  assistantTitle,
  customTitle,
  ctaSubject = 'Chat',
  resetHeader,
  drawerPosition = DRAWER_POSITION.BOTTOM,
  onClickNewChat,
}) => {
  const isTranslation = ctaSubject === 'Translation';
  const [chatTitleIsFocused, setChatTitleIsFocused] = useState(false);
  const [inputRef, setInputRef] = useState(null);
  const location = useLocation();
  const showBackToHistory = location.state?.showBackToHistory;

  const { id } = useParams<{ id: string }>();
  const { data } = useChatQuery(
    id
      ? {
          chatId: id,
        }
      : null,
  );

  const currentChat = data?.chat;
  const [chatTitle, setChatTitle] = useState('');
  const { updateChatTitle } = useChatUpdateTitle(id);
  const { showToast } = useToast();
  const { isMobile } = useScreens();

  useEffect(() => {
    if (!currentChat?.title) return;
    setChatTitle(currentChat?.title);
  }, [currentChat?.title]);

  const handleClickNewChat = () => {
    if (customTitle && typeof resetHeader === 'function') {
      resetHeader();
      return;
    }
    // should navigate to `/space/${assistantId}`;
    onClickNewChat?.();
  };

  const handleRenameChat = () => {
    setChatTitleIsFocused(false);
    if (currentChat?.title === chatTitle) return;
    if (!chatTitle.length) {
      setChatTitle(currentChat?.title);
      showToast({ message: 'Title cannot be blank.', variant: ToastVariant.INFO });
      return;
    }
    updateChatTitle(chatTitle);
  };

  const handleKeyDownChatInput: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleRenameChat();
      inputRef.current?.blur();
    }
  };
  const handleClickChatTitle = () => setChatTitleIsFocused(!isMobile);

  const handleChangeChatTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatTitle(event.target.value);
  };

  return (
    <DrawerProvider position={drawerPosition} className={`${isTranslation ? 'h-[9rem]' : ''}`}>
      <div className="flex w-full min-w-0 flex-wrap items-center justify-start gap-y-3 px-3.5 py-4 sm:px-6">
        {showBackToHistory && <ChatHistoryBreadcrumb />}
        <div
          className={cn({
            'order-1 pr-1': showBackToHistory,
            'max-w-[40%] xl:max-w-full': true,
          })}
        >
          <Badge variant="outlineInfo" size="large" icon={<IconSpace />}>
            {assistantTitle}
          </Badge>
        </div>
        <div
          className={cn({
            'subtitle-2 text-on-background-main min-w-0 flex-1 truncate whitespace-nowrap': true,
            'px-1': showBackToHistory,
            'px-4': !showBackToHistory,
          })}
        >
          {customTitle ? (
            customTitle
          ) : (
            <>
              {!chatTitleIsFocused ? (
                <div
                  className={cn({
                    'border-background w-[99%] min-w-0 truncate whitespace-nowrap rounded-[8px] px-[11px] transition':
                      true,
                    'hover:bg-background py-2.5': !isMobile,
                  })}
                  onClick={handleClickChatTitle}
                >
                  {chatTitle}
                </div>
              ) : (
                <InputField
                  name="title"
                  classes="border-transparent hover:!border-primary-cta subtitle-2 pl-2.5"
                  inputWrapperClasses="relative"
                  value={chatTitle}
                  autoFocus
                  handleChange={handleChangeChatTitle}
                  onBlur={handleRenameChat}
                  onKeyDown={handleKeyDownChatInput}
                  setRef={(ref: Ref<HTMLInputElement | null>) => {
                    setInputRef(ref);
                  }}
                  maxLength={CHAT_TITLE_MAX_LENGTH}
                />
              )}
            </>
          )}
        </div>
        <ChatHeaderActions
          onClickNewChat={handleClickNewChat}
          currentChat={currentChat}
          ctaSubject={ctaSubject}
        />
      </div>
    </DrawerProvider>
  );
};

export default ChatHeader;
