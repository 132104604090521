import { FC, useState, lazy } from 'react';
import { ButtonVariant, PopupMenu } from '@unique/component-library';
import { IconDelete, IconEdit, IconMenu } from '@unique/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { RowData } from './DueDiligenceList';
import { useInvalidateCache, useToast } from '@unique/shared-library';
import { getUseDeleteDueDiligence, useDeleteDueDiligence } from '@/lib/swr/hooks';

const DeleteDueDiligenceConfirmationModal = lazy(
  () => import('./DeleteDueDiligenceConfirmationModal'),
);
const RenameDueDiligenceModal = lazy(() => import('./RenameDueDiligenceModal'));

type Props = {
  headerTitle: string;
  rowData: RowData[];
};

const DueDiligenceHistoryTable: FC<Props> = ({ headerTitle = 'Yesterday', rowData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [selectedSheet, setSelectedSheet] = useState<RowData | null>(null);
  const [sheetAction, setSheetAction] = useState<'DELETE' | 'RENAME' | null>(null);

  const { invalidateCache } = useInvalidateCache();

  const { showSuccessToast, showErrorToast } = useToast();

  const { trigger: deleteSheet, isMutating: deletingSheet } = useDeleteDueDiligence(
    getUseDeleteDueDiligence(),
    {
      onSuccess: () => {
        closeModals();
        invalidateCache(['DueDiligence', 'MagicTableSheet']);
        showSuccessToast('Successfully deleted your sheet.');
      },
      onError: () => {
        showErrorToast('Failed to delete your sheet.');
      },
    },
  );

  const handleRenameSheetSelect = (data: RowData) => {
    setSelectedSheet(data);
    setSheetAction('RENAME');
  };

  const closeModals = () => {
    setSheetAction(null);
    setSelectedSheet(null);
  };

  const handleOpenDeleteModal = (data: RowData) => {
    setSelectedSheet(data);
    setSheetAction('DELETE');
  };

  return (
    <>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full px-2 py-2 align-middle">
            <table className="min-w-full table-fixed divide-y divide-transparent">
              <thead>
                <tr className="text-on-background-dimmed uppercase">
                  <th
                    scope="col"
                    className="w-[33%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                  >
                    {headerTitle}
                  </th>
                  <th scope="col" className="w-[33%] px-3 py-3.5 text-left text-sm font-semibold">
                    Last Edited
                  </th>
                  <th
                    scope="col"
                    className="w-[33%] px-3 py-3.5 text-left text-sm font-semibold"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-background-variant text-on-background-main bg-surface divide-y">
                {rowData.map((data) => (
                  <tr
                    key={data.sheetId}
                    onClick={() => navigate(`${pathname}/${data.sheetId}`)}
                    className="hover:bg-background cursor-pointer font-medium"
                  >
                    <td className="max-w-[200px] truncate whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6 lg:pl-8">
                      {data.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm">{data.lastEdited}</td>
                    <td className="relative flex justify-end whitespace-nowrap py-2 pl-3 pr-4 text-sm sm:pr-6 lg:pr-8">
                      <PopupMenu
                        buttonVariant={ButtonVariant.SECONDARY}
                        buttonIcon={<IconMenu />}
                        position="left"
                        menuItems={[
                          {
                            label: 'Rename',
                            icon: <IconEdit width="18px" height="18px" />,
                            onClick: (e) => {
                              e.stopPropagation();
                              handleRenameSheetSelect(data);
                            },
                          },
                          {
                            label: 'Delete',
                            icon: <IconDelete width="18px" height="18px" />,
                            onClick: (e) => {
                              e.stopPropagation();
                              handleOpenDeleteModal(data);
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedSheet && selectedSheet?.sheetId && sheetAction === 'RENAME' && (
        <RenameDueDiligenceModal
          isOpen={!!selectedSheet.sheetId}
          handleClose={closeModals}
          title={selectedSheet?.name}
          sheetId={selectedSheet?.sheetId}
        />
      )}
      {selectedSheet && selectedSheet?.sheetId && sheetAction === 'DELETE' && (
        <DeleteDueDiligenceConfirmationModal
          isOpen={!!selectedSheet.sheetId}
          handleClose={closeModals}
          deletingSheet={deletingSheet}
          onConfirmation={() => {
            deleteSheet({ dueDiligenceId: selectedSheet.dueDiligenceId });
          }}
          sheetName={selectedSheet?.name}
        />
      )}
    </>
  );
};

export default DueDiligenceHistoryTable;
