import { Tooltip, TooltipSize } from '@unique/component-library';
import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

type InlineReferenceProps = {
  tooltipText?: string;
  isLoading?: boolean;
  onClickReference?: () => void;
};

const ReferenceElement = ({
  isLoading,
  children,
  onClickReference,
}: PropsWithChildren<Partial<InlineReferenceProps>>) => {
  return (
    <a
      onClick={
        onClickReference
          ? (event) => {
              event.preventDefault();
              onClickReference();
            }
          : undefined
      }
      target="_blank"
      rel="noopener noreferrer"
      className={cn({
        'subtitle-2 bg-attention-variant text-on-attention-variant hover:bg-background-variant hover:text-on-surface top-auto inline-flex h-4 min-w-4 items-center justify-center rounded-md px-0.5 align-text-top leading-4 transition-all':
          true,
        '!cursor-default opacity-50': isLoading,
        'pointer-events-none !cursor-default': !onClickReference,
      })}
    >
      {children}
    </a>
  );
};

export const InlineReference: FC<PropsWithChildren<InlineReferenceProps>> = ({
  tooltipText,
  isLoading,
  children,
  onClickReference,
}) => {
  if (!tooltipText)
    return (
      <div className="mr-1 inline-flex cursor-pointer items-center first-of-type:ml-1 last-of-type:mr-0">
        <ReferenceElement isLoading={isLoading} onClickReference={onClickReference}>
          {children}
        </ReferenceElement>
      </div>
    );

  return (
    <Tooltip
      arrowPosition="none"
      size={TooltipSize.SMALL}
      wrapperClasses="inline-flex items-center mr-1 last-of-type:mr-0 cursor-pointer"
      className="!left-1/2 !right-0 !top-5 !z-[5] !hidden !w-max !-translate-x-1/2 hyphens-auto sm:!line-clamp-2 sm:block sm:!max-w-[100px] lg:!line-clamp-none lg:!max-w-[170px]"
      wrapper={
        <ReferenceElement isLoading={isLoading} onClickReference={onClickReference}>
          {children}
        </ReferenceElement>
      }
    >
      {tooltipText}
    </Tooltip>
  );
};
