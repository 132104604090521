import { ChatHistoryFilter, ChatHistoryState } from '@/hooks/useChatHistory';
import { Chat } from '@/lib/swr/types';
import { createContext } from 'react';

interface ChatHistoryContextType {
  chatsInHistory: Chat[];
  onDeleteChats: ({
    chatIds,
    shouldDeleteAll,
  }: {
    chatIds?: string[];
    shouldDeleteAll?: boolean;
  }) => void;
  onFilterUpdate: (newFilters: ChatHistoryFilter) => void;
  loadMore: (newFilters?: ChatHistoryFilter) => void;
  chatHistoryState: ChatHistoryState;
  canLoadMore: boolean;
  chatHistoryFilters: ChatHistoryFilter;
}

export const ChatHistoryContext = createContext<ChatHistoryContextType>({
  chatsInHistory: [],
  onDeleteChats: () => {},
  onFilterUpdate: () => {},
  loadMore: () => {},
  chatHistoryState: ChatHistoryState.Loading,
  canLoadMore: true,
  chatHistoryFilters: {},
});
