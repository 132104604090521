'use client';
import { ContentById, PaginatedContent } from '@/lib/swr/types';
import { ButtonIcon } from '@unique/component-library';
import Skeleton from '@unique/component-library/src/Skeleton';
import { IconArrowDown } from '@unique/icons';
import cn from 'classnames';
import { useState } from 'react';
import { ContentItem } from '../Chat/ContentItem';
import useFileLoader from '@/hooks/useFileLoader';

export default function FileAccordion({
  buttonTitle = 'Expand',
  content,
  isLoading,
}: {
  buttonTitle: string;
  content?: ContentById[] | PaginatedContent[];
  isLoading: boolean;
}) {
  const { onFileClick } = useFileLoader();
  const [showAllFiles, setShowAllFiles] = useState(true);
  const showAllFilesHandler = () => {
    setShowAllFiles(!showAllFiles);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center gap-3">
        <ButtonIcon
          onClick={showAllFilesHandler}
          className="!bg-background !text-on-background-dimmed hover:!bg-background-variant hover:!text-on-background-main mt-2 !h-[28px] w-fit !px-2"
        >
          <span className="flex items-center gap-x-3">
            <span className="inline">{buttonTitle}</span>
            <IconArrowDown
              className={cn({
                'transition duration-200': true,
                'rotate-180': showAllFiles,
              })}
              width="14px"
              height="14px"
            />
          </span>
        </ButtonIcon>
      </div>

      {/* All file uploads card */}
      {isLoading ? (
        <div className={cn({ 'flex w-full flex-col gap-2': true, hidden: !showAllFiles })}>
          <div className="border-background bg-surface mt-3 flex h-[67px] w-full items-center justify-center overflow-hidden rounded-lg border px-3">
            <div className="flex flex-1 items-center gap-x-3">
              <Skeleton className="!bg-control !h-[40px] !w-[40px]" />
              <Skeleton className="!bg-control !h-[30px] !w-[2/3]" />
            </div>
          </div>
        </div>
      ) : (
        <div className={cn({ 'flex w-full flex-col gap-2': true, hidden: !showAllFiles })}>
          <div className="border-background bg-surface mt-3 min-h-[65px] w-full overflow-hidden rounded-lg border">
            {!content || content?.length === 0 ? (
              <div className="subtitle-2 text-on-background-dimmed flex h-full items-center gap-x-4 px-5">
                No files added
              </div>
            ) : (
              <>
                {content.map((contentItem) => (
                  <ContentItem
                    key={contentItem.id}
                    content={contentItem}
                    handleFileOpenClick={() => onFileClick(contentItem)}
                    loadImageURLFromContent={() => Promise.resolve('')}
                    initials="A"
                    fullDetails={false}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
