export const FinancialChartFooter = ({ lastUpdated, dataSource, isLoading }) => {

    if (isLoading) {
        return <></>;
    }

    return (
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center px-2 py-4 gap-2 sm:gap-0">
            <p className="text-xs text-gray-500 flex items-center gap-2 italic">
                <span className="font-medium">Last updated:</span>
                <time>
                    {new Date(lastUpdated).toLocaleDateString()} {new Date(lastUpdated).toLocaleTimeString()}
                </time>
            </p>
            <p className="text-xs text-gray-500 flex items-center gap-2 italic">
                <span className="font-medium">Source:</span>
                <span>Data provided by {dataSource}</span>
            </p>
        </div>
    );
};