import { ErrorCode } from 'react-dropzone';

export const getFileRejectionMessage = (
  code: string,
  maxFileSize: number,
  maxFiles: number,
): string => {
  switch (code) {
    case ErrorCode.FileTooLarge:
      return `File too large. The current limit is ${maxFileSize / 1024 / 1024} MB per file.`;
    case ErrorCode.FileInvalidType:
      return 'Invalid File Type';
    case ErrorCode.FileTooSmall:
      return 'File size is too small';
    case ErrorCode.TooManyFiles:
      return `Too many files. You can upload a maximum of ${maxFiles} files at once.`;
    default:
      return `Unknown Error (${code})`;
  }
};
