'use client';

import { useAssistantQuery } from '@/lib/swr/hooks';
import { Spinner } from '@unique/component-library';
import { useParams } from 'react-router-dom';
import { FC, useContext } from 'react';
import { SpaceNotFoundPage } from './SpaceNotFoundPage';
import ChatComponent from './ChatComponent';
import { FeatureFlagContext } from '@unique/shared-library';
import { hasTranslationUI, renderUI } from '@unique/next-commons/helpers/hasTranslationUI';
import { isNetworkError } from '@unique/next-commons/helpers/isNetworkError';
import { UITypes } from '@unique/next-commons/helpers/userInterface';
import DueDiligenceHome from '../DueDiligence/DueDiligenceHome';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
import TranslationComponent from '../Translation/TranslationComponent';

export const SpacePage: FC = () => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const { flags } = useContext(FeatureFlagContext);
  const { chatUrl, adminUrl } = useContext(ConfigurationContext);

  const {
    data: assistantQuery,
    isLoading: isLoadingAssistant,
    error: assistantError,
  } = useAssistantQuery({ assistantId: spaceId });

  if (
    !isLoadingAssistant &&
    !isNetworkError(assistantError) &&
    (assistantError || !assistantQuery?.assistantByUser)
  ) {
    return <SpaceNotFoundPage basePath={chatUrl} />;
  }
  if (isLoadingAssistant) {
    return <Spinner wrapperClasses="w-full h-full flex items-center justify-center" />;
  }
  const assistant = assistantQuery?.assistantByUser;

  if (flags.FEATURE_FLAG_ENABLE_MAGIC_TABLE_UN_8472) {
    if (renderUI(assistant) === UITypes.MAGIC_TABLE) {
      return <DueDiligenceHome assistant={assistant} />;
    }
  }

  if (hasTranslationUI(assistant)) {
    return <TranslationComponent assistant={assistant} />;
  }

  return <ChatComponent chatUrl={chatUrl} adminUrl={adminUrl} assistant={assistant} />;
};
