'use client';
import { FC, useContext } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { NotFoundPage } from '@unique/shared-library';
import { routes } from '@/routes';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';

const Routing: FC = () => {
  const { basePath } = useContext(ConfigurationContext);

  // Server side rendering ignored
  if (typeof window === 'undefined') {
    return <></>;
  }

  const router = createBrowserRouter(
    [
      ...routes,
      {
        path: '*',
        element: <NotFoundPage basePath={basePath} />,
      },
    ],
    { basename: basePath },
  );

  // Load application's route and add name 404 not found to both of them.
  // (Also nice way not to handle basePath outside of it)
  return <RouterProvider router={router} />;
};

export default Routing;
