import { DueDiligence } from '@/@generated/graphql';
import { useContentByChatQuery, useContentByIdQuery } from '@/lib/swr/hooks';
import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import { ContentItem } from '../Chat/ContentItem';
import useFileLoader from '@/hooks/useFileLoader';
import Skeleton from '@unique/component-library/src/Skeleton';

type Props = {
  chatId: string;
  dueDiligence: Partial<DueDiligence>;
};

export default function DueDiligenceFilesList({ chatId, dueDiligence }: Props) {
  const { onFileClick } = useFileLoader();

  const dueDiligenceFiles = useMemo(() => {
    const sourceFileIds = dueDiligence?.sourceFileIds || [];
    const questionFileIds = dueDiligence?.questionFileIds || [];
    return [...sourceFileIds, ...questionFileIds];
  }, [dueDiligence]);

  const { data: questionsContent, isLoading: isLoadingContent } = useContentByChatQuery(
    chatId
      ? {
          chatId,
        }
      : null,
  );

  const { data: contentById, isLoading: isLoadingContentById } = useContentByIdQuery(
    !dueDiligenceFiles.length
      ? null
      : {
          contentIds: dueDiligenceFiles,
          chatId,
        },
  );

  const { sources, questions } = useMemo(() => {
    const filteredQuestions =
      contentById?.contentById?.filter((c) => dueDiligence?.questionFileIds?.includes(c.id)) || [];

    const questionsList = questionsContent?.contentByChat || [];

    const questions = uniqBy([...filteredQuestions, ...questionsList], 'id');

    const sources =
      contentById?.contentById?.filter((c) => dueDiligence?.sourceFileIds?.includes(c.id)) || [];

    return { sources, questions };
  }, [questionsContent, contentById]);

  if (isLoadingContent || isLoadingContentById) {
    return (
      <div className="bg-surface mx-auto flex h-[40vh] w-full min-w-[300px] justify-center">
        <div className="flex w-full flex-col gap-4 p-4">
          {Array(10)
            .fill('')
            .map((_, index) => (
              <Skeleton key={index} className="!bg-control !h-[20px] w-full" />
            ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {!dueDiligenceFiles?.length ? (
        <p className="p-3">No files added</p>
      ) : (
        <div className="bg-surface flex max-h-[70vh] w-full flex-col gap-x-2 overflow-y-scroll rounded-md">
          <div>
            <div className="bg-background">
              <div className="p-3">
                <p className="text-on-background-main font-bold">
                  {questions.length} question files
                </p>
              </div>
              <div className="bg-surface">
                {!questions.length ? (
                  <p className="p-3">No question files</p>
                ) : (
                  <div className="w-auto">
                    {questions.map((question) => (
                      <ContentItem
                        key={question.id}
                        content={question}
                        handleFileOpenClick={() => onFileClick(question)}
                        loadImageURLFromContent={() => Promise.resolve('')}
                        initials="A"
                        fullDetails={false}
                        cardClassName="!flex-nowrap"
                        showUploadDetails
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="bg-background">
              <div className="p-3">
                <p className="text-on-background-main font-bold">{sources.length} source files</p>
              </div>
              <div className="bg-surface">
                {!sources.length ? (
                  <p className="p-3">No source files</p>
                ) : (
                  <div>
                    {sources.map((source) => (
                      <ContentItem
                        key={source.id}
                        content={source}
                        handleFileOpenClick={() => onFileClick(source)}
                        loadImageURLFromContent={() => Promise.resolve('')}
                        initials="A"
                        fullDetails={false}
                        cardClassName="!flex-nowrap"
                        showUploadDetails
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
