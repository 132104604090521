import { Assistant } from '@/lib/swr/types';
import { ButtonIcon, MarkdownPreview, Tag } from '@unique/component-library';
import { IconNewChat } from '@unique/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type ExploreSpaceInfoProps = {
  assistant: Partial<Assistant>;
};
export const ExploreSpaceInfo: FC<ExploreSpaceInfoProps> = ({ assistant }) => {
  const navigate = useNavigate();

  const handleClickNewChat = () => {
    navigate(`/space/${assistant.id}`);
  };

  const handleClickUserPrompt = (prompt: string) => {
    navigate(`/space/${assistant.id}?prompt=${prompt}`);
  };

  if (!assistant) return null;

  return (
    <>
      <div className="subtitle-1 text-on-surface mb-2 mr-8 truncate font-bold sm:mr-0">
        About <span className="sm:hidden">{assistant.name}</span>
      </div>
      <div className="body-2 text-on-surface mb-8 sm:mb-10">
        {assistant.explanation ? (
          <MarkdownPreview text={assistant.explanation} className="!px-0" />
        ) : (
          <>
            Start exploring <span className="font-bold">{assistant.name}</span> by clicking on New
            Chat.
          </>
        )}
      </div>
      <ButtonIcon onClick={handleClickNewChat} className="mb-8 sm:mb-10" icon={<IconNewChat />}>
        New Chat
      </ButtonIcon>
      <div className="flex flex-wrap items-center gap-2 lg:gap-4">
        {assistant.userPrompts
          ?.filter((userPrompt) => !userPrompt.ownedBy)
          .map((userPrompt) => (
            <Tag
              key={userPrompt.id}
              onClick={() => handleClickUserPrompt(userPrompt.prompt)}
              label={userPrompt.title}
            />
          ))}
      </div>
    </>
  );
};
