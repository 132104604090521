import { logger } from '@unique/next-commons/logger';

export type SnowAuthData = {
  isActive: boolean;
  feedbackEndpoint: string;
  username: string;
  password: string;
  feedbackEndpointRedirectUrl: string;
};

export type SnowClickFeedback = {
  gptResponse: string;
  gptResponseId: string;
};

export type SubmitSnowData = {
  snowAuthData: SnowAuthData;
  messageId: string;
  prompt: string;
  answer: string;
  space: string;
  spaceId: string;
  isPositive: boolean;
  user: string;
  history: unknown;
  llmRequest: string;
  references: unknown;
  translationLanguage?: string;
};

const log = logger.child({
  package: 'chat',
  namespace: 'chat:helpers:submitSnow',
});

export const submitSnow = async (data: SubmitSnowData) => {
  const { messageId, snowAuthData, prompt, answer, space, isPositive, user, history, llmRequest, references, translationLanguage, spaceId } = data;

  const response = await fetch(data.snowAuthData.feedbackEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${snowAuthData.username}:${snowAuthData.password}`)}`,
    },
    body: JSON.stringify({
      jai_id: messageId,
      prompt,
      return: answer,
      feedback_state: isPositive ? 'positive' : 'negative',
      user,
      history,
      llmRequest,
      references,
      translationLanguage,
      space,
      spaceId,
    }),
  });

  if (!response.ok) {
    log.error('Error while submitting feedback to ServiceNow');
    return;
  }

  const newRedirectUrl = new URL(snowAuthData.feedbackEndpointRedirectUrl);
  newRedirectUrl.searchParams.append('sysparm_space', space.replace(/ /g, '%20').replace(/&/g, '%26'));
  newRedirectUrl.searchParams.append('sysparm_jai_id', messageId);

  window.open(newRedirectUrl, '_blank');
};
