import { Chat } from '@/lib/swr/types';
import { ChatHistoryContext } from './context';
import { ClientThemeContext } from '@unique/shared-library';
import { FC, memo, useContext, useMemo } from 'react';
import { formatSupportEmail } from '@/helpers/formatSupportEmail';
import { IconChatPlus, IconSpace, IconTime } from '@unique/icons';
import { isEqual } from 'lodash';
import { ListItemCard, ListItemCardTagType } from '@unique/component-library';
import { removeSystemPrefixFromMessage } from '@/helpers/messages';
import { useNavigate } from 'react-router-dom';

type ChatHistoryListItemProps = {
  chat: Chat;
  isSelected: boolean;
  onAddChatIdToDelete: (chatId: string) => void;
  onRemoveChatIdToDelete: (chatId: string) => void;
};

export const ChatHistoryListItem: FC<ChatHistoryListItemProps> = ({
  chat,
  isSelected,
  onAddChatIdToDelete,
  onRemoveChatIdToDelete,
}) => {
  const { supportEmail } = useContext(ClientThemeContext);
  const { chatHistoryFilters } = useContext(ChatHistoryContext);
  const navigate = useNavigate();

  const lastAssistantMessageText = useMemo(() => {
    if (!chat?.lastMessage?.text) return '';

    const isDocumentTranslationMessage = chat.lastMessage.text.startsWith('<status>');
    if (isDocumentTranslationMessage) return '';
    return chat.lastMessage.text;
  }, [chat?.lastMessage]);

  const handleClickChat = () => {
    navigate(`/${chat.id}`, { state: { showBackToHistory: true } });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onAddChatIdToDelete(chat.id);
    } else {
      onRemoveChatIdToDelete(chat.id);
    }
  };

  return (
    <ListItemCard
      isSelected={isSelected}
      description={removeSystemPrefixFromMessage(
        formatSupportEmail(lastAssistantMessageText, supportEmail),
      )}
      onClickCard={handleClickChat}
      onSelectCard={handleCheckboxChange}
      searchTerm={chatHistoryFilters.searchTerm}
      title={chat.title}
      tags={[
        {
          icon: <IconSpace />,
          value: chat.assistant.name,
          type: ListItemCardTagType.STRING,
        },
        {
          icon: <IconChatPlus width="16px" height="16px" />,
          label: 'created',
          value: chat.createdAt,
          type: ListItemCardTagType.DATE,
        },
        {
          icon: <IconTime />,
          label: 'last message',
          value: chat.lastMessage?.createdAt,
          type: ListItemCardTagType.DATE,
        },
      ]}
    />
  );
};

const chatHistoryPropsDidNotChange = (
  prevProps: ChatHistoryListItemProps,
  nextProps: ChatHistoryListItemProps,
) => {
  return (
    isEqual(prevProps.chat, nextProps.chat) &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.onAddChatIdToDelete === nextProps.onAddChatIdToDelete &&
    prevProps.onRemoveChatIdToDelete === nextProps.onRemoveChatIdToDelete
  );
};

// FIXME: This is at the moment neccessary to prevent re-rendering the component when user scrolls
// We really need to fix the scrolling causing re-renders of everything. Jira: UN-9798
export default memo(ChatHistoryListItem, chatHistoryPropsDidNotChange);
