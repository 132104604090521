import { logger } from '@unique/next-commons/logger';
import { chatSlice } from '../chatSlice';

import { getSdk } from '@/@generated/graphql';
import { GraphQLClient } from 'graphql-request';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:stopStream',
});

/**
 * This action stops a stream and can if set true add a flag to ignore messageUpdate from this stream
 * @param chatId ChatId of the message
 * @param messageId MessageId of the message
 * @param client GraphQL client
 * @param ignoreStream Will add a flag to ignore messageUpdate from this stream
 * @returns
 */
export const stopStream = (chatId: string, messageId: string, client: GraphQLClient, ignoreStreamFlag: boolean = false) => {
  return async (dispatch) => {
    const { removeStream, ignoreStream } = chatSlice.actions;
    try {
      log.info(`dispatch stopStream: ${chatId}`);
      const sdkScope = getSdk(client);
      await sdkScope.MessageStopStreaming({
        chatId,
        messageId,
      });

      if (ignoreStreamFlag) {
        // We add a flag to ignore messageUpdate from this stream (like if the user click on stop button)
        dispatch(ignoreStream({
          chatId,
          messageId,
        }));
      }
      return dispatch(
        removeStream({
          chatId,
          messageId,
        }),
      );
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};
