import { IconArrowDown } from '@unique/icons';
import cn from 'classnames';
import { FC, PropsWithChildren, ReactNode, useState } from 'react';

type ChatAccordionProps = {
  additionalComponents?: ReactNode;
  icon: ReactNode;
  label: string;
};

export const ChatAccordion: FC<PropsWithChildren<ChatAccordionProps>> = ({
  additionalComponents,
  icon,
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <button
          className="bg-background-variant subtitle-2 flex w-max items-center gap-x-2 rounded-[8px] px-2.5 py-1"
          onClick={() => setIsOpen(!isOpen)}
        >
          {icon}
          {label}
          <IconArrowDown
            width="12px"
            className={cn({
              'origin-center transition': true,
              'rotate-180': isOpen,
            })}
          />
        </button>
        {additionalComponents && additionalComponents}
      </div>
      <div
        className={cn({
          'max-h-0 overflow-hidden opacity-0 transition-all duration-300 ease-in-out': true,
          'mb-6 max-h-screen opacity-100': isOpen,
        })}
      >
        {children}
      </div>
    </>
  );
};
