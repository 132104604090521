import React, { useContext, useEffect } from 'react';
import CreateDueDiligence from './CreateDueDiligence';
import { useParams } from 'react-router-dom';
import { useAssistantQuery } from '@/lib/swr/hooks';
import { LayoutContext } from '@unique/shared-library';
import { SpaceHeader } from '../Space/SpaceHeader';

export default function DueDiligenceOnboarding() {
  const { spaceId: assistantId } = useParams();
  const { setHeaderItems } = useContext(LayoutContext);

  const { data: assistantQuery } = useAssistantQuery({ assistantId });

  useEffect(() => {
    setHeaderItems([
      <SpaceHeader
        key={`space-header-${assistantQuery?.assistantByUser}`}
        title={assistantQuery?.assistantByUser?.name}
      />,
    ]);
    return () => {
      setHeaderItems([]);
    };
  }, [assistantQuery?.assistantByUser]);

  return <CreateDueDiligence assistantId={assistantId} />;
}
