import { serializeError } from 'serialize-error';
import { ContentById, PaginatedContent } from '@/lib/swr/types';
import { loadFile } from '@unique/next-commons/helpers';
import { logger } from '@unique/next-commons/logger';
import { ClientContext, Service } from '@unique/next-commons/swr';
import { useToast } from '@unique/shared-library';
import { useContext } from 'react';
import { useAuth } from 'react-oidc-context';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:chat-messages',
});

export default function useFileLoader({ chatId }: { chatId?: string } = {}) {
  const auth = useAuth();
  const { showErrorToast } = useToast();
  const { services } = useContext(ClientContext);

  const handleFileOperation = async (
    content: ContentById | PaginatedContent,
    shouldOpen = false,
  ): Promise<string> => {
    if (!content) return;

    try {
      return await loadFile({
        accessToken: auth.user.access_token,
        ingestionUrl: services[Service.NODE_INGESTION],
        content,
        shouldOpen,
        chatId: chatId || undefined,
      });
    } catch (error) {
      const fileName = content?.title || content?.key;
      log.error(
        { error: serializeError(error) },
        `Can not ${shouldOpen ? 'open' : 'load'} file ${fileName}`,
      );
      showErrorToast(`Can not ${shouldOpen ? 'open' : 'load'} file ${fileName}`);
    }
  };

  return {
    onFileClick: (content: ContentById) => handleFileOperation(content, true),
    loadUploadedFile: (content: ContentById) => handleFileOperation(content, false),
  };
}
