'use client';

import {
  ButtonIcon,
  ButtonVariant,
  Tooltip,
} from '@unique/component-library';
import { IconClose, IconDelete } from '@unique/icons';
import { FC } from 'react';
import IndeterminateCheckbox from '@unique/component-library/src/IndeterminateCheckbox';
import { Chat } from '@/lib/swr/types';

type ChatMenuSelectionHeaderProps = {
  selectedChatIds: string[];
  chats: Chat[];
  onToggleButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onCancelButtonClick: () => void;
};

export const ChatMenuSelectionHeader: FC<ChatMenuSelectionHeaderProps> = ({
  selectedChatIds,
  chats,
  onToggleButtonClick,
  onDeleteButtonClick,
  onCancelButtonClick,
}) => {
  
  return (
    <div className="subtitle-1 bg-secondary z-55 text-on-secondary sticky top-0 flex items-center justify-between pb-4 pl-4 pt-4">
      <div>
        <IndeterminateCheckbox
          checked={selectedChatIds.length === chats?.length}
          indeterminate={selectedChatIds.length > 0}
          onChange={onToggleButtonClick}
          key={`selected-chat-${selectedChatIds.length}`}
          className="mr-3"
        />
        {selectedChatIds.length} selected
      </div>
      <div className="flex items-center">
        <Tooltip
          wrapper={
            <ButtonIcon
              disabled={selectedChatIds.length === 0}
              variant={ButtonVariant.SHAPE}
              icon={<IconDelete />}
              onClick={() => {
                onDeleteButtonClick();
              }}
            ></ButtonIcon>
          }
          arrowPosition="none"
        >
          {selectedChatIds.length === 0 && 'Select at least one chat to delete'}
          {selectedChatIds.length > 0 &&
            selectedChatIds.length < chats?.length &&
            `Delete ${selectedChatIds.length} chat${selectedChatIds.length === 1 ? '' : 's'}`}
          {selectedChatIds.length === chats?.length && 'Delete all chats'}
        </Tooltip>
        <Tooltip
          wrapper={
            <ButtonIcon
              variant={ButtonVariant.SHAPE}
              icon={<IconClose />}
              onClick={() => {
                onCancelButtonClick();
              }}
            ></ButtonIcon>
          }
          arrowPosition="none"
        >
          Cancel
        </Tooltip>
      </div>
    </div>
  );
};
