'use client';

import * as Sentry from '@sentry/nextjs';
import { LoadingScreen } from '@unique/component-library';
import { getCompanyId } from '@unique/shared-library';
import { FC, useContext, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { serializeError } from 'serialize-error';
import { useNetworkStatus } from '../helpers/useNetworkStatus';
import { logger } from '../logger';
import { ErrorHandlerContext } from '../swr';
import { RECOVERABLE_OIDC_ERRORS, SESSION_KEY_HAS_ERROR_OCCURED } from '../helpers/requireAuth';

interface RequireAuthProps {
  children: React.ReactNode;
  basePath: string;
}

const log = logger.child({
  package: 'next-commons',
  namespace: 'oidc-auth:require-auth',
});

export const RequireAuth: FC<RequireAuthProps> = ({ children, basePath }) => {
  const auth = useAuth();
  const { setError, setLogout } = useContext(ErrorHandlerContext);
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const isOnline = useNetworkStatus(basePath);

  // isRedirecting avoid blinking effect when loading login page
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Detect past errors and avoid inifinite loop on login page
  const [hasAnErrorOccured, setHasAnErrorOccured] = useState(
    sessionStorage.getItem(SESSION_KEY_HAS_ERROR_OCCURED) === 'true',
  );

  // 1. Initial login if JWT is not valid or not present.
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      setHasTriedSignin(true);
      if (auth.user?.refresh_token) {
        log.info('JWT is not valid, but refresh_token is present. Trying to refresh token.');
        auth.signinSilent();
      } else {
        // We don't log an object here, as it will be wiped during the redirect.
        log.info(`User unauthenticated. Starting login flow. Existing user: ${!!auth.user}`);
        auth.signinRedirect({ login_hint: auth.user?.profile?.email });
      }
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    setLogout(() => auth.signoutRedirect);
  }, [auth]);

  useEffect(() => {
    if (hasAnErrorOccured) {
      sessionStorage.setItem(SESSION_KEY_HAS_ERROR_OCCURED, 'true');
    } else {
      sessionStorage.removeItem(SESSION_KEY_HAS_ERROR_OCCURED);
    }
  }, [hasAnErrorOccured]);

  useEffect(() => {
    if (Sentry.isInitialized()) {
      if (!auth.isAuthenticated) {
        Sentry.setUser(null);
        return;
      }
      Sentry.setUser(null);
      Sentry.setUser({
        email: auth.user?.profile?.email,
        ip_address: '{{auto}}',
        companyId: getCompanyId(auth.user),
      });
    }
  }, [auth.user]);

  // 2. Refresh token if JWT is expired.
  useEffect(() => {
    const dispose = auth.events.addAccessTokenExpiring(() => {
      auth
        .signinSilent()
        .then(() => {
          log.info(
            `Silent sign-in success - removing ${SESSION_KEY_HAS_ERROR_OCCURED} from sessionStorage`,
          );
          setHasAnErrorOccured(false);
          setIsRedirecting(false);
        })
        .catch((error) => {
          log.error(`Silent sign-in failed with error: ${JSON.stringify(serializeError(error))}`);
        });
    });

    return () => {
      dispose();
    };
  }, [auth.events, auth.signinSilent]);

  // 3. Try to fix recoverable oidc errors using the redirect flow.
  useEffect(() => {
    // wait for network to be back to be able to handle the error and redirect the user to signin page
    if (!auth.error || !isOnline || auth.isLoading) return;

    // We don't log an object here, as it will be wiped during the redirect.
    log.warn(
      `Error occurred during login flow. Error: ${JSON.stringify(serializeError(auth.error))}`,
    );

    // clear the state from the url
    window.history.replaceState({}, document.title, window.location.pathname);
    if (!hasAnErrorOccured) {
      const errorMessage = auth.error.message;

      if (RECOVERABLE_OIDC_ERRORS.some((error) => errorMessage.includes(error))) {
        log.info(
          `Attempting to recover from "${errorMessage}" using redirect flow. Existing user: ${!!auth.user}`,
        );
        auth.signinRedirect({ login_hint: auth.user?.profile?.email });
        setIsRedirecting(true);
        setHasAnErrorOccured(true);
      } else {
        // Unrecoverable error - clear session and propagate error
        log.error(`Unrecoverable authentication error: ${errorMessage}`);
        setHasAnErrorOccured(false);
        setError(auth.error);
      }
    } else {
      // If we've already tried to recover once, treat as unrecoverable
      // and set error in order to show the error page
      log.error(`Repeated authentication error: ${auth.error.message}`);
      setHasAnErrorOccured(false);
      setError(auth.error);
    }

    auth.removeUser();
  }, [auth.error, isOnline, auth.isLoading]);

  if (auth.isLoading || isRedirecting) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};
