'use client';

import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  PopupMenu,
  Spinner,
  SpinnerTheme,
} from '@unique/component-library';
import { IconDelete, IconEdit, IconMeatballsMenu, IconSelect } from '@unique/icons';
import { FeatureFlagContext } from '@unique/shared-library';
import { FC, useContext, useMemo } from 'react';

type ChatMenuHeaderProps = {
  title?: string;
  isLoading?: boolean;
  disableSelectAndDeleteAll?: boolean;
  activateSelectMode: () => void;
  onSelectAll: () => void;
  onDeleteAll: () => void;
};

export const ChatMenuHeader: FC<ChatMenuHeaderProps> = ({
  title = 'Chats',
  isLoading = false,
  activateSelectMode,
  onSelectAll,
  onDeleteAll,
  disableSelectAndDeleteAll,
}) => {
  const { flags } = useContext(FeatureFlagContext);

  const memoizedMenuItems = useMemo(() => {
    return [
      ...(!flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803
        ? [
            {
              label: 'Select all',
              disabled: disableSelectAndDeleteAll,
              icon: <IconSelect width="14px" height="14px" />,
              onClick: () => {
                onSelectAll();
              },
            },
          ]
        : []),
      {
        label: 'Delete all',
        disabled: disableSelectAndDeleteAll,
        icon: <IconDelete width="14px" height="14px" />,
        onClick: () => {
          onDeleteAll();
        },
      },
    ];
  }, [
    disableSelectAndDeleteAll,
    flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803,
    onDeleteAll,
    onSelectAll,
  ]);

  return (
    <div className="subtitle-1 flex items-center justify-between pb-4 pl-4 pr-2 pt-4">
      <div className="flex">
        {title}
        {isLoading && (
          <Spinner theme={SpinnerTheme.LIGHT} wrapperClasses="ml-4 flex items-center" size={16} />
        )}
      </div>
      {!isLoading && (
        <>
          {!flags.FEATURE_FLAG_ENABLE_CHAT_HISTORY_UN_5803 && (
            <div className="flex sm:hidden">
              <ButtonIcon
                icon={<IconEdit width="14px" height="14px" />}
                variant={ButtonVariant.SHAPE}
                buttonSize={ButtonSize.SMALL}
                onClick={activateSelectMode}
              ></ButtonIcon>
            </div>
          )}
          <div className="hidden sm:flex">
            <PopupMenu
              buttonVariant={ButtonVariant.SHAPE}
              buttonIcon={<IconMeatballsMenu />}
              menuItems={memoizedMenuItems}
            />
          </div>
        </>
      )}
    </div>
  );
};
