import {
  FeedbackUncheckedCreateInput,
  ServiceNowIntegrationData,
  SubmitFeedbackMutation,
} from '@/@generated/graphql';
import ServiceNowModal from '@/components/Chat/ServiceNowModal';
import { checkSNowConfig } from '@/helpers/checkSNowConfig';
import { getUseSubmitFeedbackMutation, useSubmitFeedbackMutation } from '@/lib/swr/hooks';
import { Assistant } from '@/lib/swr/types';
import { upsertMessages, useAppDispatch, useAppSelector } from '@/store';
import { ModalProps } from '@unique/component-library';
import { IconThumbsDown, IconThumbsUp } from '@unique/icons';
import { logger } from '@unique/next-commons/logger';
import { FeatureFlagContext, useToast } from '@unique/shared-library';
import { useContext, useState } from 'react';
import { FeedbackModal } from '../components/Chat/FeedbackModal';
import { SavePromptModal } from '../components/Chat/SavePromptModal';

type Props = {
  currentChatAssistant: Assistant;
  onSuccess?: (data: SubmitFeedbackMutation) => void;
};

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:chat-messages',
});

export default function useChatModals({ currentChatAssistant, onSuccess }: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ModalProps | null>(null);
  const messages = useAppSelector((state) => state.messages.messages);
  const translateToLanguage = useAppSelector(({ translation }) => translation.translateToLanguage);

  const { flags } = useContext(FeatureFlagContext);

  const serviceNowIsActive = flags.FEATURE_FLAG_SERVICE_NOW_FEEDBACK_UN_9322;

  const { showErrorToast } = useToast();

  const dispatch = useAppDispatch();

  const { trigger: submitFeedback } = useSubmitFeedbackMutation(getUseSubmitFeedbackMutation());

  const updateMessageData = (input: FeedbackUncheckedCreateInput) => {
    submitFeedback(
      {
        input,
      },
      {
        revalidate: false,
        throwOnError: false,
        onError: (err) => {
          log(err);
        },
        onSuccess: (data) => {
          onSuccess?.(data);
          const foundMessage = messages.find(
            (message) => message.id === data?.submitFeedback?.messageId,
          );
          if (!foundMessage.id) return;
          const dupeFoundMessage = { ...foundMessage };
          dupeFoundMessage.feedback = data?.submitFeedback;
          dispatch(upsertMessages([dupeFoundMessage]));
        },
      },
    );
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const onThumbsClick = async (
    messageId: string,
    isPositive: boolean,
    translationSpace?: boolean,
  ) => {
    if (!serviceNowIsActive) {
      showAppFeedbackModal(messageId, isPositive);
      return;
    }

    const sNowConfig = await checkSNowConfig();

    if (!sNowConfig?.isActive) {
      showErrorToast('ServiceNow integration is not active');
      return;
    }
    let translationLanguage: string = '';
    if (translationSpace && translateToLanguage?.value) {
      translationLanguage = translateToLanguage.value;
    }
    showSNowFeedbackModal(messageId, isPositive, sNowConfig, translationLanguage);
  };

  const showSNowFeedbackModal = async (
    messageId: string,
    isPositive: boolean,
    serviceNowData: ServiceNowIntegrationData,
    translationLanguage?: string,
  ) => {
    const spaceName = currentChatAssistant?.name ?? '';

    const modalContent = {
      title: '',
      children: (
        <ServiceNowModal
          handleClose={handleModalClose}
          messageId={messageId}
          space={spaceName}
          spaceId={currentChatAssistant?.id}
          submitFeedback={updateMessageData}
          isPositive={isPositive}
          translationLanguage={translationLanguage}
          snowAuthData={{
            isActive: serviceNowData?.isActive,
            feedbackEndpoint: serviceNowData?.submitFeedbackEndpoint,
            username: serviceNowData?.username,
            password: serviceNowData?.password,
            feedbackEndpointRedirectUrl: serviceNowData?.feedbackRedirectEndpoint,
          }}
        />
      ),
    };
    setModalContent(modalContent);
    setShowModal(true);
  };

  const showAppFeedbackModal = (messageId: string, isPositive?: boolean) => {
    const modalContent = {
      title: 'Provide additional feedback',
      icon: isPositive ? (
        <IconThumbsUp className="text-success-dark" height="26" width="24" />
      ) : (
        <IconThumbsDown className="text-error-dark mt-1" height="26" width="24" />
      ),
      children: (
        <FeedbackModal
          isPositive={isPositive}
          updateMessageData={updateMessageData}
          messageId={messageId}
          handleClose={handleModalClose}
        />
      ),
    };
    setModalContent(modalContent);
    setShowModal(true);
  };

  const onSavePromptClick = (prompt: string) => {
    const modalContent = {
      title: `Add to My Prompts in ${currentChatAssistant.name}`,
      children: (
        <SavePromptModal
          prompt={prompt}
          handleClose={handleModalClose}
          assistantId={currentChatAssistant.id}
        />
      ),
    };
    setModalContent(modalContent);
    setShowModal(true);
  };

  return { showModal, modalContent, handleModalClose, onThumbsClick, onSavePromptClick };
}
