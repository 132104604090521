import { ActiveUploads } from '@/components/Chat/ChatProvider';
import { NO_CHAT_ID_UPLOAD_KEY } from '@/lib/constants/chatUpload';
import { useMemo } from 'react';

export function useFileUploadProgress(
  activeUploads: ActiveUploads,
  chatId: string = NO_CHAT_ID_UPLOAD_KEY,
) {
  const { isUploading, uploadProgress } = useMemo(() => {
    const isUploading = !!activeUploads?.[chatId];
    const uploadProgress = activeUploads?.[chatId]
      ? activeUploads?.[chatId].reduce(
          (overallProgress, upload) => overallProgress + upload.progress,
          0,
        ) / activeUploads?.[chatId].length
      : 0;
    return {
      isUploading,
      uploadProgress,
    };
  }, [activeUploads, chatId]);
  return { isUploading, uploadProgress };
}
