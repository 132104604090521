'use client';
import { FC, useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ClientThemeProvider,
  DefaultLayout,
  FeatureFlagProvider,
  NetPromoterScoreProvider,
  NotificationProvider,
  UserProfileProvider,
  OfflineHandler,
  TermsAndConditionsProvider,
} from '@unique/shared-library';
import { ClientProvider, Service, WithClientContextReady } from '@unique/next-commons/swr';
import { Navigation } from '../Navigation';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
import { Roles } from '@unique/next-commons/authorization/roles';
import {
  ClientAuthProvider,
  OIDC_REDIRECT_URI_SESSION_KEY,
  PageAccess,
  RequireAuth,
} from '@unique/next-commons/oidc-auth';
import { ChatProvider } from '@/components/Chat/ChatProvider';
import ReduxProvider from '@/store/ReduxProvider';
import { DrawerProvider } from '@unique/component-library';

export const Layout: FC = () => {
  const {
    selfUrl,
    chatUrl,
    knowledgeUploadUrl,
    adminUrl,
    basePath,
    zitadelIssuer,
    zitadelClientId,
    orgId,
    flags,
    chatBackendUrl,
    ingestionBackendUrl,
    themeBackendUrl,
    scopeManagementBackendUrl,
    version,
    staticBackgroundColor,
    staticThemeColors,
    staticTabName,
  } = useContext(ConfigurationContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // On each URL change, we set the redirect_uri as OIDC_REDIRECT_URI_SESSION_KEY in sessionStorage.
  // This is used by the onSigninCallback to redirect to the correct page after login.
  useEffect(() => {
    if (!searchParams.get('code') && !searchParams.get('state')) {
      // Ignore redirect URL if location.search contains code and state,
      // which is used by oidc-client-ts to handle the redirect.
      // Otherwise this would loop back to the validation page indifinitely.
      const query = location.search;
      sessionStorage.setItem(OIDC_REDIRECT_URI_SESSION_KEY, location.pathname + query);
    }
  }, [location.pathname]);

  return (
    <ClientAuthProvider
      oidcConfig={{
        authority: zitadelIssuer,
        client_id: zitadelClientId,
        redirect_uri: selfUrl,
      }}
      orgId={orgId}
      onSigninCallback={() => {
        if (sessionStorage.getItem(OIDC_REDIRECT_URI_SESSION_KEY)) {
          navigate(sessionStorage.getItem(OIDC_REDIRECT_URI_SESSION_KEY));
          return;
        }
        // Navigate back to the homepage
        navigate('/', { replace: true });
      }}
    >
      <FeatureFlagProvider flags={flags}>
        <ClientProvider
          services={{
            [Service.NODE_CHAT]: chatBackendUrl,
            [Service.NODE_INGESTION]: ingestionBackendUrl,
            [Service.NODE_THEME]: themeBackendUrl,
            [Service.NODE_SCOPE_MANAGEMENT]: scopeManagementBackendUrl,
          }}
          defaultService={Service.NODE_CHAT}
          basePath={basePath}
        >
          <NotificationProvider>
            <UserProfileProvider>
              <ClientThemeProvider
                version={version}
                staticThemeColors={staticThemeColors}
                staticBackgroundColor={staticBackgroundColor}
                staticTabName={staticTabName}
              >
                <ReduxProvider>
                  <RequireAuth basePath={basePath}>
                    <PageAccess hasAllRoles={[Roles.CHAT_BASIC]}>
                      <ChatProvider>
                        <DefaultLayout selfUrl={selfUrl} basePath={basePath}>
                          <OfflineHandler />
                          <Navigation
                            chatUrl={chatUrl}
                            knowledgeUploadUrl={knowledgeUploadUrl}
                            adminUrl={adminUrl}
                          />
                          <WithClientContextReady>
                            <DrawerProvider>
                              <TermsAndConditionsProvider>
                                <NetPromoterScoreProvider>
                                  <Outlet />
                                </NetPromoterScoreProvider>
                              </TermsAndConditionsProvider>
                            </DrawerProvider>
                          </WithClientContextReady>
                        </DefaultLayout>
                      </ChatProvider>
                    </PageAccess>
                  </RequireAuth>
                </ReduxProvider>
              </ClientThemeProvider>
            </UserProfileProvider>
          </NotificationProvider>
        </ClientProvider>
      </FeatureFlagProvider>
    </ClientAuthProvider>
  );
};

export default Layout;
