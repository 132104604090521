import { Assistant, ChatAssistant } from '@/lib/swr/types';
import { SUPPORTED_MIME_TYPES } from '@unique/shared-library';

export const getAllowedFileTypes = (assistant: Assistant | ChatAssistant) => {
  const { settings } = assistant || {};
  const allowedTypes =
    Array.isArray(settings?.allowedFileTypes) &&
    settings?.allowedFileTypes.length &&
    settings?.allowedFileTypes.every((type) => typeof type === 'string')
      ? settings?.allowedFileTypes
      : null;

  return allowedTypes;
};

export const getAllowedMimeTypes = (allowedFileTypes: string[] | null) => {
  if (!allowedFileTypes) return {};

  const mimeTypes = {};
  const allowedTypes = allowedFileTypes.map((type) =>
    (type.startsWith('.') ? type.trim() : `.${type.trim()}`).toLowerCase(),
  );

  Object.entries(SUPPORTED_MIME_TYPES).forEach(([key, values]) => {
    const selectedTypes = [];
    for (const value of values) {
      if (allowedTypes.includes(value)) {
        selectedTypes.push(value);
      }
    }
    if (selectedTypes.length) {
      mimeTypes[key] = selectedTypes;
    }
  });

  return mimeTypes;
};
