'use client';

import { Message, Role } from '@/@generated/graphql';
import { escapeHtmlOutsideCodeBlocks } from '@/helpers/escapeHtmlOutsideCodeBlocks';
import { formatSupportEmail } from '@/helpers/formatSupportEmail';
import { messageHasCode, removeSystemPrefixFromMessage } from '@/helpers/messages';
import {
  INTERNAL_URL_PREFIX,
  isInternalUniqueUrl,
  mapReadUrlForReference,
  parseContentIdFromInternalUrl,
} from '@/helpers/references';
import { useAdvancedFormatting } from '@/hooks/useAdvancedFormatting';
import useChatPdfPreview from '@/hooks/useChatPdfPreview';
import { useContentByIdQuery } from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import {
  CopyToClipboard,
  DefaultMarkdownLink,
  MarkdownPreview,
  duration,
} from '@unique/component-library';
import {
  ClientThemeContext,
  FeatureFlagContext,
  ScrollWrapperContext,
  useCopyToClipboard,
} from '@unique/shared-library';
import cn from 'classnames';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import FeedbackButtons from '../../FeedbackButtons';
import MessageAssessment from '../Assessment/MessageAssessment';
import { ChatMessageDebugFooter } from './components/ChatMessageDebugFooter';
import {
  ChatPromptButton,
  PROMPT_URL_PREFIX_HTTP,
  PROMPT_URL_PREFIX_HTTPS,
} from '../ChatPromptButton';
import { ChatReferences } from './components/ChatReferences';
import { SavePromptControl } from './components/SavePromptControl';
import SenderProfile from '../SenderProfile';
import { InlineReference } from '../InlineReference';

interface MessageItemProps {
  messageId: string;
  isStreaming: boolean;
  allowDebugRead: boolean;
  showPdfHighlighting: boolean;
  redirectInternalStorageOnly: boolean;
  onThumbsClick: (messageId: string, isPositive: boolean, translationSpace?: boolean) => void;
  onSavePromptClick: (prompt: string) => void;
  handleSelectPrompt: (prompt: string) => void;
  onStreamingDone?: () => void;
  enableScrollOnMessageUpdate?: boolean;
}

const linkContainsPrompt = (href: unknown) => {
  return href && typeof href === 'string' && href.startsWith(PROMPT_URL_PREFIX_HTTPS);
};

const STREAM_UPDATE_INTERVAL_MS = 16; // 60fps

export const MessageItem: FC<MessageItemProps> = ({
  messageId,
  isStreaming,
  allowDebugRead,
  showPdfHighlighting,
  redirectInternalStorageOnly,
  onThumbsClick,
  onSavePromptClick,
  handleSelectPrompt,
  onStreamingDone,
  enableScrollOnMessageUpdate = false,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const message = useAppSelector((state) =>
    state.messages.messages.find((message) => message.id === messageId),
  );
  const { copiedText, copy } = useCopyToClipboard();
  const { copiedText: copiedCode, copy: copyCode } = useCopyToClipboard();
  const isUserMessage = message.role === Role.User;
  const chatMessageWithoutReferences = message?.text?.replace(/<sup>.*?<\/sup>/g, '');
  const hasCode = messageHasCode(message?.text);
  const hasPrompt =
    message?.text?.includes(PROMPT_URL_PREFIX_HTTPS) ||
    message?.text?.includes(PROMPT_URL_PREFIX_HTTP);
  const shouldHaveAddPromptButton = isUserMessage;
  const chatImageUrls = useAppSelector((state) => state.chat.chatImageUrls) ?? {};
  const { supportEmail } = useContext(ClientThemeContext);
  const { flags } = useContext(FeatureFlagContext);
  const { id } = useParams<{ id: string }>();
  const {
    handleClickReference,
    loadingReferenceId,
    handleClosePdfPreview,
    openedReferenceId,
    checkPdfPreviewEnabled,
  } = useChatPdfPreview({
    chatId: id,
    showPdfHighlighting,
    redirectInternalStorageOnly,
  });
  const ignoreStream = useAppSelector((state) => state.chat.ignoreStream);

  const referencesToRewriteUrl = message.references
    ? message.references?.filter((reference) => isInternalUniqueUrl(reference.url))
    : [];

  const { data: contentData } = useContentByIdQuery({
    contentIds: referencesToRewriteUrl?.map((reference) =>
      parseContentIdFromInternalUrl(reference.url),
    ),
    chatId: id,
  });

  const isAssistantMessage = useMemo(() => message.role === Role.Assistant, [message.role]);

  const shouldHaveCopyButton = isAssistantMessage && !hasCode && !hasPrompt;

  const { scrollTo } = useContext(ScrollWrapperContext);

  const ChatMessageMarkdownImage = (props: JSX.IntrinsicElements['img']): JSX.Element => {
    const { src } = props;
    if (isInternalUniqueUrl(src?.toString())) {
      // extract the content id from src
      const contentId = src?.toString().replace(INTERNAL_URL_PREFIX, '');
      if (chatImageUrls[contentId]) {
        // eslint-disable-next-line @next/next/no-img-element
        return <img src={chatImageUrls[contentId]} className="my-8 max-h-[400px] max-w-[850px]" />;
      } else {
        return null;
      }
    }
    // Ignore external images in the chat
    return null;
  };

  const ChatMessageMarkdownLink = (props: JSX.IntrinsicElements['a']): JSX.Element => {
    // Check if link contains a prompt and if it does, render a button instead of a link
    // This is to allow the user to select the prompt
    // Also, we show the file name including file type as the button label (e.g. "file.pdf")
    // based on this, we can extract the file type icon (e.g. PDF icon)

    // only allow prompt buttons that include a string as text
    // children can also include ReactNode elements, so we need to check if it's a string
    let promptCta: string;
    const { children, href, target } = props;
    if (Array.isArray(children) && typeof children[0] === 'string') {
      promptCta = children[0]?.toString();
    }
    if (typeof children === 'string') {
      promptCta = children?.toString();
    }

    if (
      linkContainsPrompt(href) &&
      handleSelectPrompt &&
      typeof handleSelectPrompt === 'function' &&
      promptCta
    ) {
      return (
        <ChatPromptButton
          handleSelectPrompt={handleSelectPrompt}
          href={href}
          promptCta={promptCta}
        />
      );
    }

    if (href === 'https://expired') {
      return <ChatPromptButton isExpired promptCta={promptCta} />;
    }
    return (
      <DefaultMarkdownLink href={href} target={target}>
        {children}
      </DefaultMarkdownLink>
    );
  };

  const cleanText = useMemo(
    () => removeSystemPrefixFromMessage(message.text) ?? '',
    [message.text],
  );
  const countRef = useRef(cleanText?.length ?? 0);
  const [text, setText] = useState(cleanText);
  const NUMBER_CHAR_PER_FRAME = 8;

  const scrollToTopMessage = () => {
    scrollTo({
      behavior: 'smooth',
      to: elementRef.current?.offsetTop,
      displayScrollToBottomButton: true,
    });
  };

  useEffect(() => {
    if (ignoreStream.find((s) => s.messageId === messageId)) {
      // If stream is ignored we stop all rendering.
      return;
    }
    if (!isStreaming) {
      // if not streaming we update the message directly without any animation
      setText(cleanText);
      return;
    }
    const interval = setInterval(() => {
      if (countRef.current < cleanText?.length) {
        countRef.current = countRef.current + NUMBER_CHAR_PER_FRAME;
        setText(cleanText?.substring(0, countRef.current));
        if (enableScrollOnMessageUpdate) {
          // Scroll to top of the message
          scrollToTopMessage();
        }
      } else if (isStreaming && (message.stoppedStreamingAt || message.completedAt)) {
        if (enableScrollOnMessageUpdate && !message.feedback) {
          // Ignore feedback update otherwise would scroll to the top of the message
          scrollToTopMessage();
        }
        onStreamingDone?.();
        clearInterval(interval);
      }
    }, STREAM_UPDATE_INTERVAL_MS); // 60fps for smooth scroll
    return () => clearInterval(interval);
  }, [message, cleanText, isStreaming, ignoreStream]);

  const MarkdownSuperscript = (props: JSX.IntrinsicElements['sup']): JSX.Element => {
    // check if children is strictly a reference number
    const { children } = props;
    let isNumber: boolean = false;
    const isNumberRegex = /^\d+$/;
    if (Array.isArray(children) && typeof children[0] === 'string') {
      isNumber = isNumberRegex.test(children[0]);
    }
    if (typeof children === 'string') {
      isNumber = isNumberRegex.test(children);
    }

    const reference = isNumber
      ? message.references?.find((ref) => ref.sequenceNumber === Number(children[0]))
      : null;

    if (!isNumber || !reference || !reference.url) {
      return <InlineReference tooltipText={reference?.name}>{children}</InlineReference>;
    }
    const referenceWithReadUrl = mapReadUrlForReference(
      reference,
      contentData,
      redirectInternalStorageOnly,
    );
    return (
      <InlineReference
        isLoading={referenceWithReadUrl.id === loadingReferenceId}
        tooltipText={referenceWithReadUrl.name}
        onClickReference={() => handleClickReference(referenceWithReadUrl)}
      >
        {children}
      </InlineReference>
    );
  };

  const formatMessage = () => {
    if (isUserMessage) {
      // Escape HTML outside code blocks for user message
      return escapeHtmlOutsideCodeBlocks(text);
    }
    return formatSupportEmail(text, supportEmail);
  };

  const hasAssessments = Array.isArray(message?.assessment) && message.assessment.length > 0;

  // This hook returns the advanced formatting components for the message to be used in the MarkdownPreview component.
  const advancedFormatting = useAdvancedFormatting(message as Message);

  /**
   * This is caching Markdown component to avoid re-rendering too often.
   * Should be manually updated if needed.
   *
   * Eg. Current implementation load images from contentId on rendering using customComponents and so
   * can't detect when file is loaded to update URl, this is why chatImageUrls
   * is passed as a dependency and will rerender on change.
   */
  const memoizedMarkdownPreview = useMemo(
    () => (
      <MarkdownPreview
        text={formatMessage()}
        handleSelectPrompt={handleSelectPrompt}
        copyCode={copyCode}
        copiedCode={copiedCode}
        customComponents={{
          a: ChatMessageMarkdownLink,
          img: ChatMessageMarkdownImage,
          sup: MarkdownSuperscript,
        }}
        wrapCode
        enableLatex={flags.FEATURE_FLAG_ENABLE_LATEX_UN_8603}
        advancedFormatting={advancedFormatting}
      />
    ),
    [message, text, supportEmail, chatImageUrls, loadingReferenceId],
  );

  return (
    <div className="mx-auto flex w-full max-w-[928px] items-start" ref={elementRef}>
      <SenderProfile isUserMessage={isUserMessage} />
      <div className="w-[calc(100%-48px)] flex-1 rounded-lg pl-2">
        <div
          className={cn({
            'body-1 group relative rounded-[8px] pb-6 pt-2.5 transition': true,
            'hover:bg-background': shouldHaveCopyButton || shouldHaveAddPromptButton,
          })}
        >
          {memoizedMarkdownPreview}
          {shouldHaveCopyButton && (
            <CopyToClipboard
              text={chatMessageWithoutReferences || ''}
              copiedText={copiedText}
              copy={copy}
            />
          )}
          {shouldHaveAddPromptButton && (
            <SavePromptControl
              prompt={chatMessageWithoutReferences || ''}
              onSavePromptClick={onSavePromptClick}
            />
          )}
        </div>

        {!!message.references?.length && (
          <ChatReferences
            onOpenReference={handleClickReference}
            onCloseReference={handleClosePdfPreview}
            references={message.references}
            checkPdfPreviewEnabled={checkPdfPreviewEnabled}
            redirectInternalStorageOnly={redirectInternalStorageOnly}
            openedReferenceId={openedReferenceId}
            loadingReferenceId={loadingReferenceId}
          />
        )}

        <div className="text-2xs -mx-3 mt-1 flex flex-wrap px-3 pt-2">
          {isUserMessage && (
            <div className="body-2 text-on-background-dimmed ml-auto mt-2 tracking-widest">
              {duration(message.createdAt)} ago
            </div>
          )}
          {isAssistantMessage && (
            <>
              {hasAssessments ? (
                <MessageAssessment
                  message={message}
                  additionalComponents={
                    <FeedbackButtons
                      id={message.id}
                      feedback={message.feedback}
                      onThumbsClick={onThumbsClick}
                    />
                  }
                />
              ) : (
                <div className="ml-auto">
                  <FeedbackButtons
                    id={message.id}
                    feedback={message.feedback}
                    onThumbsClick={onThumbsClick}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {allowDebugRead && <ChatMessageDebugFooter message={message} />}
      </div>
    </div>
  );
};
export default MessageItem;
