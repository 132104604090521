import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  Drawer,
  DRAWER_POSITION,
  DrawerProvider,
  MenuItem,
} from '@unique/component-library';
import { IconDelete, IconLoading, IconMeatballsMenu } from '@unique/icons';
import { useScreens } from '@unique/shared-library';
import cn from 'classnames';
import { FC, useState } from 'react';

type ChatHistoryHeaderProps = {
  isLoading: boolean;
  canDeleteAllChats: boolean;
  handleDeleteAllChats: () => void;
};
export const ChatHistoryHeader: FC<ChatHistoryHeaderProps> = ({
  isLoading,
  canDeleteAllChats,
  handleDeleteAllChats,
}) => {
  const { isDesktop } = useScreens();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <DrawerProvider position={DRAWER_POSITION.TOP} className="h-[6rem]">
      <div className="flex items-center gap-x-3 px-4 lg:px-9">
        <h1
          className={cn({
            'text-on-background-main text-left font-bold': true,
            'title-s': isDesktop,
            'subtitle-1 py-4': !isDesktop,
          })}
        >
          Chat History
        </h1>
        {isLoading && (
          <div className="relative flex h-10 w-10 items-center">
            <IconLoading width="16px" height="16px" />
          </div>
        )}
        {canDeleteAllChats && (
          <div className="ml-auto pr-1.5 sm:hidden">
            <ButtonIcon
              icon={<IconMeatballsMenu />}
              variant={ButtonVariant.SECONDARY}
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          </div>
        )}
        <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <div className="mt-8">
            <MenuItem
              icon={<IconDelete />}
              title="Delete All Chats"
              handleClick={(event) => {
                event.preventDefault();
                handleDeleteAllChats();
                setIsDrawerOpen(false);
              }}
            />
          </div>
        </Drawer>
      </div>
    </DrawerProvider>
  );
};
