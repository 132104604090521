import { LoadingText, ProgressBar } from '@unique/component-library';
import { IconFileLoading } from '@unique/icons';
import { FC } from 'react';

interface ChatUploadProgressProps {
  progress?: number;
}

export const ChatUploadProgress: FC<ChatUploadProgressProps> = ({ progress }) => {
  return (
    <div className="border-control mb-6 mt-4 flex h-full min-h-[170px] flex-1 flex-col items-center justify-center gap-2 overflow-y-auto rounded-lg border-2 border-dashed md:min-h-[250px]">
      <div className="flex items-center justify-center">
        <IconFileLoading height="56px" width="56px" />
      </div>
      <div className="text-on-surface mt-4 text-center font-bold">
        <LoadingText>Uploading File(s)</LoadingText>
      </div>

      <div className="my-4 w-full max-w-[250px]">
        <ProgressBar progress={progress} className="bg-control border-control border" />
      </div>
    </div>
  );
};
