import { IngestionState, SourceKind } from '@/@generated/graphql';
import { ContentById } from '@/lib/swr/types';
import { FileTypeIcon, ProgressBar, ProgressBarVariant } from '@unique/component-library';
import { getIngestionProgressValue } from '@unique/shared-library';
import { FC, useEffect, useState } from 'react';

interface ContentItemUploadingProps {
  content: ContentById;
}

const PROGRESS_BAR_DEFAULT_STEP = 0.5;

export const ContentItemUploading: FC<ContentItemUploadingProps> = ({ content }) => {
  const [progressBarCompleted, setProgressBarCompleted] = useState(0);
  const isExternalFile = content?.source?.kind !== SourceKind.UniqueBlobStorage;

  // always increase progress bar constantly to give user a sense of progress
  useEffect(() => {
    const increaseProgress = setInterval(() => {
      setProgressBarCompleted((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(increaseProgress);
        }
        return prevProgress + PROGRESS_BAR_DEFAULT_STEP;
      });
    }, 500);

    return () => {
      clearInterval(increaseProgress);
    };
  }, []);

  // accelerate progress bar progress based on ingestion state
  useEffect(() => {
    setProgressBarCompleted(getIngestionProgressValue(content.ingestionState));
  }, [content.ingestionState]);

  return (
    <div className="subtitle-2 border-control bg-background text-on-background-main flex max-w-[300px] flex-col gap-x-2 gap-y-1.5 rounded-[8px] border px-2 pt-1">
      <div className="flex items-center gap-x-2">
        <div className="flex shrink-0 items-center justify-center">
          <FileTypeIcon dimensions="16px" isExternal={isExternalFile} mimeType={content.mimeType} />
        </div>
        <span className="truncate">{content.title ?? content.key}</span>
      </div>
      <ProgressBar
        progress={progressBarCompleted}
        className="!h-1"
        variant={
          [IngestionState.Failed, IngestionState.FailedMalwareFound].includes(
            content.ingestionState,
          )
            ? ProgressBarVariant.ERROR
            : ProgressBarVariant.DEFAULT
        }
      />
    </div>
  );
};
