export const queryByAssistantId = (assistantId: string) => {
  return {
    magicTableSheet: {
      is: {
        chat: {
          is: {
            assistant: {
              is: {
                id: {
                  equals: assistantId,
                },
              },
            },
          },
        },
      },
    },
  };
};
