import { SortOrder } from '@/@generated/graphql';
import { ChatHistoryFilter, ChatHistoryState } from '@/hooks/useChatHistory';
import {
  FILTER_OPTIONS_TIMESTAMP,
  DEBOUNCE_DELAY_SEARCH_FILTER,
} from '@unique/shared-library/constants/searchFilters';
import { useAssistantsQuery } from '@/lib/swr/hooks';
import { FilterProps, ListControls } from '@unique/component-library';
import { debounce } from 'lodash';
import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { ChatHistoryContext } from './context';
import { useAuth } from 'react-oidc-context';

type ChatHistoryControlsProps = {
  handleUpdateFilter: (
    filterId: keyof ChatHistoryFilter,
    selectedOption: string | string[],
  ) => void;
};

export const ChatHistoryControls: FC<ChatHistoryControlsProps> = ({ handleUpdateFilter }) => {
  const { chatHistoryFilters, chatHistoryState } = useContext(ChatHistoryContext);
  const [searchValue, setSearchValue] = useState('');
  const { user } = useAuth();
  const { data: assistantsQuery } = useAssistantsQuery({
    orderBy: { name: SortOrder.Asc },
    where: {
      chats: {
        some: {
          userId: { equals: user.profile.sub },
          deleted: { equals: false },
        },
      },
    },
  });

  useEffect(() => {
    const { searchTerm } = chatHistoryFilters;
    if (!searchTerm) return;
    setSearchValue(searchTerm);
  }, []);

  const handleClearSearch = () => {
    setSearchValue('');
    handleUpdateFilter('searchTerm', '');
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (value: string) => handleUpdateFilter('searchTerm', value),
        DEBOUNCE_DELAY_SEARCH_FILTER,
      ),
    [handleUpdateFilter],
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const filterOptions: FilterProps[] = useMemo(() => {
    if (!assistantsQuery?.assistants?.length) return [];
    const filterOptionsAssistant = assistantsQuery.assistants.map((assistant) => ({
      value: assistant.id,
      label: assistant.name,
    }));

    return [
      {
        id: 'assistantId',
        options: filterOptionsAssistant,
        name: 'Space',
        showSearch: true,
        selectedOptions: filterOptionsAssistant.filter(
          (type) => chatHistoryFilters.assistantId === type.value,
        ),
      },
      {
        id: 'lastMessageTimestamp',
        options: FILTER_OPTIONS_TIMESTAMP,
        name: 'Last Message',
        selectedOptions: FILTER_OPTIONS_TIMESTAMP.filter(
          (type) => chatHistoryFilters.lastMessageTimestamp === type.value,
        ),
      },
    ];
  }, [assistantsQuery, chatHistoryFilters]);

  const numberOfFiltersApplied = Object.keys(chatHistoryFilters).length;

  return (
    <ListControls
      isLoading={chatHistoryState === ChatHistoryState.Loading}
      onSearch={handleSearch}
      searchValue={searchValue}
      onClearSearch={handleClearSearch}
      filterOptions={filterOptions}
      onFilterUpdate={handleUpdateFilter}
      numberOfFiltersApplied={numberOfFiltersApplied}
    />
  );
};
