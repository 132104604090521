import {
  isInternalUniqueUrl,
  mapReadUrlForReferences,
  parseContentIdFromInternalUrl,
} from '@/helpers/references';
import { useContentByIdQuery } from '@/lib/swr/hooks';
import { Reference } from '@/lib/swr/types';
import { IconFileSearch } from '@unique/icons';
import { pluralize } from '@unique/shared-library';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ChatAccordion } from '../../ChatAccordion';
import { ChatReference } from './ChatReference';

interface ChatReferencesProps {
  references?: Reference[];
  redirectInternalStorageOnly?: boolean;
  openedReferenceId: string | null;
  loadingReferenceId: string | null;
  onOpenReference: (reference: Reference) => void;
  onCloseReference: () => void;
  checkPdfPreviewEnabled: (reference: Reference) => boolean;
}

export const ChatReferences: FC<ChatReferencesProps> = ({
  references = [],
  openedReferenceId,
  loadingReferenceId,
  redirectInternalStorageOnly,
  onOpenReference,
  onCloseReference,
  checkPdfPreviewEnabled,
}) => {
  const { id } = useParams<{ id: string }>();
  if (!references) references = [];

  const referencesToRewriteUrl = references.filter((reference) =>
    isInternalUniqueUrl(reference.url),
  );

  const { data: contentData } = useContentByIdQuery({
    contentIds: referencesToRewriteUrl.map((reference) =>
      parseContentIdFromInternalUrl(reference.url),
    ),
    chatId: id,
  });

  const sortedReferencesWithReadUrl = mapReadUrlForReferences(
    references,
    contentData,
    redirectInternalStorageOnly,
  ).sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const handleClick = (reference: Reference, isActive: boolean) => {
    if (isActive) {
      onCloseReference();
      return;
    }
    onOpenReference(reference);
  };

  return (
    <div className="mt-1 md:mt-3">
      <ChatAccordion
        icon={<IconFileSearch />}
        label={`${references.length} ${pluralize('reference', references.length)}`}
      >
        <div className="mt-4 max-h-[275px] overflow-y-auto">
          {sortedReferencesWithReadUrl.map((reference) => {
            return (
              <ChatReference
                key={reference.id}
                reference={reference}
                onClick={handleClick}
                checkPdfPreviewEnabled={checkPdfPreviewEnabled}
                isLoading={reference.id === loadingReferenceId}
                isActive={reference.id === openedReferenceId}
              />
            );
          })}
        </div>
      </ChatAccordion>
    </div>
  );
};
