import { SourceKind } from '@/@generated/graphql';
import { getFileTitleFromKey } from '@/helpers/getFileTitleFromKey';
import { ContentById } from '@/lib/swr/types';
import { FileTypeIcon, LoadingText } from '@unique/component-library';
import { IconExpiredFile, IconFileLoading } from '@unique/icons';
import { useRoles } from '@unique/next-commons/authorization';
import {
  ClientThemeContext,
  getIngestionStateType,
  INGESTION_STATUS_TO_MESSAGE,
  isIngestingContent,
  isIngestionFailed,
} from '@unique/shared-library';
import cn from 'classnames';
import { format } from 'date-fns';
import { isNumber } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import ContentWrapper from './ContentWrapper';
import FileUploadDetails from './FileUploadDetails';

interface ContentItemProps {
  content: ContentById;
  handleFileOpenClick: (contentItem: ContentById) => void;
  loadImageURLFromContent: (contentItem: ContentById) => Promise<string>;
  hideIngestionStatus?: boolean;
  initials: string;
  fullDetails?: boolean;
  cardClassName?: string;
  showUploadDetails?: boolean;
}

export const ContentItem: FC<ContentItemProps> = ({
  content,
  handleFileOpenClick,
  loadImageURLFromContent,
  fullDetails = true,
  hideIngestionStatus = false,
  initials,
  cardClassName,
  showUploadDetails = false,
}) => {
  const [chatImageUrl, setChatImageUrl] = useState<string>();
  const ingestionFailed = isIngestionFailed(content.ingestionState);
  const { supportEmail } = useContext(ClientThemeContext);
  const isExternalFile = content?.source?.kind !== SourceKind.UniqueBlobStorage;
  const showExpirationInfo = isNumber(content.expiresInDays);
  const isExpired = showExpirationInfo && content.expiresInDays <= 0;
  const [IngestionStateIcon, ingestionStateText, ingestionStateColor] = getIngestionStateType(
    content.ingestionState,
  );
  const { allowDebugRead } = useRoles();

  useEffect(() => {
    if (typeof loadImageURLFromContent === 'function') {
      loadImageURLFromContent(content).then((data) => {
        setChatImageUrl(data);
      });
    }
  }, [content]);

  const contentInitialsDiv = cn({
    'subtitle-2 label bg-background-variant text-on-background-main flex h-10 w-10 items-center justify-center rounded-full':
      true,
  });

  const cardClasses = cn({
    'bg-surface flex w-full flex-1 flex-wrap gap-x-5 gap-y-4 p-4': true,
    'border-b border-b-background hover:bg-background': !fullDetails,
    'rounded-lg border-background border': fullDetails,
    [cardClassName]: !!cardClassName,
  });

  if (isIngestingContent(content.ingestionState) && !hideIngestionStatus) {
    return (
      <div className="mx-auto flex w-full max-w-[928px] items-start gap-2">
        {fullDetails && <div className={contentInitialsDiv}>{initials}</div>}
        <div className={cardClasses}>
          <div className="flex items-center justify-center">
            <IconFileLoading />
          </div>
          <div className="flex flex-col">
            <div className="text-on-surface font-bold">
              <LoadingText>{INGESTION_STATUS_TO_MESSAGE[content.ingestionState]}</LoadingText>
            </div>
            {fullDetails && (
              <p className="text-on-background-dimmed">
                Your file will be shown here when ready for chat.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex w-full items-start">
      {ingestionFailed && !hideIngestionStatus ? (
        <ContentWrapper
          fullDetails={fullDetails}
          initials={initials}
          className={contentInitialsDiv}
        >
          <div className={cardClasses}>
            <div className={`flex items-center justify-center ${ingestionStateColor}`}>
              <IngestionStateIcon />
            </div>
            <div
              className={cn({
                'flex min-w-[200px] flex-1 sm:min-w-[300px]': true,
                'flex-col justify-center': fullDetails,
                'flex-row items-center': !fullDetails,
              })}
            >
              <p
                className={cn({
                  [`${ingestionStateColor} cursor-pointers`]: true,
                  'text-sm font-medium': !fullDetails,
                  'subtitle-2 font-bold': fullDetails,
                })}
              >
                {ingestionStateText}
                {fullDetails
                  ? '. Please try again.'
                  : ` for "${getFileTitleFromKey(content?.key)}..."`}
                &nbsp;
                <span className="text-on-background-dimmed text-sm font-medium">
                  {supportEmail && (
                    <>
                      If the error persists,{' '}
                      <a href={`mailto:${supportEmail}`} className="text-info underline">
                        Contact Support.
                      </a>
                    </>
                  )}
                </span>
                {allowDebugRead && ( <><code className="text-on-background-dimmed text-sm font-medium"> - {content.id}</code></> )}
              </p>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <ContentWrapper
          fullDetails={fullDetails}
          initials={initials}
          className={contentInitialsDiv}
        >
          <>
            <div className={cardClasses}>
              <div className="flex items-center justify-center">
                {isExpired ? (
                  <IconExpiredFile />
                ) : (
                  <FileTypeIcon
                    dimensions="32px"
                    isExternal={isExternalFile}
                    mimeType={content.mimeType}
                  />
                )}
              </div>
              <div className="flex min-w-[200px] flex-1 flex-col justify-center sm:min-w-[300px]">
                <a
                  {...(handleFileOpenClick
                    ? { onClick: () => handleFileOpenClick(content) }
                    : { href: content?.readUrl })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cn({
                    'subtitle-2 cursor-pointer': true,
                    'text-on-surface': !isExpired,
                    'text-on-background-dimmed pointer-events-none': isExpired,
                  })}
                >
                  {content.title ?? content.key}
                </a>
                {fullDetails && <FileUploadDetails content={content} />}
                {!fullDetails && showUploadDetails && (
                  <p className="text-on-control-dimmed text-sm font-medium">
                    Added on {format(new Date(content.createdAt), "dd MMM, yyyy 'at' HH:mm")}
                  </p>
                )}
              </div>
            </div>
            {chatImageUrl && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={chatImageUrl}
                alt={`Chat Image for ${content.title ?? content.key}`}
                className="my-8 max-h-[150px] max-w-[300px] object-contain sm:max-h-[300px] sm:max-w-[500px] lg:max-h-[400px] lg:max-w-[850px]"
                fetchPriority="high"
              />
            )}
          </>
        </ContentWrapper>
      )}
    </div>
  );
};
