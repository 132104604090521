import Skeleton from '@unique/component-library/src/Skeleton';

export const ChatHistoryListSkeleton = () => {
  return (
    <>
      <div className="bg-background">
        <div className="mx-auto flex max-w-[928px] gap-4 px-4 py-4 md:gap-24 md:py-8">
          <Skeleton className="!bg-control h-[40px] !w-11/12 md:!w-9/12"></Skeleton>
          <div className="flex !w-1/12 gap-6 md:!w-full">
            <Skeleton className="!bg-control h-[40px] !w-full md:!w-1/5"></Skeleton>
            <Skeleton className="!bg-control hidden h-[40px] !w-1/4 md:!block"></Skeleton>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-[928px] px-4 py-4 md:py-7">
        {Array.from({ length: 10 }).map((_, index) => (
          <div
            key={`${index}-skeleton-chat-history`}
            className="border-background-variant mb-4 flex items-start gap-x-4 rounded-[8px] border px-6 py-7 md:mb-6"
          >
            <Skeleton className="!bg-control !h-6 !w-6"></Skeleton>
            <div className="flex-1">
              <Skeleton className="!bg-control mb-2 w-full"></Skeleton>
              <Skeleton className="!bg-control mb-2 !h-10 w-full"></Skeleton>
              <div className="flex items-center gap-x-4">
                <Skeleton className="!bg-control !w-1/6"></Skeleton>
                <Skeleton className="!bg-control !w-1/6"></Skeleton>
                <Skeleton className="!bg-control !w-1/6"></Skeleton>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
