import { isEmpty, isNil, omitBy } from 'lodash';

export type FilterObject = Record<string, string | string[] | null | undefined>;

export function getCleanedFilters<T>(
  currentFilters: T,
  filterId: string | keyof T,
  selectedOption: string | string[],
): T {
  const updatedFilters = { ...currentFilters, [filterId]: selectedOption };
  return omitBy(omitBy(updatedFilters, isNil), isEmpty) as T;
}
