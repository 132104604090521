import { MessagesQuery } from '@/@generated/graphql';

export const SYSTEM_MESSAGE_PREFIX = '[SYSTEM] ';

export const isSystemMessage = (message: string) => {
  if (!message) return false;
  return message.startsWith(SYSTEM_MESSAGE_PREFIX);
};

export const getLastMessageId = (messages: MessagesQuery) => {
  const messagesArray = messages?.messages;
  if (messagesArray?.length) {
    const lastMessage = messagesArray[messagesArray.length - 1];
    return lastMessage.id;
  }
  return null;
};

export const removeSystemPrefixFromMessages = (messages: MessagesQuery['messages']) => {
  if (messages.length) {
    return messages.map((message) => {
      if (message.text && message.text.startsWith(SYSTEM_MESSAGE_PREFIX)) {
        return {
          ...message,
          text: message.text.replace(SYSTEM_MESSAGE_PREFIX, ''),
        };
      }
      return message;
    });
  }
  return [];
};

export const removeSystemPrefixFromMessage = (message: string) => {
  if (message?.startsWith(SYSTEM_MESSAGE_PREFIX)) {
    return message.replace(SYSTEM_MESSAGE_PREFIX, '');
  }
  return message;
};

export const messageHasCode = (message: string) => {
  return message?.includes('```') || false;
};
